import React from "react";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { modalActions } from "../../../state/modal";
import { Form, Select, SubmitButton } from "../../FormElements";
import { SUPPORTED_BLOCKCHAINS, TOAST_CONFIG } from "../../../config";
import { useAddCryptoAccountMutation } from "../../../services/creditService";
import parseError from "../../../utils/errorUtils";

const chainValidationSchema = yup.object().shape({
    chain: yup.string().required("Required")
})

const blockChainOptions = window.Object.keys(SUPPORTED_BLOCKCHAINS).map(
    (blockchainKey) => ({
        value: blockchainKey,
        label: SUPPORTED_BLOCKCHAINS[blockchainKey],
    })
);

const AddCryptoAccountModal = () => {
    const { t } = useTranslation(["profile", "common"]);
    const dispatch = useDispatch();
    const { hideModal } = modalActions;
    const [chain, setChain] = React.useState("");

    const [addCryptoAccount] = useAddCryptoAccountMutation();

    const handleOnClickCancel = () => {
        dispatch(hideModal());
    };

    const handleOnSubmit = async ({ chain: blockchain }) => {
        try {
            const toastId = toast.loading("Please wait...");
            const response = await addCryptoAccount({ chain: blockchain });
            if (!response.error) {
                // handle on success
                toast.update(toastId, {
                    render:
                        "Crypto wallet created.",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
                dispatch(hideModal());
            }
            if (response.error) {
                // handle on error
                toast.update(toastId, {
                    render: parseError(response.error) || "Error creating crypto walltet.",
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Form
            initialValues={{ chain }}
            validationSchema={chainValidationSchema}
            onSubmit={handleOnSubmit}
            enableReinitialize
            className="p-3 p-sm-0"
        >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h5>Select blockchain: </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 p-3 text-center">
                        <Select
                            name="chain"
                            options={blockChainOptions}
                            defaultLabel="Choose Blockchain..."
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <SubmitButton title={t("CREATE_WALLET")} className="w-100" />
                    </div>
                    <div className="col-md-5">
                        <button
                            type="button"
                            className="btn btn-outline-primary w-100 h-100"
                            onClick={handleOnClickCancel}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default AddCryptoAccountModal;
