import React from "react";
import { useGetFileQuery } from "../../services/fileService";

import "./Profile.styles.scss";

function Banner({ user }) {
    const { data: bannerFileData } = useGetFileQuery(
        { fileId: user?.header_image },
        { skip: !user?.header_image }
    );

    const getBannerFileLink = () => {
        let bannerLink = bannerFileData?.file;
        if (user?.header_image && user?.header_image?.includes('blob:')) {
            bannerLink = user?.header_image;
        }
        return bannerLink;
    }

    return (
        <div className="banner-wrapper">
            <div className="banner-outline">
                <div
                    className="img-container"
                    style={{
                        background: `url(${
                            getBannerFileLink() || "/assets/img/banner.png"
                        })`,
                    }}
                />
            </div>
        </div>
    );
}

export default Banner;
