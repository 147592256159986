import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Form, TextField, SubmitButton } from "../../components/FormElements";
import { TextAreaField } from "../../components/FormElements/FormElements";
import NavBar from "../../components/NavBar";
import ProfileHeader from "../../components/Profile/ProfileHeader";

import { modalActions } from "../../state/modal";
import { uploadFile } from "../../services/fileService";
import {
    useGetMyProfileQuery,
    useUpdateMyProfileMutation,
} from "../../services/appService";
import {
    useGetAccountsQuery,
    useGetAvailableCreditsQuery,
    useGetCryptoAccountsQuery,
} from "../../services/creditService";
import { SUPPORTED_BLOCKCHAINS, TOAST_CONFIG } from "../../config";
import parseError from "../../utils/errorUtils";

import "./ProfilePage.styles.scss";

const profileSchema = Yup.object().shape({
    first_name: Yup.string().nullable(),
    email: Yup.string().email("Invalid email").required("Required"),
    bio: Yup.string()
        .min(150, "Bio must be at least 150 characters")
        .nullable(),
});

function EditProfilePage() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [selectedProfileHeader, setSelectedProfileHeader] = useState(null);
    const [selectedProfileHeaderURL, setSelectedProfileHeaderURL] = useState(
        ""
    );
    const [selectedProfileAvatar, setSelectedProfileAvatar] = useState(null);
    const [selectedProfileAvatarURL, setSelectedProfileAvatarURL] = useState(
        ""
    );

    const [profileData, setProfileData] = useState({
        first_name: "",
        email: "",
        bio: "",
    });

    const [transactionCreditFormData, setTransactionCreditFormData] = useState({
        credit: "",
    });

    const [earningsFormData, setEarningsFormData] = useState({
        earnings: "",
    });

    const { t, i18n } = useTranslation(["profile", "common"]);
    const { data: profile } = useGetMyProfileQuery();
    const {
        data: creditBalance,
        refetch: refetchCreditBalance,
    } = useGetAvailableCreditsQuery();

    const {
        data: earningsBalance,
        refetch: refetchEarningsBalance,
    } = useGetAccountsQuery();

    const { data: cryptoAccounts, refetch: refetchCryptoAccounts } = useGetCryptoAccountsQuery();

    const [
        updateProfile,
        { isError: isUpdateProfileError, isSuccess: isUpdateProfileSuccess },
    ] = useUpdateMyProfileMutation();

    useEffect(() => {
        if (selectedProfileHeader && selectedProfileHeaderURL) {
            URL.revokeObjectURL(selectedProfileHeaderURL);
            const newHeaderURL = URL.createObjectURL(selectedProfileHeader);
            setSelectedProfileHeaderURL(newHeaderURL);
        }

        if (selectedProfileHeader && !selectedProfileHeaderURL) {
            const newHeaderURL = URL.createObjectURL(selectedProfileHeader);
            setSelectedProfileHeaderURL(newHeaderURL);
        }

        return () => {
            URL.revokeObjectURL(selectedProfileHeaderURL);
        };
    }, [selectedProfileHeader]);

    useEffect(() => {
        if (selectedProfileAvatar && profile?.avatar) {
            URL.revokeObjectURL(profile?.avatar);
            const newAvatarURL = URL.createObjectURL(selectedProfileAvatar);
            setSelectedProfileAvatarURL(newAvatarURL);
        }

        if (selectedProfileAvatar && !selectedProfileAvatarURL) {
            const newAvatarURL = URL.createObjectURL(selectedProfileAvatar);
            setSelectedProfileAvatarURL(newAvatarURL);
        }

        return () => {
            URL.revokeObjectURL(selectedProfileAvatarURL);
        };
    }, [selectedProfileAvatar]);

    useEffect(() => {
        setProfileData({ ...profile });
    }, [profile]);

    useEffect(() => {
        if (creditBalance?.credit) {
            const { credit } = creditBalance;
            setTransactionCreditFormData({ credit });
        } else {
            setTransactionCreditFormData({ credit: 0 });
        }
    }, [creditBalance]);

    useEffect(() => {
        // Update earnings on mount
        refetchEarningsBalance();
        // update wallets on mount
        refetchCryptoAccounts();
    }, []);

    useEffect(() => {
        if (earningsBalance) {
            setEarningsFormData({
                earnings: earningsBalance?.balance,
            });
        }
    }, [earningsBalance]);

    const handleSubmit = async (values) => {
        const profileUpdate = { ...profile, ...values };
        try {
            const toastId = toast.loading("Updating profile...");
            if (selectedProfileHeader) {
                const response = await uploadFile({
                    file: selectedProfileHeader,
                });
                if (response?.data?.data?.id) {
                    const { id } = response.data.data;
                    profileUpdate.header_image = id;
                }
            }
            if (selectedProfileAvatar) {
                const response = await uploadFile({
                    file: selectedProfileAvatar,
                });
                if (response?.data?.data?.id) {
                    const { id } = response.data.data;
                    profileUpdate.avatar = id;
                }
            }
            const response = await updateProfile(profileUpdate);
            if (response.data) {
                // handle on success
                toast.update(toastId, {
                    render: "Profile updated successfully!",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
                history.push(`/app/user/${profile?.id}`);
            }
            if (response.error) {
                // handle on error
                toast.update(toastId, {
                    render: parseError(response.error) || "Error updating profile!",
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            }
        } catch (e) {}
    };

    const onSelectBanner = async (banner) => {
        setSelectedProfileHeader(banner);
        dispatch(modalActions.hideModal());
    };

    const onSelectAvatar = async (avatar) => {
        setSelectedProfileAvatar(avatar);
        dispatch(modalActions.hideModal());
    };

    const getProfileWithSelectedImages = () => {
        let formattedProfile = { ...profile };
        if (selectedProfileAvatarURL) {
            formattedProfile = {
                ...formattedProfile,
                avatar: selectedProfileAvatarURL,
            };
        }
        if (selectedProfileHeaderURL) {
            formattedProfile = {
                ...formattedProfile,
                header_image: selectedProfileHeaderURL,
            };
        }
        return formattedProfile;
    };

    const handleTopupRequest = (values, { setSubmitting }) => {
        dispatch(
            modalActions.showModal({
                modalType: "CreditTopUpModal",
                modalProps: {
                    onCreditTopUp: refetchCreditBalance,
                },
            })
        );
        setSubmitting(false);
    };

    const resolveBlockchainNameForCode = (code = "") => {
        const resolved  = SUPPORTED_BLOCKCHAINS[code];
        if (resolved) {
            return resolved;
        }
        return code;
    }

    const handleAddWallet = () => {
        dispatch(modalActions.showModal({
            modalType: 'AddCryptoAccountModal',
        }));
    }

    return (
        <>
            <NavBar showLinks />
            <div className="container edit-profile-page my-5">
                <h2 className="font-weight-bold text-center mb-3">
                    {t("PROFILE_SETTINGS")}
                </h2>

                <div className="col-xl-4 mx-auto">
                    <h6
                        className="text-center mb-5"
                        style={{ lineHeight: 1.5 }}
                    >
                        {t("COMPLETE_YOUR_PROFILE")}
                    </h6>
                </div>
                <div className="row justify-content-center align-items-start mt-4">
                    <div className="col-xl-2 d-none d-xl-block">
                        <div className="left-panel pro-image pt-4">
                            {t("PROFILE_PICTURE_COVER_IMAGE")}
                        </div>
                    </div>
                    <div className="col-xl-10 right-panel pt-4">
                        <ProfileHeader
                            user={getProfileWithSelectedImages()}
                            onSelectBanner={onSelectBanner}
                            onSelectAvatar={onSelectAvatar}
                            editProfile
                        />
                    </div>
                </div>
                <div className="row justify-content-center middle-panel-wrapper">
                    <div className="col-xl-2 d-none d-xl-block">
                        <div className="left-panel basic-info pt-4 h-100">
                            {t("BASIC_INFORMATION")}
                        </div>
                    </div>
                    <div className="col-xl-10 right-panel pt-4">
                        <Form
                            initialValues={profileData}
                            validationSchema={profileSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            <div className="col-12 col-md-8 col-xl-6">
                                <TextField
                                    name="first_name"
                                    label={t("DISPLAY_NAME")}
                                    inputClassName="input-class"
                                />
                            </div>
                            <div className="mt-4 col-12 col-md-8  col-xl-6">
                                <TextField
                                    name="email"
                                    label={t("EMAIL")}
                                    inputClassName="input-class"
                                />
                            </div>

                            <div className="mt-4 col-12 col-md-8  col-xl-6">
                                <TextAreaField
                                    name="bio"
                                    label={t("BIO")}
                                    inputClassName="input-class"
                                />
                            </div>

                            <div className="mt-5 col-12 col-md-8  col-xl-6">
                                <SubmitButton title={t("SAVE")} />
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="row align-items-start">
                    <div className="col-xl-2 d-none d-xl-block">
                        <div className="left-panel transaction-credit pt-5">
                            {t("TRANSACTION_CREDIT")}
                        </div>
                    </div>
                    <div className="col-xl-5 right-panel">
                        <div className="pt-5">
                            <Form
                                initialValues={transactionCreditFormData}
                                onSubmit={handleTopupRequest}
                                enableReinitialize
                            >
                                <div className="w-100 px-3">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <TextField
                                                type="number"
                                                name="credit"
                                                label={t("CREDIT_AMOUNT")}
                                                inputClassName="input-class"
                                                disabled
                                            />
                                        </div>
                                        <div className="col-xl-6">
                                            <SubmitButton title={t("TOPUP")} />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className="row align-items-start">
                    <div className="col-xl-2 d-none d-xl-block">
                        <div className="left-panel earnings pt-3">
                            {t("EARNINGS")}
                        </div>
                    </div>
                    <div className="col-xl-5 right-panel">
                        <div className="pt-3">
                            <Form
                                initialValues={earningsFormData}
                                enableReinitialize
                            >
                                <div className="w-100 px-3">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <TextField
                                                type="number"
                                                name="earnings"
                                                label={t("EARNED_AMOUNT")}
                                                inputClassName="input-class"
                                                disabled
                                            />
                                        </div>
                                        <div className="col-xl-6">
                                            <SubmitButton
                                                title={t("REQUEST_PAYOUT")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-2 d-none d-xl-block">
                        <div className="left-panel crypto-wallets h-100 pt-3">
                            {t("CRYPTO_WALLETS")}
                        </div>
                    </div>
                    <div className="col-xl-5 right-panel">
                        <div className="py-3">
                            <div className="w-100 px-3">
                                {cryptoAccounts?.length
                                    ? cryptoAccounts.map(({ id, chain, public_address: publicAddress }) => (
                                          <div key={id} className="row my-2">
                                              <div className="col-xl-3">
                                                  {resolveBlockchainNameForCode(chain)}
                                              </div>
                                              <div className="col-xl-9 text-muted">
                                                  {publicAddress}
                                              </div>
                                          </div>
                                      ))
                                    : "No crypto accounts found. Create one here."}
                            </div>
                        </div>
                        <div className="w-100 px-3">
                            <button
                                type="button"
                                className="w-gradient btn btn-primary submit-btn"
                                onClick={handleAddWallet}
                            >
                                Add New Wallet
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditProfilePage;
