import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
    Form,
    SubmitButton,
    TextField,
} from "../../../components/FormElements";
import CustomPaymentForm from "../../../components/Payments/CustomPaymentForm/CustomPaymentForm";
import { TOAST_CONFIG } from "../../../config";
import { useTopupCreditMutation } from "../../../services/creditService";
import parseError from "../../../utils/errorUtils";

const TopupCredits = ({ credits="0.00", onCancel, onSuccess }) => {
    const { t } = useTranslation(["profile", "common"]);
    const [showPaymentForm, setShowPaymentForm] = React.useState(false);
    const [isPaymentSuccessFull, setIsPaymentSuccessFull] = React.useState(
        false
    );
    const [wertPaymentId, setWertPaymentId] = React.useState("")

    const [topupFormData, setTopupFormData] = React.useState({
        credit: "",
    });

    const [topupCredits] = useTopupCreditMutation();

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    };

    const handleOnPaymentSuccess = () => {
        setIsPaymentSuccessFull(true);
        if (onSuccess) {
            onSuccess();
        }
    };

    const handleOnTopupAgain = () => {
        setTopupFormData({ credit: "" });
        setShowPaymentForm(false);
        setWertPaymentId("");
    }

    const onSubmitTopupRequest = async ({ credit }) => {
        setTopupFormData({ credit });
        setIsPaymentSuccessFull(false);
        // call api and get stripe secret
        try {
            const toastId = toast.loading("Requesting credit topup...");
            const response = await topupCredits({ amount: credit });
            if (response.data) {
                // handle on success
                toast.update(toastId, {
                    render:
                        "Credit topup request success. Redirecting to payment.",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
                const { data: paymentId } = response;
                setWertPaymentId(paymentId);
                setShowPaymentForm(true);
            }
            if (response.error) {
                // handle on error
                toast.update(toastId, {
                    render: parseError(response.error) || "Error requesting topup.",
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {showPaymentForm ? (
                <>
                    <div className="col-12 mx-auto">
                        <h6
                            className="text-center mb-4"
                            style={{ lineHeight: 1.5 }}
                        >
                            {t("BUY_CREDITS")}
                        </h6>
                    </div>
                    {isPaymentSuccessFull ? (
                        <>
                            <div className="col-xl-6 mx-auto px-4 py-3">
                                <div className="d-flex justify-content-center">
                                    <img
                                        src="/assets/img/success-tick.png"
                                        alt="Success"
                                        className="payment-success-img w-75 img-fluid mx-auto my-4"
                                    />
                                </div>
                                <div className="text-center font-weight-bold">
                                    {t("TRANSACTION_SUCCESS")}
                                </div>
                                <div className="text-center font-weight-bold">
                                    {`${t("YOU_SUCCESSFULLY_TOPPED_UP")} `}
                                    {topupFormData?.credit}
                                </div>
                                <div className="my-4">
                                    <div className="p-4 border rounded text-center">
                                        <div className="credit-amount-label">
                                            {t("CREDIT_AMOUNT")}
                                        </div>
                                        <span className="pt-2">{credits}</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button type="button" className="btn text-secondary mx-2" onClick={handleOnCancel}>
                                        {t("BACK")}
                                    </button>
                                    <button type="button" className="w-gradient btn btn-primary submit-btn mx-2" onClick={handleOnTopupAgain}>
                                        {t("TOPUP_AGAIN")}
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="col-xl-6 mx-auto px-4 py-3 border rounded">
                            <div className="text-center text-secondary">
                                TopUp Account
                            </div>
                            <div className="text-center pb-4">
                                {topupFormData.credit || "0.00"}(USD)
                            </div>
                            <CustomPaymentForm
                                currencyAmount={topupFormData.credit}
                                paymentId={wertPaymentId}
                                onSuccess={handleOnPaymentSuccess}
                                successMsg="Topup successfull."
                            />
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div className="col-12 mx-auto">
                        <h6
                            className="text-center mb-4"
                            style={{ lineHeight: 1.5 }}
                        >
                            {t("TOPUP_CREDITS")}
                        </h6>
                    </div>
                    <div className="col-xl-10 offset-xl-1 mx-auto">
                        <Form onSubmit={onSubmitTopupRequest} initialValues={topupFormData} enableReinitialize>
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="pr-5 border-right">
                                            <TextField
                                                name="credit"
                                                label={t("TOPUP_AMOUNT_USD")}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 offset-lg-2">
                                        <button
                                            type="button"
                                            className="btn text-secondary mx-2 h-100 w-100"
                                            onClick={handleOnCancel}
                                        >
                                            {t("CANCEL")}
                                        </button>
                                    </div>
                                    <div className="col-lg-2">
                                        <SubmitButton title={t("CONFIRM")} className="w-100" />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </>
            )}
        </>
    );
};

export default TopupCredits;
