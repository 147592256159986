import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import * as Yup from "yup";
import Footer from "../../components/Footer/Footer";
import {
    Form,
    NFTFileUploadField,
    NFTThumbnailFileField,
    TextField,
    TextAreaField,
    Select,
    SubmitButton,
    DisplayField,
    PercentageField,
} from "../../components/FormElements/FormElements";
import { modalActions } from "../../state/modal";
import NavBar from "../../components/NavBar";
import parseError from "../../utils/errorUtils";
import { useGetMyProfileQuery } from "../../services/appService";
import {
    useCreateNFTMutation,
    useGetNFTTagsQuery,
} from "../../services/nftService";
import { useCreateIPFSCopyMutation } from "../../services/fileService";
import { uploadFiles } from "../../api";
import {
    SUPPORTED_BLOCKCHAINS,
    TOAST_CONFIG,
} from "../../config";
import { compressImage } from "../../utils/imageUtils";
import { useCryptoWallets } from "../../hooks/cryptoWallet";
import { useTransactionCredits } from "../../hooks/transactionCredits";
import "./NFTPages.styles.scss";

const isError = false;
const responseData = null;

const blockChainOptions = window.Object.keys(SUPPORTED_BLOCKCHAINS).map(
    (blockchainKey) => ({
        value: blockchainKey,
        label: SUPPORTED_BLOCKCHAINS[blockchainKey],
    })
);

const createNFTSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required").max(500),
    tag: Yup.string().required("Required"),
    chain: Yup.string().required("Required"),
    royalty: Yup.number()
        .nullable()
        .min(1, "Minimum value is 1")
        .max(100, "Maximum value is 100"),
});

const MintNFTPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [nftFormData, setNFTFormData] = useState({
        nftFile: "",
        thumbnail: "",
        name: "",
        description: "",
        bio: "",
        chain: "",
        royalty: "",
        tag: "",
    });
    const [pendingMessage, setPendingMessage] = useState("Creating NFT ...");
    // chaging pending message is tempororily disabledin the toast hook. There seem to be an issue with toastify for this.

    const { data: myProfile } = useGetMyProfileQuery();

    const [createNFT] = useCreateNFTMutation();

    const { data: NFTTagsData } = useGetNFTTagsQuery();
    const categoryOptions = NFTTagsData?.allIds
        ? NFTTagsData?.allIds?.map((categoryId) => ({
              value: categoryId,
              label: NFTTagsData?.byId[categoryId]?.name,
          }))
        : [];

    const [addIPFS] = useCreateIPFSCopyMutation();

    const {
        isMatchingWalletAvailable,
        isWalletReadyForTransactions,
    } = useCryptoWallets();

    const {  creditBalanceDetails, isCreditBalanceSufficient } = useTransactionCredits();

    const onChangeThumbnail = async (files) => {
        setNFTFormData((prevNFTFormData) => ({
            ...prevNFTFormData,
            thumbnail: files,
        }));
    };

    const scrollToFormAfterNFTSelecting = () => {
        const element = window.document.querySelector("#nft-details-form");
        element.scrollIntoView({
            behavior: "smooth",
        });
    };

    const setThumbnailIfNFTIsAnImage = (files) => {
        if (files?.length) {
            const fileObject = files[files.length - 1];
            if (fileObject?.file) {
                const { type } = fileObject.file;
                if (type.includes("image")) {
                    const thumbnailFiles = [fileObject];
                    onChangeThumbnail(thumbnailFiles);
                }
            }
        }
    };

    const onChangeNftfile = (files) => {
        // init thumbnail if files are empty or removed
        if (!files?.length) {
            onChangeThumbnail("");
        }
        setNFTFormData((prevNFTFormData) => ({
            ...prevNFTFormData,
            nftFile: files,
        }));
        setThumbnailIfNFTIsAnImage(files);
        // Scroll below after preview is shown
        setTimeout(() => {
            if (files?.length) {
                scrollToFormAfterNFTSelecting();
            }
        }, 600);
    };

    const onChangeName = (e) => {
        setNFTFormData((prevNFTFormData) => ({
            ...prevNFTFormData,
            name: e.target.value,
        }));
    };
    const onChangeDescription = (e) => {
        setNFTFormData((prevNFTFormData) => ({
            ...prevNFTFormData,
            description: e.target.value,
        }));
    };
    const onChangeBlockchain = (e) => {
        const { value: chain } = e.target;
        setNFTFormData((prevNFTFormData) => ({
            ...prevNFTFormData,
            chain,
        }));
        handleWalletNotAvailable(chain);
    };
    const onChangeRoyalty = (e) => {
        setNFTFormData((prevNFTFormData) => ({
            ...prevNFTFormData,
            royalty: e.target.value,
        }));
    };
    const onChangeTag = (e) => {
        setNFTFormData((prevNFTFormData) => ({
            ...prevNFTFormData,
            tag: e.target.value,
        }));
    };

    const handleSubmit = async (values) => {
        const {
            name,
            description,
            chain,
            royalty,
            nftFile,
            thumbnail,
            tag,
        } = values;
        const thumbnailCompressionOptions = {
            maxSizeMB: 1,
            maxWidthOrHeight: 255,
            useWebWorker: true,
        };
        const nftFileformData = new window.FormData();
        nftFileformData.append("file", nftFile[0]?.file);
        
        const toastId = toast.loading(pendingMessage);

        try {
            const fileRes = await uploadFiles(nftFileformData);
            const fileId = fileRes?.data.id;
            const { file } = thumbnail[0];
            const compressedThumb = await compressImage(
                file,
                thumbnailCompressionOptions
            );
            const thumbFormData = new window.FormData();
            thumbFormData.append("file", compressedThumb);
            const thumbnailRes = await uploadFiles(thumbFormData);
            const thumbnailId = thumbnailRes?.data.id;

            const metaData = { name, description };
            const ipfsResponse = await addIPFS({ fileId, metaData });
            // check if ipfs response error
            if (ipfsResponse?.error) {
                toast.update(toastId, {
                    render: parseError(
                        ipfsResponse?.error,
                        "Unable to create NFT metadata"
                    ),
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            } else {
                // If metadata request succeed
                const response = await createNFT({
                    tag: window.parseInt(tag),
                    chain,
                    file: fileId,
                    thumbnail: thumbnailId,
                    royalty: !!royalty,
                    cashback_value: royalty * 100,
                });
                if (response.data) {
                    // handle on success
                    toast.update(toastId, {
                        render: "NFT Created",
                        type: toast.TYPE.SUCCESS,
                        isLoading: false,
                        autoClose: TOAST_CONFIG.autoClose,
                        closeButton: TOAST_CONFIG.closeButton,
                    });
                    history.push(`/app/user/${myProfile.id}`);
                }
                if (response.error) {
                    // handle on error
                    toast.update(toastId, {
                        render: parseError(
                            response?.error,
                            "Unable to create NFT"
                        ),
                        type: toast.TYPE.ERROR,
                        isLoading: false,
                        autoClose: TOAST_CONFIG.autoClose,
                        closeButton: TOAST_CONFIG.closeButton,
                    });
                }
            }
        } catch (error) {
            console.error(error);
            toast.update(toastId, {
                render: parseError(
                    error,
                    "Error while trying to create NFT"
                ),
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: TOAST_CONFIG.autoClose,
                closeButton: TOAST_CONFIG.closeButton,
            });
        }
    };

    const handleOnChainValidationModalClose = () => {
        setNFTFormData((prevNFTFormData) => ({
            ...prevNFTFormData,
            chain: "",
        }));
    };

    const handleWalletNotAvailable = (chain) => {
        if (isMatchingWalletAvailable(chain)) {
            if (!isWalletReadyForTransactions(chain)) {
                // If a valid wallet found but wallet address is not found
                dispatch(
                    modalActions.showModal({
                        modalType: "ConfirmationModal",
                        modalProps: {
                            title: "Wallet unavailable",
                            description:
                                "Your wallet for this blockchain is still being created. If it's taking too much time you can retry creating the wallet from the wallet settings",
                            variant: "warning",
                            onDone: () => {
                                history.push("/app/settings/crypto-wallets");
                            },
                            onCancel: handleOnChainValidationModalClose,
                            doneBtnText: "Go to Settings",
                        },
                    })
                );
            }
        } else {
            // If a valid wallet for selected chain is not found
            dispatch(
                modalActions.showModal({
                    modalType: "ConfirmationModal",
                    modalProps: {
                        title: "Wallet not created",
                        description:
                            "You don't have a wallet for this blockchain. Please create an account to continue.",
                        variant: "warning",
                        onDone: () => {
                            history.push("/app/settings/crypto-wallets");
                        },
                        onCancel: handleOnChainValidationModalClose,
                        doneBtnText: "Create Wallet",
                    },
                })
            );
        }
    };

    const handleCreditNotSufficient = () => {
        if (!isCreditBalanceSufficient()) {
            dispatch(
                modalActions.showModal({
                    modalType: "ConfirmationModal",
                    modalProps: {
                        title: "Insufficient Transaction Credits",
                        description:
                            "Your credit balance is running low. Top-up your transaction credits to continue.",
                        variant: "warning",
                        onDone: () => {
                            history.push("/app/settings/credits");
                        },
                        doneBtnText: "Topup Credits",
                        onCancel: () => {
                            history.goBack();
                        },
                        blocking: true,
                    },
                })
            );
        }
    };

    React.useEffect(() => {
        // validate credits when the balance is availabe
        if (creditBalanceDetails) {
            handleCreditNotSufficient();
        }
    }, [creditBalanceDetails]);

    return (
        <>
            <NavBar />
            <Form
                initialValues={nftFormData}
                onSubmit={handleSubmit}
                validationSchema={createNFTSchema}
                enableReinitialize
            >
                <div className="container mint-nft-content">
                    <img
                        src="/assets/icon/hg-pen-tip-down.svg"
                        alt=" "
                        className="pen-tip-down"
                    />
                    <div className="h4 font-weight-bold">
                        Let&apos;s Create an NFT.
                    </div>
                    <small className="mt-3 mb-5 small-text">
                        Tell us about your creation and turn that into an NFT.
                    </small>
                    <div className="h5 font-weight-bold"> Upload File </div>
                    <small className="mt-3 mb-5 small-text">
                        Upload your digital artwork here.
                    </small>
                    <div className="nft-file-selector mx-auto">
                        <NFTFileUploadField
                            name="nftFile"
                            label="Select file for your NFT"
                            onChangeFiles={onChangeNftfile}
                        />
                    </div>
                    <div id="nft-details-form" className="w-100 mt-4">
                        <div className="row">
                            <div className="col-md-5 offset-md-1 border-md-right">
                                <div className="pt-5">
                                    <NFTThumbnailFileField
                                        name="thumbnail"
                                        onChangeFiles={onChangeThumbnail}
                                    />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="w-100 px-3">
                                    <div className="row my-5">
                                        <div className="col-12">
                                            <TextField
                                                label="Name"
                                                name="name"
                                                onChange={onChangeName}
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-5">
                                        <div className="col-12">
                                            <TextAreaField
                                                label="Description"
                                                name="description"
                                                onChange={onChangeDescription}
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-5">
                                        <div className="col-12">
                                            <Select
                                                name="tag"
                                                options={categoryOptions}
                                                defaultLabel="Choose Category..."
                                                onChange={onChangeTag}
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-5">
                                        <div className="col-12">
                                            <Select
                                                name="chain"
                                                options={blockChainOptions}
                                                defaultLabel="Choose Blockchain..."
                                                onChange={onChangeBlockchain}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-5 mb-3">
                                        <div className="col-12">
                                            <PercentageField
                                                label="Royalty"
                                                name="royalty"
                                                onChange={onChangeRoyalty}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-5 mb-3">
                                        <div className="col-12">
                                            <DisplayField fieldName="status">
                                                {() =>
                                                    isError ? (
                                                        <div className="text-danger font-weight-bold mt-2">
                                                            <p
                                                                style={{
                                                                    fontSize: 14,
                                                                }}
                                                                className="mb-0"
                                                            >
                                                                {parseError(
                                                                    responseData
                                                                )}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </DisplayField>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-5">
                            <div className="col-md-6 offset-md-6 px-5">
                                <SubmitButton title="CREATE" />
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
            <Footer />
        </>
    );
};

export default MintNFTPage;
