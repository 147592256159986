import React from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../state/modal";
import { FileUpload } from "../../FileUpload/FileUpload";
import "./ImagePickerModal.styles.scss";

const ImagePickerModal = ({
    onSelect,
    onPick,
    accept = ["image/png", "image/jpeg"],
}) => {
    const dispatch = useDispatch();
    const [files, setFiles] = React.useState([]);

    React.useEffect(() => {
        if (onPick && files.length) {
            const [file] = files;
            onPick(file);
        }
    }, [files, onPick]);

    const handleFileSelect = () => {
        const [file] = files;
        if (onSelect) {
            onSelect(file);
        }
    };

    const handleCancelClick = () => {
        dispatch(modalActions.hideModal());
    };

    return (
        <div className="image-picker-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <FileUpload
                            label="Drop an image to continue"
                            files={files}
                            setFiles={setFiles}
                            acceptedFiles={accept}
                            allowImageCrop
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-outlined-primary"
                            onClick={handleCancelClick}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            disabled={files.length === 0}
                            onClick={handleFileSelect}
                        >
                            Select
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImagePickerModal;
