export const normaliseObjectArray = (array) => {
    const result = {byId:{}, allIds:[]}
    const byId = array.reduce((_byId, item) => {
        const newById = {..._byId}
        newById[item.id] = item;
        return newById;
    }, {});
    result.byId = byId;
    result.allIds = Object.keys(byId).map(id=>parseInt(id));
    return result;
};


export const getDisplayName = (user) => {
    const nameOptions = [];
    if (user?.first_name) {
        nameOptions.push(user.first_name);
    }
    if (user?.last_name) {
        nameOptions.push(user.last_name);
    }
    if (nameOptions.length === 0) {
        nameOptions.push(user?.email);
    }
    return nameOptions.join(" ");
}

export const getDisplayNameInitials = (user) => {
    const nameOptions = [];
    if (user?.first_name && !user?.last_name) {
        nameOptions.push(user.first_name.charAt(0));
        nameOptions.push(user.first_name.charAt(1));
    }
    if (user?.last_name && !user?.first_name) {
        nameOptions.push(user.last_name.charAt(0));
        nameOptions.push(user.last_name.charAt(1));
    }
    if (user?.first_name && user?.last_name) {
        nameOptions.push(user.first_name.charAt(0));
        nameOptions.push(user.last_name.charAt(0));
    }
    if (nameOptions.length === 0 && user?.email) {
        const { email } = user;
        nameOptions.push(email.charAt(0));
        nameOptions.push(email.charAt(email.length - 1));
    }
    return nameOptions.join("");
}

export const getGradientForUserDisplayName  = (user) => {
    const gradientOptions = [
        { c1: "#7000FF", c2: "#00FFA3"},
        { c1: "#ff00cc", c2: "#333399"},
        { c1: "#0575E6", c2: "#021B79"},
        { c1: "#c94b4b", c2: "#4b134f"},
        { c1: "#8E2DE2", c2: "#4A00E0"},
        { c1: "#c471ed", c2: "#f64f59"},
    ]
    const name = getDisplayName(user);
    if (name) {
        const colorIdx = name.length%6;
        return gradientOptions[colorIdx];
    };
    return gradientOptions[0];
};