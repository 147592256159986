import React from "react";
import constants from "../../utils/constants";
import { ArtCard } from "../Cards";
import ArtCardSkeleton from "../Skeletons/ArtCardSkeleton";

import "./Grids.styles.scss";

function NFTGrid({
    nftData,
    isLoading,
    gridTitle,
    cardBtnTitle = "View",
    onCardClick,
    showPendingNFTs = false,
}) {
    const handleCardClick = (id) => {
        onCardClick(id);
    };

    const hasPendingPayment = ({ latest_listing: latestListing }) => {
        let currentStatus = false;
        if (
            showPendingNFTs &&
            (latestListing?.status ===
                constants.NFT_LISTING_STATUS_PAYMENT_PROCESSING ||
                latestListing?.status === constants.NFT_LISTING_STATUS_PAID)
        ) {
            currentStatus = true;
        }
        return currentStatus;
    };

    return (
        <>
            {gridTitle && <NFTGridHeader gridTitle={gridTitle} />}
            <div className={`${gridTitle ? "nft-grid-wrapper" : ""}`}>
                <div className="row justify-content-start pt-2 pb-5">
                    {isLoading &&
                        new Array(12)
                            .fill()
                            .map((item, index) => index)
                            .map((item) => (
                                <div
                                    className="col-md-6 col-lg-4 col-xxl-3 art-card mt-5"
                                    key={item}
                                >
                                    <ArtCardSkeleton />
                                </div>
                            ))}
                    {!isLoading &&
                        (nftData?.allIds.length ? (
                            nftData?.allIds.map((currNFTId) => (
                                <div
                                    className="col-md-6 col-lg-4 col-xxl-3 art-card mt-5"
                                    key={currNFTId}
                                >
                                    <ArtCard
                                        nftDetails={nftData.byId[currNFTId]}
                                        btnTitle={cardBtnTitle}
                                        onBtnClick={() =>
                                            handleCardClick(currNFTId)
                                        }
                                        isPending={hasPendingPayment(
                                            nftData.byId[currNFTId]
                                        )}
                                    />
                                </div>
                            ))
                        ) : (
                            <h4 className="w-100 font-weight-bold text-center d-block py-4">
                                No NFT&apos;s to show
                            </h4>
                        ))}
                </div>
            </div>
        </>
    );
}

export default NFTGrid;

function NFTGridHeader({ gridTitle }) {
    return (
        <div className="nft-grid-header">
            <div className="grid-title">{gridTitle}</div>
        </div>
    );
}
