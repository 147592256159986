import React from "react";
import { useTranslation } from "react-i18next";
import { useGetAvailableCreditsQuery } from "../../../services/creditService";
import "./Credits.styles.scss";
import TopupCredits from "./TopupCredits";
import ViewCredits from "./ViewCredits";

const Credits = () => {
    const { t } = useTranslation(["profile", "common"]);

    const [isTopupModeActive, setIsTopupModeActive] = React.useState(false);

    const {
        data: creditBalance,
        refetch: refetchCreditBalance,
    } = useGetAvailableCreditsQuery();

    const activateCreditTopUpMode = () => {
        setIsTopupModeActive(true);
    };

    const handleOnTopupCancel = () => {
        setIsTopupModeActive(false);
    };

    const onTopupPaymentSuccess = () => {
        refetchCreditBalance();
    };

    return (
        <div className="container my-5">
            <h4 className="font-weight-bold text-center mb-3">
                {t("CREDITS")}
            </h4>
            <div className="row">
                {isTopupModeActive ? (
                    <TopupCredits
                        credits={creditBalance?.credit}
                        onSuccess={onTopupPaymentSuccess}
                        onCancel={handleOnTopupCancel}
                    />
                ) : (
                    <ViewCredits
                        credits={creditBalance?.credit}
                        onClickTopup={activateCreditTopUpMode}
                    />
                )}
            </div>
        </div>
    );
};

export default Credits;
