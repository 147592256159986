import React from "react";
import WertModule from "@wert-io/module-react-component";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import { TOAST_CONFIG } from "../../../config";
import "./CustomPaymentForm.styles.scss";

const CustomPaymentForm = ({ paymentId, onSuccess, successMsg="", currencyAmount=0 }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const handleOnLoad = () => {
        setIsLoading(false);
    };

    const handleOnError = ({ message }) => {
        toast(`Something went wrong. ${message}`, {
            type: toast.TYPE.ERROR,
            autoClose: TOAST_CONFIG.autoClose,
        });
    };

    const handleOnPaymentStatus = ({ status }) => {
        switch (status) {
            case "pending":
                toast("Please wait, your payment is processing.", {
                    type: toast.TYPE.DEFAULT,
                    autoClose: TOAST_CONFIG.autoClose,
                });
                break;
            case "canceled":
                toast("Oh snap! your payment was cancelled.", {
                    type: toast.TYPE.ERROR,
                    autoClose: TOAST_CONFIG.autoClose,
                });
                break;
            case "failed":
                toast("Oh snap! payment attempt failed.", {
                    type: toast.TYPE.ERROR,
                    autoClose: TOAST_CONFIG.autoClose,
                });
                break;
            case "success":
                toast(successMsg || "Payment successfull!", {
                    type: toast.TYPE.SUCCESS,
                    autoClose: TOAST_CONFIG.autoClose,
                });
                onSuccess();
                break;
            default:
                break;
        }
    };

    const wertOptions = {
        click_id: paymentId,
        origin: process.env.REACT_APP_WERT_ORIGIN_URL,
        partner_id: process.env.REACT_APP_WERT_PARTNER_ID,
        listeners: {
            loaded: handleOnLoad,
            error: handleOnError,
            "payment-status": handleOnPaymentStatus,
        },
        currency_amount: currencyAmount, // default amount to pay
        commodity: process.env.REACT_APP_WERT_COMMODITY,
        commodities: process.env.REACT_APP_WERT_COMMODITY,
        address: process.env.REACT_APP_WERT_WALLET_ADDRESS, // Wallet address used to send crypto
        color_buttons: "#2C41FF",
    };
    
    return (
        <div className="custom-payment-form">
            <AnimatePresence>
                <WertModule options={wertOptions} />
                {isLoading && (
                    <motion.div
                        initial={{ opacity: 0}}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="loading-overlay">
                            <div
                                className="spinner-border text-primary"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="overlay-text">
                                Please wait...
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default CustomPaymentForm;
