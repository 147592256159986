import React from "react";
import OutsideAlerter from "../OutsideAlerter";

function FilterMenu({
    icon,
    title = "",
    children,
    className,
    isArrowIcon,
    selectedOption,
    isSelect = false,
    setIsSelect,
    hasNestedFilters=false,
}) {
    const [show, setShow] = React.useState(false);
    const handleOnClick = () => {
        setShow(!show);
        if (isSelect) {
            setIsSelect(false);
            setShow(true);
        }
    };

    const handleOnClickOptions = () => {
        // hide dropdown options if one clicked
        if (!hasNestedFilters) {
            handleOnClick();
        }
    }

    const handleOutsideClick = () => {
        setShow(false);
    }

    return (
        <OutsideAlerter onClickOutside={handleOutsideClick}>
        <div className="filter-menu-container">
            <button
                type="button"
                className={`d-flex justify-content-around align-items-center filter-option bg-white${
                    show && !isSelect ? "remove-border-radius" : ""
                }`}
                onClick={handleOnClick}
            >
                <div
                    className={`d-flex align-items-center py-2 ${
                        className || ""
                    }`}
                >
                    {icon && <img className="icon" src={icon} alt="" />}
                    {title && <div className="menu-title ml-2">{title}</div>}
                    {selectedOption && (
                        <div className="selected-option"> {selectedOption}</div>
                    )}
                </div>

                {isArrowIcon && (
                    <div className="py-2">
                        {(!show || isSelect) && (
                            <img
                                src="/assets/icon/hg-arrow-down.svg"
                                alt=" "
                                className="icon mx-2"
                            />
                        )}
                        {show && !isSelect && (
                            <img
                                src="/assets/icon/hg-arrow-up.svg"
                                alt=" "
                                className="icon mx-2"
                            />
                        )}
                    </div>
                )}
            </button>
            <div onClick={handleOnClickOptions}>
            {show && !isSelect && <>{children}</>}
            </div>
        </div>
        </OutsideAlerter>
    );
}

export default FilterMenu;
