import React from "react";

function CategoryFilter({ categories, catFilter, setCatFilter }) {

    return (
        <div className="d-flex category-filter justify-content-center align-items-ceter flex-wrap">
            {categories.map((cat, index) => (
                <div
                    className={`mx-4 category-item ${
                        catFilter === cat.value ? "active-category" : ""
                    }`}
                    onClick={() => setCatFilter(cat.value)}
                    key={index}
                >
                    {cat.label}
                </div>
            ))}
        </div>
    );
}

export default CategoryFilter;
