import compressor from "browser-image-compression";
import { DEFAULT_IMAGE_COMPRESSION_OPTIONS } from "../config";

export const compressImage = async (image, options) => {
    let compressed;
    const optns = options || DEFAULT_IMAGE_COMPRESSION_OPTIONS;
    try {
        compressed = await compressor(image, optns);
    } catch (error) {
        console.error(`Error during image compression. ${error}`);
    };
    return compressed || image;
}