import React from "react";
import dayjs from "dayjs";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import { useGetNFTListingsQuery } from "../../services/nftService";
import constants from "../../utils/constants";

ChartJS.register(...registerables);

const chartOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            grid: {
                drawBorder: true,
                display: false,
            },
        },
        y: {
            grid: {
                drawBorder: true,
                display: true,
            },
        },
    },
};

const NFTPriceHistoryChart = ({ nft }) => {
    const { data: nftListingHistory } = useGetNFTListingsQuery(nft?.id, {
        skip: !nft?.id,
    });

    const priceHistoryData = React.useMemo(() => {
        const historyData = {
            labels: [],
            datasets: [
                {
                    label: "Listing Price",
                    data: [],
                    fill: true,
                    backgroundColor: "rgba(44, 65, 255,0.2)",
                    borderColor: "#2c41ff",
                },
            ],
        };
        if (nftListingHistory?.length) {
            const listingDates = [];
            const listingPrices = [];
            nftListingHistory
            .filter(({ status }) => status === constants.NFT_LISTING_STATUS_SOLD)
            .forEach(({ price, created_at: listingDate }) => {
                listingDates.push(dayjs(listingDate).format("MM/DD"));
                listingPrices.push(Number(price).toFixed(1));
            });
            historyData.labels = listingDates;
            historyData.datasets[0].data = listingPrices;
        }

        return historyData;
    }, [nftListingHistory])

    return (
    <div className="price-history-chart-container">
        <Line data={priceHistoryData} options={chartOptions} height={75} />
    </div>
)};
export default NFTPriceHistoryChart;
