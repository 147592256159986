import React from 'react';
import {useSelector} from "react-redux";

export const Spinner = (props) => {
    const spinnerState = useSelector(state => state.spinner);
    return (
        (spinnerState.isVisible ?
            <div className="spinner">
                <div className="spinner-border text-primary">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            :null
            )
    );
}