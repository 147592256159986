import React from "react";
import "./FilterMenuItem.styles.scss";

const FilterMenuItem = ({
    active = false,
    onClick,
    label = "",
    selectable = false,
    disabled = false,
}) => {
    const handleOnItemClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (
        <button
            type="button"
            onClick={handleOnItemClick}
            className="filter-menu-item"
            disabled={disabled}
        >
            <label className="menu-item-label">{label}</label>
            {selectable && (
                <input
                    type="checkbox"
                    className="menu-item-input"
                    checked={active}
                />
            )}
        </button>
    );
};

export default FilterMenuItem;
