import React from "react";
import { useTranslation } from "react-i18next";

const ViewCredits = ({ credits = "0.00", onClickTopup }) => {
    const { t } = useTranslation(["profile", "common"]);
    const handleOnTopup = () => {
        if (onClickTopup) {
            onClickTopup();
        }
    };
    return (
        <>
            <div className="col-12 mx-auto">
                <h6 className="text-center mb-4" style={{ lineHeight: 1.5 }}>
                    {t("BUY_CREDITS")}
                </h6>
            </div>
            <div className="col-xl-6 offset-xl-3">
                <div className="p-4 border rounded text-center">
                    <div className="credit-amount-label">
                        {t("CREDIT_AMOUNT")}
                    </div>
                    <span className="pt-2">{Number(credits).toFixed(2)}</span>
                </div>
            </div>
            <div className="col-xl-6 offset-xl-3 mt-3">
                <button
                    type="button"
                    className="d-block mx-auto w-gradient btn btn-primary submit-btn"
                    onClick={handleOnTopup}
                >
                    {t("TOPUP")}
                </button>
            </div>
        </>
    );
};

export default ViewCredits;
