import React from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../state/modal";

const ContentCurrentlyUnavailable = () => {
    const dispatch = useDispatch();
    const { hideModal } = modalActions;
    const handleOnClickOkay = () => {
        dispatch(hideModal());
    };
    return (
        <div className="container">
            <div className="row">
                <div className="col-10 offset-1 p-3 text-center">
                    Oh snap! This functionality is not available yet.
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 offset-md-4 px-3 pb-3">
                    <button
                        type="button"
                        className="btn btn-outline-primary w-100"
                        onClick={handleOnClickOkay}
                    >
                        Okay
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ContentCurrentlyUnavailable;
