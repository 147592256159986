import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./CollapsibleRecord.styles.scss";

const CollapsibleRecord = ({ icon, title = "", children }) => {
    const [show, setShow] = React.useState(false);
    const handleOnClick = () => {
        setShow(!show);
    };
    return (
        <>
            <div className={`collapsible-record-root ${show ? "show" : ""}`}>
                <div className="info">
                    {icon && <img src={icon} alt=" " className="icon pr-4" />}
                    <div className="pr-4 text-truncate h5 font-weight-bold">
                        {title}
                    </div>
                </div>
                <div className="actions">
                    <button
                        type="button"
                        className="action-btn"
                        onClick={handleOnClick}
                    >
                        {!show && (
                            <img src="/assets/icon/hg-arrow-down.svg" alt=" " />
                        )}
                        {show && (
                            <img src="/assets/icon/hg-arrow-up.svg" alt=" " />
                        )}
                    </button>
                </div>
            </div>
            <AnimatePresence>
                {show && <motion.div
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 }
                    }}
                    transition={{ duration: 0.5 }}
                    className={`collapsible-record-content collapse ${
                        show ? "show" : ""
                    }`}
                    style={{overflowY:"hidden"}}
                >
                    {children}
                </motion.div>}
            </AnimatePresence>
        </>
    );
};

export default CollapsibleRecord;
