import React from "react";
import "./Button.styles.scss";

const Button = ({ onClick, title, disabled }) => (
    <button type="button" className="button btn btn-primary w-gradient" onClick={onClick} disabled={disabled}>
        {title || ""}
        <div className="button-glow" />
    </button>
);

export default Button;
