import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useHistory, Link } from "react-router-dom";
import {
    ACCESS_TOKEN_NAME,
    REFRESH_TOKEN_NAME,
    TEMPORARY_TOKEN_NAME,
    TOAST_CONFIG,
} from "../../config";
import {
    Form,
    TextField,
    SubmitButton,
    DisplayField,
    Checkbox,
} from "../../components/FormElements";
import AuthNavBar from "../../components/NavBar/AuthNavBar";
import "./AuthPage.styles.scss";
import {
    useRegisterMutation,
    useRequestPasswordResetMutation,
    useSignInMutation,
} from "../../services/appService";
import { useWaitingToast } from "../../hooks/toastHooks";
import parseError from "../../utils/errorUtils";
import { useTranslation } from "react-i18next";
import { useGetMyProfileWithTempTokenQuery } from "../../services/tempAppService";

import { useTemporaryLoginStatus } from "../../hooks/auth";

const LoginFormSchema = Yup.object().shape({
    username: Yup.string().required("Required").min(1, "Required"),
    password: Yup.string().required("Required").min(6, "Too short"),
});

export default function SignInPage() {
    const history = useHistory();
    const { t, i18n } = useTranslation(["auth", "common"]);

    const [rememberMe] = useState(false);
    const [signinComplete, setSigninComplete] = useState(false);
    const [formData] = useState({
        username: "",
        password: "",
    });
    const [
        signIn,
        { error: signInError, isError: isSignInError },
    ] = useSignInMutation();

    const {
        data: myProfile,
        refetch: refetchMyProfile,
    } = useGetMyProfileWithTempTokenQuery({}, { skip: !signinComplete });

    useEffect(() => {
        if (signinComplete && myProfile) {
            checkMobileVerificationStatus(myProfile);
        }
    }, [myProfile, signinComplete]);

    const checkMobileVerificationStatus = (profileData) => {
        if (profileData?.reg_status === "PENDING_MOBILE") {
            history.push("/app/add/mobile");
        } else if (profileData?.reg_status === "VERIFIED") {
            history.push("/app/verify/otp");
        }
    };

    const onSubmit = async (values) => {
        setSigninComplete(false);
        const { username, password } = values;
        try {
            const toastId = toast.loading("Please wait...");
            let response = await signIn({ username, password });

            if (response.data) {
                // handle on success
                toast.update(toastId, {
                    render: "Signed In",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
                response = response.data;

                if (response && response.access) {
                    sessionStorage.setItem(
                        TEMPORARY_TOKEN_NAME,
                        response.access
                    );
                    if (rememberMe) {
                        localStorage.setItem(
                            REFRESH_TOKEN_NAME,
                            response.refresh
                        );
                    } else {
                        sessionStorage.setItem(
                            REFRESH_TOKEN_NAME,
                            response.refresh
                        );
                    }
                    setSigninComplete(true);
                    await refetchMyProfile();
                }
            }
            if (response.error) {
                // handle on error
                toast.update(toastId, {
                    render: parseError(response.error) || "Error signing in",
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            }
        } catch (e) {}
    };

    return (
        <>
            <AuthNavBar />
            <div className="auth-layout">
                <div className="container">
                    <div className="row justify-content-around justify-content-xxl-center align-items-center">
                        <div className="col-lg-6 col-xl-5 left-grid d-none d-lg-block">
                            <img
                                src="/assets/img/login-img.svg"
                                style={{ filter: "grayscale(0.4)" }}
                                className="main-img mr-9"
                                alt=""
                            />
                            <div className="content" style={{ top: "-135px" }}>
                                <h3 className="mb-0">{t("THE_KEY")} </h3>
                                <h3 className="mb-0">{t("TO_YOUR_NFT")} </h3>
                                <h3>{t("JOURNEY")} </h3>
                            </div>
                            <div
                                onClick={() => history.push("/app/register")}
                                className="sub-content"
                            >
                                <div className="sub-content-query">
                                    {t("DON'T_ACCOUNT")}
                                </div>
                                <div className="toggle-link red-link">
                                    <span>{t("JOIN_NOW")}</span>
                                    <img
                                        src="/assets/img/arrow-left.svg"
                                        className="ml-2"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-5">
                            <h4 className="auth-title mb-1">{t("SIGN_IN")}</h4>
                            <div className="sub-title pb-2">
                                {t("CREATE_EXPLORE_TRADE")}
                            </div>
                            <div className="form-wrapper">
                                <Form
                                    enableReinitialize
                                    initialValues={formData}
                                    validationSchema={LoginFormSchema}
                                    onSubmit={onSubmit}
                                >
                                    <div className="input-row mt-4">
                                        <TextField
                                            label={t("USERNAME")}
                                            name="username"
                                        />
                                    </div>
                                    <div className="input-row mt-4">
                                        <TextField
                                            type="password"
                                            label={t("PASSWORD")}
                                            name="password"
                                        />
                                    </div>

                                    <DisplayField fieldName="status">
                                        {(status) => {
                                            if (!isSignInError) return <></>;
                                            if (isSignInError) {
                                                return (
                                                    <div
                                                        className={`text-danger font-weight-bold mt-2`}
                                                    >
                                                        <p
                                                            style={{
                                                                fontSize: 14,
                                                            }}
                                                            className="mb-0"
                                                        >
                                                            {
                                                                signInError
                                                                    ?.data
                                                                    ?.errors[0]
                                                                    ?.non_field_errors[0]
                                                            }
                                                        </p>
                                                    </div>
                                                );
                                            }
                                        }}
                                    </DisplayField>

                                    <div className="d-flex align-items-center mt-4">
                                        <div className="froget-password">
                                            {t("FORGET_YOUR_PASSWORD ?")} <br />
                                            {t("YOU_CAN_RESET_IT_HERE")}
                                        </div>

                                        <div
                                            className="ml-4 d-flex align-items-center"
                                            onClick={() =>
                                                history.push("/app/forgot")
                                            }
                                        >
                                            <img
                                                src="/assets/img/green-arrow.svg"
                                                className=""
                                                alt=""
                                            />
                                            <span className="green-link toggle-link">
                                                {t("CLICK_HERE")}
                                            </span>
                                        </div>
                                    </div>

                                    <SubmitButton
                                        className="mt-4"
                                        title={t("SIGN_IN")}
                                    />
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export function LogoutPage() {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        sessionStorage.removeItem(TEMPORARY_TOKEN_NAME);
        sessionStorage.removeItem(ACCESS_TOKEN_NAME);
        sessionStorage.removeItem(REFRESH_TOKEN_NAME);
        localStorage.removeItem(REFRESH_TOKEN_NAME);
        history.push("/app/signin");
    }, [dispatch, history]);

    return <></>;
}

const ForgetFormSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email")
        .required("Required")
        .min(1, "Required"),
});

export function ForgotPasswordPage() {
    const dispatch = useDispatch();
    const [formData] = useState({
        email: "",
    });
    const { t, i18n } = useTranslation(["auth", "common"]);
    const [
        reqResetPassword,
        { isError: isReqResetPasswordError, error: reqResetPasswordError },
    ] = useRequestPasswordResetMutation();

    const onSubmit = async (values) => {
        try {
            const toastId = toast.loading("Please wait...");
            const response = await reqResetPassword(values);
            if (response.data) {
                // handle on success
                toast.update(toastId, {
                    render: "Reset link sent",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            }
            if (response.error) {
                // handle on error
                toast.update(toastId, {
                    render: parseError(response.error) || "Error requesting password reset",
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            }
        } catch (error) {}
    };

    return (
        <div className="auth-layout">
            <div className="container">
                <div className="text-center">
                    <img src="/assets/img/token-img.svg" className="" alt="" />
                </div>

                <div className="sub-title text-center mt-4">
                    {t("FORGET_YOUR_PASSWORD ?")}
                </div>
                <div className="content text-center mt-3">
                    <h6 className="mb-0 black-link">{t("ENTER_EMAIL")}</h6>
                </div>

                <div className="row justify-content-center mt-3">
                    <div className="col-lg-6 col-xl-5">
                        <Form
                            enableReinitialize
                            initialValues={formData}
                            onSubmit={onSubmit}
                            validationSchema={ForgetFormSchema}
                        >
                            <div className="input-row mt-2">
                                <TextField label={t("EMAIL")} name="email" />
                            </div>
                            <DisplayField fieldName="status">
                                {(status) => {
                                    if (!isReqResetPasswordError) return <></>;

                                    if (isReqResetPasswordError) {
                                        return (
                                            <div
                                                className={`text-danger font-weight-bold mt-2`}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: 14,
                                                    }}
                                                    className="mb-0"
                                                >
                                                    {
                                                        reqResetPasswordError
                                                            ?.data?.errors[0]
                                                    }
                                                </p>
                                            </div>
                                        );
                                    }
                                }}
                            </DisplayField>
                            <div className="text-center mt-5">
                                <SubmitButton
                                    className="btn btn-primary"
                                    title={t("SEND_LINK")}
                                />
                            </div>
                        </Form>
                    </div>
                </div>

                <div className="text-center mt-3">
                    <Link to="/app/signin">{t("BACK_TO_LOGIN")}</Link>
                </div>
            </div>
        </div>
    );
}

const RegisterFormSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email")
        .required("Required")
        .min(1, "Required"),
    password: Yup.string().required("Required").min(6, "Too short"),
    confirm_password: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
    ),
});

export function RegisterPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation(["auth"]);

    const [formData] = useState({
        //username: "",
        email: "",
        password: "",
        confirm_password: "",
        terms: false,
    });

    const [
        register,
        { isError: isRegisterError, data: responseData },
    ] = useRegisterMutation();

    const onSubmit = async (values) => {
        const data = { ...values };
        try {
            const toastId = toast.loading("Please wait...");
            const response = await register(data);

            if (response.data) {
                // handle on success
                toast.update(toastId, {
                    render: "Account created successfully",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
                const { data: successResponseData } = response.data;
                if (successResponseData?.email) {
                    const { email } = successResponseData;
                    history.push(`/app/register/success?email=${email}`);
                }
            }
            if (response.error) {
                // handle on error
                toast.update(toastId, {
                    render: parseError(response.error) || "Error registering account",
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            }
        } catch (error) {}
    };
    return (
        <>
            <AuthNavBar />
            <div className="auth-layout">
                <div className="container">
                    <div className="row justify-content-around justify-content-xxl-center align-items-center">
                        <div className="col-lg-6 col-xl-5 left-grid d-none d-lg-block">
                            <img
                                src="/assets/img/register-img.svg"
                                className="main-img"
                                alt=""
                            />
                            <div className="content">
                                <h3 className="mb-0">{t("WELCOME!")} </h3>
                                <h3 className="mb-0">{t("TAKE_CONTROL")}</h3>
                                <h3 className="mb-0">{t("NFT_JOURNEY")}</h3>
                                <h3>{t("WITH_HYPERGLADE")} </h3>
                            </div>
                            <div className="sub-content">
                                <div className="sub-content-query">
                                    {t("ALREADY")}
                                </div>
                                <div
                                    onClick={() => history.push("/app/signin")}
                                    className="toggle-link blue-link"
                                >
                                    <span>{t("SIGN_IN")}</span>
                                    <img
                                        src="/assets/img/arrow-right.svg"
                                        className="ml-2"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-5">
                            <h4 className="auth-title mb-1">
                                {t("JOIN_OUR_PLATFORM")}
                            </h4>
                            <div className="sub-title pb-2">
                                {t("BE_EMPOWERED")}
                            </div>
                            <div className="form-wrapper">
                                <Form
                                    enableReinitialize
                                    initialValues={formData}
                                    validationSchema={RegisterFormSchema}
                                    onSubmit={onSubmit}
                                >
                                    <div className="input-row mt-4">
                                        <TextField
                                            label={t("EMAIL")}
                                            name="email"
                                        />
                                    </div>
                                    <div className="input-row mt-4">
                                        <TextField
                                            type="password"
                                            label={t("PASSWORD")}
                                            name="password"
                                        />
                                    </div>
                                    <div className="input-row mt-4">
                                        <TextField
                                            type="password"
                                            label={t("CONFIRM_PASSWORD")}
                                            name="confirm_password"
                                        />
                                    </div>
                                    <div className="input-row mt-4">
                                        <Checkbox
                                            label={t("SEND_MY_EMAIL")}
                                            name="terms"
                                        />
                                    </div>

                                    <DisplayField fieldName="status">
                                        {(status) => {
                                            if (!isRegisterError) return <></>;
                                            if (isRegisterError) {
                                                return (
                                                    <div
                                                        className={`text-danger font-weight-bold mt-2`}
                                                    >
                                                        <p
                                                            style={{
                                                                fontSize: 14,
                                                            }}
                                                            className="mb-0"
                                                        >
                                                            {parseError(
                                                                responseData
                                                            )}
                                                        </p>
                                                    </div>
                                                );
                                            }
                                        }}
                                    </DisplayField>

                                    <SubmitButton
                                        className="mt-4"
                                        title={t("REGISTER")}
                                    />
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export function ForgotLinkSent() {
    const { t, i18n } = useTranslation(["auth"]);

    return (
        <div className="form">
            <div className="heading">{t("CHECK_YOUR_EMAIL")}</div>
            <div className="sub-text">{t("FOLLOW_THE_INSTRUCTIONS")}</div>
            <Link className="mt-4 w-100" to="/login">
                {t("BACK_TO_LOGIN")}
            </Link>
        </div>
    );
}
export function ResetPasswordPage() {
    const [formData] = useState({
        password: "",
        confirmPassword: "",
    });
    const history = useHistory();
    const onSubmit = () => {
        // setSubmitting(true);
        history.push("/forgot-link-sent");
    };
    const { t, i18n } = useTranslation(["auth"]);

    return (
        <div className="form">
            <div className="heading">{t("RESET_YOUR_PASSWORD")}</div>
            <div>{t("Your_new_password")}</div>
            <div className="wrapper">
                <Form
                    enableReinitialize
                    initialValues={formData}
                    validationSchema={LoginFormSchema}
                    onSubmit={onSubmit}
                >
                    <div className="input-row">
                        <TextField
                            label={t("PASSWORD")}
                            name="password"
                            type="password"
                        />
                    </div>
                    <div className="input-row">
                        <TextField
                            label={t("CONFIRM_PASSWORD")}
                            name="confirmPassword"
                            type="password"
                        />
                    </div>
                    <DisplayField fieldName="status">
                        {(status) => {
                            if (!status) return <></>;
                            return (
                                <div
                                    className={`alert alert-${
                                        status.type === "error"
                                            ? "danger"
                                            : "success"
                                    }`}
                                    role="alert"
                                >
                                    <p>{status.message}</p>
                                </div>
                            );
                        }}
                    </DisplayField>
                    <SubmitButton
                        className="btn btn-primary mt-4"
                        title={t("SAVE_PASSWORD")}
                    />
                </Form>
            </div>
        </div>
    );
}
