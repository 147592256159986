import React, { useEffect, useState } from "react";
import { useRouteMatch, useHistory, useLocation, Route, Switch } from "react-router-dom";
import CategoryFilter from "../../components/FilterSearch/CategoryFilter";
import FilterSearch from "../../components/FilterSearch/FilterSearch";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar";

import Profile from "./Profile";
import Earnings from "./Earnings";
import Credits from "./Credits";
import CryptoWallets from "./CryptoWallets";

import "./AllSettings.styles.scss";

function  AllSettingsPage() {
  
    const history = useHistory();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState("");
    const [catFilter, setCatFilter] = useState("");
    const { pathname } = useLocation();
    const { url } = useRouteMatch();

    const categories = [
        {
            label: "Profile",
            value: `${url}/profile`,
        },
        {
            label: "Earnings",
            value: `${url}/earnings`,
        },
        {
            label: "Credits",
            value: `${url}/credits`,
        },
        {
            label: "Crypto Wallets",
            value: `${url}/crypto-wallets`,
        }
    ];

    useEffect(() => {
        const categoryValue = categories.find(({value}) => value.includes(pathname));
        if (categoryValue && pathname === categoryValue.value) {
            const { value } = categoryValue;
            setCatFilter(value);
        } else {
            // if no matching tab found, redirect to first tab route
            history.push(categories[0].value);
        }
    }, [pathname]);

    const handleCategoryFilterChange = (value) => {
        if (value !== pathname) {
            history.push(value);
        }
    };

    return (
        <>
            <NavBar />
            <div className="nft-page-content container">
                <div className="row justify-content-center mt-5 mx-0">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                        <FilterSearch
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            setSearchResults={setSearchResults}
                            placeholder="Search items, collection, Artist or user"
                        />
                    </div>
                </div>
                <div className="caption-sm mt-3">EXPLORE COLLECTIONS</div>
                <div className="heading-lg mt-5">
                   All Settings
                </div>

                <div className="mt-4 pt-xl-3 row justify-content-center mx-0">
                    <div className="col-12 col-xl-10 col-xx-8">
                        <CategoryFilter
                            categories={categories}
                            catFilter={catFilter}
                            setCatFilter={handleCategoryFilterChange}
                        />
                    </div>
                </div>
                <Switch>
                    <Route path={`${url}/profile`} component={Profile} exact/>
                    <Route path={`${url}/earnings`} component={Earnings} exact/>
                    <Route path={`${url}/credits`} component={Credits} exact/>
                    <Route path={`${url}/crypto-wallets`} component={CryptoWallets} exact/>
                </Switch>
            </div>
            <Footer />
        </>
    );
}

export default AllSettingsPage;
