import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import "./AuthVerify.styles.scss";
import {
    Form,
    SubmitButton,
    PhoneNumberField,
    DisplayField,
} from "../FormElements/FormElements";
import { useReSendVerifyMobileTokenMutation } from "../../services/tempAppService";
import { useWaitingToast } from "../../hooks/toastHooks";
import { TOAST_CONFIG } from "../../config";

export default function VerifyPhone() {
    const history = useHistory();

    const [formData] = useState({
        phone: "",
    });

    const [resendVerifyMobile] = useReSendVerifyMobileTokenMutation();

    const onResendToken = async (values, { setStatus }) => {
        const { phone } = values;
        let formattedPhone = "+";
        if (phone[0] !== "+") {
            formattedPhone += phone;
        } else {
            formattedPhone = phone;
        }

        try {
            const toastId = toast.loading("Sending OTP...");
            const res = await resendVerifyMobile({ phone: formattedPhone });
            if (res.data) {
                // handle on success
                toast.update(toastId, {
                    render: "OTP sent",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
                history.push("/app/verify/mobile");
            }
            if (res.error) {
                // handle on error
                toast.update(toastId, {
                    render: "Error sending OTP",
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            }
            setStatus(res);
        } catch (error) {}
    };

    return (
        <>
            <div className="auth-layout">
                <div className="container">
                    <div className="text-center">
                        <img
                            src="/assets/img/token-img.svg"
                            className=""
                            alt=""
                        />
                    </div>

                    <div className="sub-title text-center mt-4">
                        FOR ADDED PROTECTION TO YOUR ACCOUNT{" "}
                    </div>
                    <div className="content text-center mt-2">
                        <h4 className="mb-0 black-link">
                            VERIFY LOGIN WITH YOUR DEVICE
                        </h4>
                    </div>
                    <div className="sub-desc text-center mt-5">
                        Enter your mobile phone number here. We'll send a one
                        time code to your phone.
                    </div>

                    <div className="row justify-content-center mt-3">
                        <div className="col-lg-6 col-xl-5">
                            <Form
                                enableReinitialize
                                initialValues={formData}
                                onSubmit={onResendToken}
                            >
                                <div className="input-row">
                                    <PhoneNumberField
                                        name="phone"
                                        placeholder="Phone Number"
                                    />
                                </div>
                                <div className="text-center mt-5">
                                    <SubmitButton
                                        className=""
                                        title="SEND THE CODE"
                                    />
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
