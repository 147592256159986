import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router";
import FilterSearch from "../../components/FilterSearch/FilterSearch";
import Footer from "../../components/Footer/Footer";
import NFTGrid from "../../components/Grids/NFTGrid";
import NavBar from "../../components/NavBar";
import FilterBar from "../../components/FilterSection/FilterBar";
import { useGetListedNFTSQuery, useGetNFTTagsQuery } from "../../services/nftService";
import "./NFTListingPage.styles.scss";
import { normaliseObjectArray } from "../../utils/dataUtils";
import constants from "../../utils/constants";
import { useQuery } from "../../hooks/commonHooks";

const filterInit = { 
    price: { selected: { min: null, max: null } },
    chain: { values: [
        { label: "All", value: null, icon: "/assets/icon/hg-all-icon.svg" },
        { label: "Ethereum", value: "ETH", icon: "/assets/icon/hg-ethereum-icon.svg" },
        { label: "Polygon", value: "POLYGON", icon: "/assets/icon/hg-polygon-icon.svg" },
        { label: "Solana", value: "SOL", icon: "/assets/icon/hg-solana-icon.svg" },
    ], selected: null },
    category: { values: [
        { label: "All", value: null },
    ], selected: null },
    status: { values: [
        { label: "All", value: null, icon: "/assets/icon/hg-all-icon.svg" },
        { label: "Minted", value: "MINTED", icon: "/assets/icon/hg-minted-icon.svg" },
        { label: "Listed", value: "LISTED", icon: "/assets/icon/hg-listed-icon.svg" },
    ], selected: null }
};

function NFTListingPage() {
    const MIN_WINDOW_WIDTH = 1120;

    const history = useHistory();
    let query = useQuery();
    const [isNavSearchShown, setIsNavSearchShown] = useState(false);
    const [isNavFilterShown, setIsNavFilterShown] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState("");
    const [filter, setFilter] = useState(filterInit);
    const [showListedNFTsOnly, setShowListedNFTsOnly] = useState(false);
    const {
        data: nftsData,
        isLoading: isNFTsLoading,
        isError: isNFTError,
    } = useGetListedNFTSQuery({
        tag: filter.category.selected || null,
        isApproved: true,
        listedOnly: showListedNFTsOnly,
    });
    const { data: NFTTagsData } = useGetNFTTagsQuery();
    const creatorId = query.get("creator");

    const isNFTWithinPriceRange = (nftData, min=0, max) => {
        const activeListing = nftData.listings?.find?.(({ status: listingStatus }) => constants.NFT_LISTING_STATUS_ACTIVE === listingStatus);
        if (activeListing) {
            return _.inRange(activeListing.price, min, max)
        }
        return false;
    };

    const isNFTBelongToChain = (nftData, selectedChain) => nftData?.chain === selectedChain;
    
    const matchesNFTStatus = (nftData, selectedStatus) => {
        switch (selectedStatus) {
            case "MINTED":
                return !nftData.listings?.length;
            case "LISTED":
                return !!nftData.listings?.some?.(({ status: listingStatus }) => constants.NFT_LISTING_STATUS_ACTIVE === listingStatus);
            default:
                return !selectedStatus;
        }
    };


    const filteredNFTListings = useMemo(() => {
        // fallback to all NFTs
        let filteredNormalizedNFTData = nftsData;
        if (nftsData?.allIds.length) {
            const nftList = nftsData.allIds.map(
                (nftId) => nftsData.byId[nftId]
            );
            
            let filteredNFTList = nftList;
            
            // filteration of search query matches
            if (searchQuery) {
                filteredNFTList = nftList.filter(
                    (nftData) =>
                        // Check for name
                        nftData?.name
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                        // Check for description
                        nftData?.description
                            ?.toLowerCase()
                            .includes(searchQuery.toLowerCase())
                );
            }

            // filteration of filter menu selections
            const { price, chain, status } = filter;

            if (!(price.selected.min == null && price.selected.max == null)) {
                filteredNFTList = filteredNFTList.filter((nftData) => isNFTWithinPriceRange(nftData, price.selected.min, price.selected.max))
            }
            if (chain.selected != null) {
                filteredNFTList = filteredNFTList.filter((nftData) => isNFTBelongToChain(nftData, chain.selected));
            }
            if (status.selected != null) {
                filteredNFTList = filteredNFTList.filter((nftData) => matchesNFTStatus(nftData, status.selected));
            }
            // TODO: quick fix. added this as an alternative to the artist profile.
            if (creatorId){
                filteredNFTList = filteredNFTList.filter((nftData) => nftData.owned_by===creatorId)
            }
            filteredNormalizedNFTData = normaliseObjectArray(filteredNFTList);
        }
        return filteredNormalizedNFTData;
    }, [nftsData, searchQuery, filter, creatorId]);

    const handleOnScroll = useCallback((e) => {
        if (window.innerWidth > MIN_WINDOW_WIDTH && window.scrollY > 120) {
            setIsNavSearchShown(true);
        }
        if (window.scrollY <= 120) {
            setIsNavSearchShown(false);
        }
        if (window.innerWidth > MIN_WINDOW_WIDTH && window.scrollY > 155) {
            setIsNavFilterShown(true);
        }
        if (window.scrollY <= 155) {
            setIsNavFilterShown(false);
        }
    }, []);

    useEffect(() => {
        // check scrolling for hiding searchbar
        window.addEventListener("scroll", handleOnScroll);
        return () => {
            window.removeEventListener("scroll", handleOnScroll);
        };
    }, []);

    useEffect(() => {
        // filter state update with new categories
        if (NFTTagsData) {
            const categories = NFTTagsData?.allIds
            ? NFTTagsData?.allIds?.map((categoryId) => ({
                  value: categoryId,
                  label: NFTTagsData?.byId[categoryId]?.name,
              }))
            : [];
            categories.unshift({ label: "All", value: null });
            setFilter((prevFilters) => {
                const newFilters = { ...prevFilters };
                newFilters.category.values = categories;
                newFilters.category.selected = null;
                return newFilters;
            });
        }
    }, [NFTTagsData]);

    const handleFilterChange = (filterName, value) => {
        const newFilters = { ...filter };
        if (newFilters[filterName]) {
            newFilters[filterName].selected = value;
            setFilter(newFilters);
        };
    };

    return (
        <>
            <div className="sticky-top">
                <NavBar
                    isSearchBarShown={isNavSearchShown}
                    isFilterBarShown={isNavFilterShown}
                    searchBarProps={{
                        searchQuery,
                        setSearchQuery,
                        setSearchResults,
                        placeholder: "Search items, collection, Artist or user",
                    }}
                    filterBarProps={{
                        filter,
                        onFilterChange: handleFilterChange,
                        showListedNFTsOnly,
                        setShowListedNFTsOnly,
                    }}
                />
            </div>
            <div className="bg-white">
                <div className="row justify-content-center mt-5 mx-0">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                        <div className="d-flex justify-content-center w-100">
                            <div
                                className={`filter-search-wrapper ${
                                    isNavSearchShown ? "" : "show"
                                }`}
                            >
                                <FilterSearch
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                    setSearchResults={setSearchResults}
                                    placeholder="Search items, collection, Artist or user"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="caption-sm mt-3">EXPLORE COLLECTIONS</div>

                <div className="stick-after-navbar">
                    <div className="mt-3 pt-xl-3 row justify-content-center mx-0">
                        <div className="col-12 p-0">
                            <div className="d-flex justify-content-center w-100">
                                <div
                                    className={`filter-bar-wrapper ${
                                        isNavFilterShown ? "" : "show"
                                    }`}
                                >
                                    <FilterBar
                                        filter={filter}
                                        onFilterChange={handleFilterChange}
                                        showListedNFTsOnly={showListedNFTsOnly}
                                        setShowListedNFTsOnly={setShowListedNFTsOnly}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="nft-page-content">
                <div className="w-100 bg-light">
                    <div className="row justify-content-center container m-auto">
                        <div className="col-xl-10 col-xxl-11">
                            <NFTGrid
                                cardBtnTitle="VIEW"
                                nftData={filteredNFTListings}
                                isLoading={isNFTsLoading}
                                onCardClick={(nftId) => {
                                    history.push(`/app/nft/view/${nftId}`);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default NFTListingPage;
