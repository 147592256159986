import React from "react";
import NavBar from "../../components/NavBar";
import { useGetNFTSQuery } from "../../services/nftService";

function MyCollectionPage() {
    const { data: nftData } = useGetNFTSQuery();

    return (
        <>
            <NavBar showLinks />
            <div className="container mt-3">
                <h2 className="text-center font-weight-bold">My collections</h2>
                <div className="row">
                    {nftData?.allIds?.map((id) => (
                        <div
                            className="col-md-6 col-lg-4 col-xxl-3 mt-5"
                            key={id}
                        >
                            <div className="card" style={{ width: "18rem" }}>
                                <img
                                    className="card-img-top"
                                    src="..."
                                    alt="Card image cap"
                                />
                                <div className="card-body"></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default MyCollectionPage;
