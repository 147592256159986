import axios from "axios";
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from "./config";

// use apiHandler to access protected endpoints
const apiHandler = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
});

apiHandler.interceptors.request.use((config) => {
    const newConf = config;
    const token = sessionStorage.getItem(ACCESS_TOKEN_NAME);
    newConf.headers.Authorization = `Bearer ${token}`;
    return newConf;
});

const fileUploadHandler = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
});

fileUploadHandler.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem(ACCESS_TOKEN_NAME);
    config.headers.Authorization = "Bearer " + token;
    config.headers["content-type"] = "multipart/form-data";
    return config;
});

apiHandler.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status !== 401) {
            window.location = "/signout";
        } else {
            return Promise.reject(error);
        }
        return 0;
    }
);

const publicApiHandler = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
});

export async function refreshAccessToken() {
    const response = await publicApiHandler.post("/api/auth/token/refresh/", {
        refresh:
            sessionStorage.getItem(REFRESH_TOKEN_NAME) ||
            localStorage.getItem(REFRESH_TOKEN_NAME),
    });
    if (sessionStorage.getItem(REFRESH_TOKEN_NAME)) {
        sessionStorage.setItem(ACCESS_TOKEN_NAME, response.data.data.access);
    } else {
        localStorage.setItem(ACCESS_TOKEN_NAME, response.data.data.access);
    }
    return response.data;
}

// user endpoints

export async function getUsers() {
    return {
        data: [
            {
                id: 1,
                display_name: "John Doe",
                occupation: "CEO. Team motivator.",
                is_admin: false,
                subscription: 3,
            },
            {
                id: 2,
                display_name: "Justin Case",
                occupation: "CTO",
                is_admin: true,
                subscription: 2,
            },
            {
                id: 3,
                display_name: "Ben Dover",
                occupation: "Unemployed",
                is_admin: false,
                subscription: 1,
            },
            {
                id: 4,
                display_name: "Theodore Handle",
                occupation: "Carpenter",
                is_admin: false,
                subscription: 1,
            },
            {
                id: 5,
                display_name: "Will Barrow",
                occupation: "Organic smoothie vendor",
                is_admin: false,
                subscription: 3,
            },
        ],
    };
}

export async function uploadFiles(data) {
    const response = await fileUploadHandler.post(`/api/files/`, data);
    return response.data;
}
