import React from "react";
import "./Footer.styles.scss";

function Footer() {
    return (
        <div className="footer">
            <div className="ribbon" />
            <div className="footer-content">
                <div className="org-info">
                    <div className="logo-container">
                        <div className="logo-wrapper">
                            <img
                                src="/assets/img/logo-dark.svg"
                                alt=""
                                className="logo"
                            />
                        </div>
                        <div className="logo-text">
                            <div className="title">Hyperglade</div>
                            <div className="subtitle">NFT Marketplace</div>
                        </div>
                    </div>
                    <div className="copyright-info">
                        © {(new Date()).getFullYear()} Hyperglade PTE LTD. All rights reserved.
                    </div>
                    <div className="social-buttons">
                        <a
                            href="https://twitter.com/hyperglade"
                            target="_blank"
                            className="social-button"
                            type="button"
                        >
                            <img
                                src="/assets/icon/twitter.png"
                                width="100%"
                                alt=""
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/hyper.glade"
                            target="_blank"
                            className="social-button"
                            type="button"
                        >
                            <img
                                src="/assets/icon/instagram.png"
                                width="100%"
                                alt=""
                            />
                        </a>
                        <a
                            href="https://discord.gg/rkzaYnZdqb"
                            target="_blank"
                            className="social-button"
                            type="button"
                        >
                            <img
                                src="/assets/icon/discord.png"
                                width="100%"
                                alt=""
                            />
                        </a>
                        <a
                            href="https://www.facebook.com/HyperGlade-103943078730431/"
                            target="_blank"
                            className="social-button"
                            type="button"
                        >
                            <img
                                src="/assets/icon/facebook.png"
                                width="100%"
                                alt=""
                            />
                        </a>
                    </div>
                </div>
                <div className="more-info">
                    <div className="site-info">
                        <div className="title">HYPERGLADE</div>

                        <a
                            className="link"
                            href="https://hyperglade.com/about-hyperglade"
                            target="_blank"
                            rel="noreferrer"
                        >
                            About us
                        </a>
                        <a
                            className="link"
                            href="https://blog.hyperglade.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Creators&apos; Blog
                        </a>
                        {/* <a className="link" href="/how-to-bid">
                            How to bid
                        </a>
                        <a className="link" to="/artists">
                            Artists
                        </a> */}
                    </div>
                    <div className="policy-info">
                        <div className="title">INFORMATION</div>
                        <a
                            className="link"
                            href="https://hyperglade.com/market-toc"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Terms of service
                        </a>
                        <a
                            className="link"
                            href="https://hyperglade.com/market-privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Privacy policy
                        </a>
                        <a
                            className="link"
                            href="https://hyperglade.com/market-faq"
                            target="_blank"
                            rel="noreferrer"
                        >
                            FAQ
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
