import React from "react";
import "./SkeletonRoot.styles.scss";

/*
 * type: string - text | btn | circle | undefined
 * width: number | undefined
 * height: number | undefined
 */
const Skeleton = ({ type = "", width, height }) => (
    <div className={`skeleton-loader ${type}`} style={{ width, height }} />
);

export default Skeleton;
