import React, { useState } from "react";
import constants from "../../utils/constants";

const NFTBidDetails = ({ nft, onClickBuy }) => {
    const [isBuyNowLoading, setIsBuyNowLoading] = useState(false);

    const handleOnClickBuy = async () => {
        setIsBuyNowLoading(true);
        try {
            await onClickBuy();
        } catch (e) {
            // continue
        } finally {
            setIsBuyNowLoading(false);
        }
    };

    const getCurrentNFTPrice = (prefix = "", postfix = "") => {
        let price = "N/A";
        if (nft?.listings?.length) {
            const { listings: nftListingHistory } = nft;
            const listing = nftListingHistory.find(
                ({ status }) => status === constants.NFT_LISTING_STATUS_ACTIVE
            );
            if (listing?.price) {
                const currentPrice = listing?.price;
                price = `${prefix} ${Number(currentPrice).toFixed(
                    2
                )} ${postfix}`;
            }
        }
        return price;
    };

    return (
        <div className="w-100 px-3">
            <div className="row">
                <div className="col-12 px-0">
                    <div className="h3 font-weight-bold">
                        {nft?.name || "NFT Name Unavailable"}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 px-0">
                    <span className="d-block">Current Price</span>
                    <div className="d-flex align-items-end">
                        <div className="h3 font-weight-bold mb-0">
                            {getCurrentNFTPrice("", "USD")}
                        </div>
                        <div className="h6 px-2">
                            {/* ({nft?.priceInUsd ? `$${nft.priceInUsd}`:"N/A"}) */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 d-flex align-items-end px-0">
                    {isBuyNowLoading ? (
                        <button
                            className="btn btn-primary w-100 py-2 mr-2 text-truncate"
                            type="button"
                            disabled
                        >
                            <span
                                className="spinner-border spinner-border-sm mr-1"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-primary w-100 py-2 mr-2 text-truncate"
                            onClick={handleOnClickBuy}
                            disabled={getCurrentNFTPrice("", "USD") === "N/A"}
                        >
                            BUY NOW
                        </button>
                    )}
                </div>
                <div className="col-1 d-flex align-items-end px-0">
                    <button
                        type="button"
                        className="btn btn-outline-secondary mr-2 mt-2"
                    >
                        <img
                            src="/assets/icon/hg-discount-bookmark.svg"
                            alt=""
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NFTBidDetails;
