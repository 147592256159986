export const TEMPORARY_TOKEN_NAME = "tempAdminToken";
export const ACCESS_TOKEN_NAME = "adminToken";
export const REFRESH_TOKEN_NAME = "refreshToken";

export const TOAST_CONFIG = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    newestOnTop: true,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    closeButton: true,
};

export const FILE_UPLOAD_CONFIG = {
    maxSize: "5MB",
    maxNFTSize: "100MB",
    acceptedThumbnailFileTypes: ["image/jpeg", "image/png"],
    acceptedNftFileTypes: [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/svg+xml",
        "video/mp4",
        "video/webm",
        "audio/mp3",
        "audio/wav",
        "audio/ogg",
        "model/gltf-binary",
    ],
};

export const SUPPORTED_BLOCKCHAINS = {
    // ETH: "Ethereum",
    POLYGON: "Polygon",
};

// Denoted in USD
export const MINIMUM_ALLOWED_CREDIT_AMOUNT = 1;

// Supported blockchain tx verification platform config
export const CHAIN_TX_VERIFICATION_PLATFORMS = {
    POLYGON: {
        platform: "Polygonscan",
        baseURL: "https://polygonscan.com/tx/",
    },
    ETH: {
        platform: "Etherscan",
        baseURL: "https://etherscan.io/tx/",
    },
}

export const DEFAULT_IMAGE_COMPRESSION_OPTIONS = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
};