import React from "react";
import "./SkeletonRoot.styles.scss";
import "../Cards/ArtCard.styles.scss";

const ArtCardSkeleton = () => (
    <div className="ArtCardItem">
        <div className="card-img-wrapper">
            <div className="card-img">
                <div className="skeleton-loader" />
            </div>
        </div>
        <div className="ArtCardContent">
            <div className="ArtCardName skeleton-loader text" />
            <div className="ArtCard-Footer mt-2">
                <div className="footer-section w-100">
                    <div className="ArtCardBid skeleton-loader text" />
                    <div className="ArtCardMoneyRate skeleton-loader text" />
                </div>
                <div className="footer-section px-2">
                    <div className="skeleton-loader btn" />
                </div>
            </div>
        </div>
    </div>
);

export default ArtCardSkeleton;
