import React, { useState } from "react";
import { useGetFileQuery } from "../../services/fileService";
import "./NFTThumbnail.styles.scss";
import Skeleton from "../Skeletons/Skeleton";

const NFTThumbnail = ({ nft = { thumbnail: "" } }) => {
    const { data: thumbnailFileData } = useGetFileQuery(
        { fileId: nft?.thumbnail },
        { skip: !nft?.thumbnail }
    );

    const [loading, setLoading] = useState(false);

    const ImageLoaded = () => {
        setLoading(true);
    };

    return (
        <div className="nft-thumbnail-wrapper">
            <img
                src={thumbnailFileData?.file || " "}
                alt=""
                className="nft-thumbnail"
                onLoad={ImageLoaded}
            />
            {!loading && <div className="nft-thumbnail-skeleton-container">
                <Skeleton height="100%" weight="100%" />
            </div>}
        </div>
    );
};

export default NFTThumbnail;
