import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ModalContainer from "../components/Modals/ModalContainer";
import AppRoutes from "./AppRoutes";
import { Spinner } from "../components/Spinner";

const Routes = () => {
    return (
        <Router>
            <div className="App">
                <Switch>
                    <AppRoutes />
                </Switch>
                <Spinner />
                <ModalContainer />
            </div>
        </Router>
    );
};

export default Routes;
