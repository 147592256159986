import React, { useState } from "react";
import ReactOTP from "react-otp-input";

function OTPInput({ onVerify }) {
    const [otp, setOtp] = useState('');

    const handleOtpChange = (changedOtp) => {
        setOtp(changedOtp);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        onVerify(otp)
    };
    return (
        <form onSubmit={handleSubmit}>
            <div className="otp-container">
                <ReactOTP
                    value={otp}
                    shouldAutoFocus
                    onChange={handleOtpChange}
                    numInputs={6}
                    containerStyle="otp-container"
                    inputStyle="otp-input"
                />
            </div>
            <div className="text-center mt-4">
                <button type="submit" className=" btn btn-primary submit-btn">
                    VERIFY
                    <div className="button-glow" />
                </button>
            </div>
        </form>
    );
}

export default OTPInput;
