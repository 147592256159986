import React from "react";
import AuthNavBar from "../NavBar/AuthNavBar";
import Button from "../Button";
import "./AuthVerify.styles.scss";
import queryString from "query-string";
import { useLocation } from "react-router";
import { useResendEmailVerifyMutation } from "../../services/appService";

export default function UnsuccessVerify() {
    const location = useLocation();
    const { email } = queryString.parse(location.search);

    const [resendEmail] = useResendEmailVerifyMutation();

    const onRetry = async () => {
        try {
            await resendEmail({ email });
        } catch (error) {}
    };

    return (
        <>
            <AuthNavBar />
            <div className="auth-layout">
                <div className="container">
                    <img
                        src="/assets/img/auth-fail.svg"
                        className="verify-img"
                        alt=""
                    />
                    <div className="sub-title text-center pt-3">
                        P O O P H Z !
                    </div>
                    <div className="content text-center pt-3">
                        <h3 className="mb-0 red-link">Something Wrong!</h3>
                    </div>
                    <div className="sub-title text-center black-link pt-5">
                        Please recheck the your mail you have entered for
                        verification and confirm the last mail we have sent to
                        you <br />
                        or resent a verification email by clicking below Retry
                        button.
                    </div>
                    <div className="col text-center pt-5">
                        <Button
                            className="btn btn-primary"
                            title="RE-CHECK EMAIL"
                        />
                    </div>
                    <div className="row justify-content-md-center pt-4">
                        <div className="col-md-auto d-flex align-items-center mt-4">
                            <div
                                className="ml-4 d-flex align-items-center"
                                onClick={onRetry}
                            >
                                <img
                                    src="/assets/img/liteblue-arrow.svg"
                                    className=""
                                    alt=""
                                />
                                <span className="liteblue-link toggle-link">
                                    Retry
                                </span>
                            </div>
                            <div className="froget-mail pl-5">
                                Not Recived the email? <br />
                                Don&apos;t worry. We can send it again.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
