import React from "react";
import { Route, Redirect } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./CustomRoutes";
import SignInPage, {
    RegisterPage,
    LogoutPage,
    ForgotPasswordPage,
} from "../pages/AuthPage/AuthPage";
import SuccessVerify from "../components/AuthVerify/SuccessVerify";
import VerifyPhone from "../components/AuthVerify/VerifyPhone";
import Layout from "../pages/Layout";
import NFTListingPage from "../pages/NFTListingPage";
import EmailVerificationPage from "../pages/LinkLandingPages/EmailVerificationPage";
import CreateNFTPage from "../pages/NFTPages/CreateNFTPage";
import MyCollectionPage from "../pages/NFTPages/MyCollectionPage";
import AccountPage from "../pages/ProfilePage/AccountPage";
import SellNFTPage from "../pages/NFTPages/SellNFTPage";
import EditProfilePage from "../pages/ProfilePage/EditProfilePage";
import ResetPasswordPage from "../pages/LinkLandingPages/ResetPasswordPage";
import VerifyMobilePage from "../pages/AuthPage/VerifyMobilePage";
import VerifyOtpPage from "../pages/AuthPage/VerifyOtpPage";
import ViewNFTPage from "../pages/NFTPages/ViewNFTPage";
import AllSettingsPage from "../pages/SettingsPage/AllSettingsPage";

const AppRoutes = () => (
    <>
        <Route exact path="/">
            <Redirect to="/app/home" />
        </Route>
        <Route
            path="/app"
            render={({ match: { url } }) => (
                <>
                    {/* <PublicRoute restricted component={LandingPageFull} path={`${url}/home`} exact /> */}
                    <PublicRoute
                        restricted
                        component={NFTListingPage}
                        path={`${url}/home`}
                        exact
                    />
                    <PublicRoute
                        restricted
                        component={SignInPage}
                        path={`${url}/signin`}
                        exact
                    />
                    <PublicRoute
                        restricted
                        component={RegisterPage}
                        path={`${url}/register`}
                        exact
                    />
                    <PublicRoute
                        restricted
                        component={SuccessVerify}
                        path={`${url}/register/success`}
                        exact
                    />
                    <PublicRoute
                        restricted
                        component={EmailVerificationPage}
                        path={`${url}/verify/email`}
                        exact
                    />
                    <PublicRoute
                        restricted
                        component={ForgotPasswordPage}
                        path={`${url}/forgot`}
                        exact
                    />
                    <PublicRoute
                        restricted
                        component={ResetPasswordPage}
                        path={`${url}/reset-password`}
                        exact
                    />
                    <PublicRoute
                        component={VerifyMobilePage}
                        path={`${url}/verify/mobile`}
                        exact
                    />
                    <PublicRoute
                        component={VerifyPhone}
                        path={`${url}/add/mobile`}
                        exact
                    />
                    <PublicRoute
                        component={VerifyOtpPage}
                        path={`${url}/verify/otp`}
                        exact
                    />
                    <PrivateRoute
                        component={CreateNFTPage}
                        path={`${url}/nft/create`}
                        exact
                    />
                    <PrivateRoute
                        component={MyCollectionPage}
                        path={`${url}/collection`}
                        exact
                    />
                    <PrivateRoute
                        component={SellNFTPage}
                        path={`${url}/nft/sell/:nftId`}
                        exact
                    />
                    <PublicRoute
                        component={ViewNFTPage}
                        path={`${url}/nft/view/:nftId`}
                        exact
                        restoreOnMount
                    />
                    <PublicRoute
                        component={AccountPage}
                        path={`${url}/user/:userId`}
                        exact
                    />
                    <PrivateRoute
                        component={Layout}
                        path={`${url}/dashboard`}
                    />
                     <PrivateRoute
                        component={AllSettingsPage}
                        path={`${url}/settings`}
                        restoreOnMount
                    />
                    <PublicRoute
                        restricted
                        component={LogoutPage}
                        path={`${url}/signout`}
                        exact
                    />
                </>
            )}
        />
    </>
);

export default AppRoutes;
