import React from "react";
import { useHistory, useLocation } from "react-router";

import "./FilterSearch.styles.scss";

function FilterSearch(props) {
    const { searchQuery, setSearchQuery, setSearchResults, placeholder } = props;

    const location = useLocation();
    const history = useHistory();

    const addQuery = (key, value) => {
        let pathname = location.pathname;
        let searchParams = new URLSearchParams(location.search);
        searchParams.set(key, value);
        searchParams.set("page", 1);
        history.push({
            pathname: pathname,
            search: searchParams.toString(),
        });
    };

    const onChangeSearchQuery = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchResults(searchQuery);
        addQuery("search", searchQuery);
    };

    return (
        <form onSubmit={handleSearch}>
            <div className="d-flex filter-search">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => onChangeSearchQuery(e)}
                    className="filter-search-input"
                    placeholder={placeholder}
                    name=""
                    id=""
                />
                <button type="submit" className="search-btn">
                    <img src="/assets/img/search.svg" alt="" />
                </button>
            </div>
        </form>
    );
}

export default FilterSearch;
