import React from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import ValidReceivedVerifyCode from "../../components/AuthVerify/ValidReceivedVerifyCode";
import {
    ACCESS_TOKEN_NAME,
    REFRESH_TOKEN_NAME,
    TOAST_CONFIG,
} from "../../config";
import {
    useVerifyOTPMutation,
    useGetMyProfileWithTempTokenQuery,
} from "../../services/tempAppService";
import parseError from "../../utils/errorUtils";

function VerifyOtpPage() {
    const history = useHistory();

    const [verifyOTP] = useVerifyOTPMutation();

    const { refetch: refetchMyProfile } = useGetMyProfileWithTempTokenQuery({});

    const onVerify = async (otp) => {
        try {
            const toastId = toast.loading("Verifying OTP...");
            let response = await verifyOTP({ otp });
            if (response.data) {
                // handle on success
                toast.update(toastId, {
                    render: "OTP Verified",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
                response = response.data;
                if (response && response.access) {
                    sessionStorage.setItem(ACCESS_TOKEN_NAME, response.access);
                    sessionStorage.setItem(
                        REFRESH_TOKEN_NAME,
                        response.refresh
                    );

                    await refetchMyProfile();
                }
                history.push("/app/home");
            }
            if (response.error) {
                // handle on error
                toast.update(toastId, {
                    render: parseError(response, "Invalid OTP"),
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            }
        } catch (error) {}
    };

    const handleRetry = () => {
        history.push("/app/signout");
    };

    return (
        <ValidReceivedVerifyCode
            handleRetry={handleRetry}
            onVerify={onVerify}
        />
    );
}

export default VerifyOtpPage;
