import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import FilterMenu from "./FilterMenu";
import "./FilterBar.styles.scss";
import FilterMenuItem from "./FilterMenuItem";

function FilterBar({
    filter,
    onFilterChange,
    showListedNFTsOnly = false,
    setShowListedNFTsOnly,
}) {
    const [openPrice, setOpenPrice] = React.useState(false);
    const [openBlockchain, setOpenBlockchain] = React.useState(false);
    const [openCategory, setOpenCategory] = React.useState(false);
    const [openStatus, setOpenStatus] = React.useState(false);

    const [minPrice, setMinPrice] = React.useState(null);
    const [maxPrice, setMaxPrice] = React.useState(null);

    const handleOnClickPrice = () => {
        setOpenPrice(!openPrice);
    };

    const handleOnClickBlockchain = () => {
        setOpenBlockchain(!openBlockchain);
    };

    const handleOnClickCategory = () => {
        setOpenCategory(!openCategory);
    };

    const handleOnClickStatus = () => {
        setOpenStatus(!openStatus);
    };

    const handleOnFilterChange = (filterName, value) => {
        if (onFilterChange) {
            onFilterChange(filterName, value);
        }
    };

    const handleOnMinPriceChange = (e) => {
        setMinPrice(e.target.value);
    };

    const handleOnMaxPriceChange = (e) => {
        setMaxPrice(e.target.value);
    };

    const handleOnShowListedNFTs = () => {
        if (setShowListedNFTsOnly) {
            setShowListedNFTsOnly(true);
        }
    };

    const handleOnShowAllNFTs = () => {
        if (setShowListedNFTsOnly) {
            setShowListedNFTsOnly(false);
        }
    };

    const getAllOrListedNFTsSelectedOptionLabel = () => (
        <div style={{ minWidth: 110 }}>
            {showListedNFTsOnly ? "Marketplace" : "Gallery"}
        </div>
    );

    return (
        <div className=" d-flex filter-bar justify-content-center align-items-center">
            {/* todo: remove below container if possible */}
            <div className="container">
                <div
                    className="d-flex align-items-center filter-options"
                    style={{ paddingLeft: "" }}
                >
                    {setShowListedNFTsOnly && (
                        <FilterMenu
                            title="Showing:"
                            selectedOption={getAllOrListedNFTsSelectedOptionLabel()}
                            className="d-inline-flex px-3"
                        >
                            <div className="drop-down p-3">
                                <ul className="list-unstyled p-0 m-0">
                                    <FilterMenuItem
                                        label="Gallery"
                                        onClick={handleOnShowAllNFTs}
                                    />
                                    <FilterMenuItem
                                        label="Marketplace"
                                        onClick={handleOnShowListedNFTs}
                                    />
                                </ul>
                            </div>
                        </FilterMenu>
                    )}

                    {/* <FilterMenu
                    title={catFilter.label}
                    isArrowIcon={true}
                    isSelect={isSelect}
                    setIsSelect={setIsSelect}
                    className="d-inline-flex px-3 vertical-line"
                >
                    <div className="drop-down p-3">
                        <ul className="list-unstyled p-0 m-0">
                            {categories
                                // All option should be visible for the user
                                // .filter((cat) => cat.label !== catFilter.label)
                                .map((cat, index) => (
                                    <li
                                        className="pb-1"
                                        onClick={() => {
                                            setCatFilter({
                                                ...catFilter,
                                                label: cat.label,
                                                value: cat.value,
                                            });
                                            setIsSelect(true);
                                        }}
                                        key={index}
                                    >
                                        {cat.label}
                                    </li>
                                ))}
                        </ul>
                    </div>
                </FilterMenu> */}

                    <FilterMenu
                        title="Filter"
                        icon="/assets/icon/hg-filter-icon.svg"
                        className="d-inline-flex px-3"
                        hasNestedFilters
                    >
                        <div className="drop-down p-3">
                            <ul className="list-unstyled p-0 m-0">
                                <li className="py-2 horizontal-line">
                                    <div
                                        className={`d-flex justify-content-between align-items-center filter-list p-2 pl-3 w-100 ${
                                            openPrice
                                                ? "remove-border-radius"
                                                : ""
                                        }`}
                                    >
                                        <div className="title">Price : </div>
                                        <div
                                            onClick={handleOnClickPrice}
                                            className="py-2"
                                        >
                                            {!openPrice && (
                                                <img
                                                    src="/assets/icon/hg-arrow-down.svg"
                                                    alt=" "
                                                    className="icon mx-2"
                                                />
                                            )}
                                            {openPrice && (
                                                <img
                                                    src="/assets/icon/hg-arrow-up.svg"
                                                    alt=" "
                                                    className="icon mx-2"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <AnimatePresence>
                                        {openPrice && (
                                            <motion.div
                                                initial="collapsed"
                                                animate="open"
                                                exit="collapsed"
                                                variants={{
                                                    open: {
                                                        opacity: 1,
                                                        height: "auto",
                                                    },
                                                    collapsed: {
                                                        opacity: 0,
                                                        height: 0,
                                                    },
                                                }}
                                                transition={{ duration: 0.5 }}
                                                className={`collapsible-form-content collapse  ${
                                                    openPrice ? "show" : ""
                                                }`}
                                                style={{ overflowY: "hidden" }}
                                            >
                                                <div className="p-2">
                                                    <div className="form-group">
                                                        <label className="input-label ml-3">
                                                            Min
                                                        </label>
                                                        <input
                                                            type="number"
                                                            placeholder="1"
                                                            className="form-control form-control-lg p-2"
                                                            onChange={
                                                                handleOnMinPriceChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="input-label ml-3">
                                                            Max
                                                        </label>
                                                        <input
                                                            type="number"
                                                            placeholder="10000"
                                                            className="form-control form-control-lg p-2"
                                                            onChange={
                                                                handleOnMaxPriceChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        <button
                                                            type="button"
                                                            className="btn button my-2"
                                                            onClick={() => {
                                                                handleOnFilterChange(
                                                                    "price",
                                                                    {
                                                                        min: minPrice || null,
                                                                        max: maxPrice || null,
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            APPLY
                                                        </button>
                                                    </div>
                                                </div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </li>
                                <li className="py-2 horizontal-line">
                                    <div
                                        className={`d-flex justify-content-between align-items-center filter-list p-2 pl-3 w-100 ${
                                            openBlockchain
                                                ? "remove-border-radius"
                                                : ""
                                        }`}
                                    >
                                        <div className="title">
                                            Blockchain :{" "}
                                        </div>
                                        <div
                                            onClick={handleOnClickBlockchain}
                                            className="py-2"
                                        >
                                            {!openBlockchain && (
                                                <img
                                                    src="/assets/icon/hg-arrow-down.svg"
                                                    alt=" "
                                                    className="icon mx-2"
                                                />
                                            )}
                                            {openBlockchain && (
                                                <img
                                                    src="/assets/icon/hg-arrow-up.svg"
                                                    alt=" "
                                                    className="icon mx-2"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <AnimatePresence>
                                        {openBlockchain && (
                                            <motion.div
                                                initial="collapsed"
                                                animate="open"
                                                exit="collapsed"
                                                variants={{
                                                    open: {
                                                        opacity: 1,
                                                        height: "auto",
                                                    },
                                                    collapsed: {
                                                        opacity: 0,
                                                        height: 0,
                                                    },
                                                }}
                                                transition={{ duration: 0.5 }}
                                                className={`collapsible-form-content collapse  ${
                                                    openBlockchain ? "show" : ""
                                                }`}
                                                style={{ overflowY: "hidden" }}
                                            >
                                                <div className="p-2">
                                                    {
                                                        filter?.chain?.values?.map?.((blockchain) => (
                                                        <div key={blockchain.value} className="form-check-group d-flex justify-content-between align-items-center p-3 mb-2">
                                                            <label className="check-label m-0">
                                                                {blockchain?.icon && <img
                                                                    src={blockchain.icon}
                                                                    alt=" "
                                                                    className="icon mr-2"
                                                                />}
                                                                {blockchain.label || ""}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                className="check-input"
                                                                checked={ filter.chain.selected === blockchain.value }
                                                                onClick={() => { handleOnFilterChange("chain", blockchain.value) }}
                                                            />
                                                        </div>
                                                        )) || ""
                                                    }
                                                </div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </li>
                                <li className="py-2 horizontal-line">
                                    <div
                                        className={`d-flex justify-content-between align-items-center filter-list p-2 pl-3 w-100 ${
                                            openCategory
                                                ? "remove-border-radius"
                                                : ""
                                        }`}
                                    >
                                        <div className="title">Category : </div>
                                        <div
                                            onClick={handleOnClickCategory}
                                            className="py-2"
                                        >
                                            {!openCategory && (
                                                <img
                                                    src="/assets/icon/hg-arrow-down.svg"
                                                    alt=" "
                                                    className="icon mx-2"
                                                />
                                            )}
                                            {openCategory && (
                                                <img
                                                    src="/assets/icon/hg-arrow-up.svg"
                                                    alt=" "
                                                    className="icon mx-2"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <AnimatePresence>
                                        {openCategory && (
                                            <motion.div
                                                initial="collapsed"
                                                animate="open"
                                                exit="collapsed"
                                                variants={{
                                                    open: {
                                                        opacity: 1,
                                                        height: "auto",
                                                    },
                                                    collapsed: {
                                                        opacity: 0,
                                                        height: 0,
                                                    },
                                                }}
                                                transition={{ duration: 0.5 }}
                                                className={`collapsible-form-content collapse  ${
                                                    openCategory ? "show" : ""
                                                }`}
                                                style={{ overflowY: "hidden" }}
                                            >
                                                <div className="p-2">
                                                    {filter?.category?.values?.length
                                                        ? filter?.category?.values.map(
                                                              ({
                                                                  label,
                                                                  value,
                                                              }) => (
                                                                  <div
                                                                      key={
                                                                          value
                                                                      }
                                                                      className="form-check-group d-flex justify-content-between align-items-center p-3 mb-2"
                                                                  >
                                                                      <label className="check-label m-0">
                                                                          {
                                                                              label
                                                                          }
                                                                      </label>
                                                                      <input
                                                                          type="checkbox"
                                                                          className="check-input"
                                                                          checked={
                                                                              value ===
                                                                              filter?.category?.selected
                                                                          }
                                                                          onChange={(
                                                                              
                                                                          ) =>
                                                                              handleOnFilterChange(
                                                                                  "category",
                                                                                  value
                                                                              )
                                                                          }
                                                                      />
                                                                  </div>
                                                              )
                                                          )
                                                        : ""}
                                                </div>
                                                <div className="search-more py-2">
                                                    Search More +
                                                </div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </li>
                                <li className="py-2 horizontal-line">
                                    <div
                                        className={`d-flex justify-content-between align-items-center filter-list p-2 pl-3 w-100 ${
                                            openStatus
                                                ? "remove-border-radius"
                                                : ""
                                        }`}
                                    >
                                        <div className="title">Status : </div>
                                        <div
                                            onClick={handleOnClickStatus}
                                            className="py-2"
                                        >
                                            {!openStatus && (
                                                <img
                                                    src="/assets/icon/hg-arrow-down.svg"
                                                    alt=" "
                                                    className="icon mx-2"
                                                />
                                            )}
                                            {openStatus && (
                                                <img
                                                    src="/assets/icon/hg-arrow-up.svg"
                                                    alt=" "
                                                    className="icon mx-2"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <AnimatePresence>
                                        {openStatus && (
                                            <motion.div
                                                initial="collapsed"
                                                animate="open"
                                                exit="collapsed"
                                                variants={{
                                                    open: {
                                                        opacity: 1,
                                                        height: "auto",
                                                    },
                                                    collapsed: {
                                                        opacity: 0,
                                                        height: 0,
                                                    },
                                                }}
                                                transition={{ duration: 0.5 }}
                                                className={`collapsible-form-content collapse  ${
                                                    openStatus ? "show" : ""
                                                }`}
                                                style={{ overflowY: "hidden" }}
                                            >
                                                <div className="p-2">
                                                    {filter?.status?.values?.map?.((nftStatus) => (
                                                        <div key={nftStatus.value} className="form-check-group d-flex justify-content-between align-items-center p-3 mb-2">
                                                        <label className="check-label m-0">
                                                            {nftStatus?.icon && <img
                                                                src={nftStatus.icon}
                                                                alt=" "
                                                                className="icon mr-2"
                                                            />}
                                                            {nftStatus.label}
                                                        </label>
                                                        <input
                                                            type="checkbox"
                                                            className="check-input"
                                                            checked={ filter.status.selected === nftStatus.value }
                                                            onClick={() => { handleOnFilterChange("status", nftStatus.value)}}
                                                        />
                                                        </div>
                                                    ))}
                                                </div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </li>
                            </ul>
                        </div>
                    </FilterMenu>

                    <FilterMenu
                        title="Updated date: &nbsp;"
                        isArrowIcon={true}
                        selectedOption="Newest"
                        className="d-inline-flex px-3"
                    >
                        <div className="drop-down p-3">
                            <ul className="list-unstyled p-0 m-0">
                                <FilterMenuItem label="Newest" />
                            </ul>
                        </div>
                    </FilterMenu>

                    <FilterMenu
                        title="Price: &nbsp;"
                        isArrowIcon={true}
                        selectedOption="Low to High"
                        className="d-inline-flex px-3 vertical-line"
                    >
                        <div className="drop-down p-3">
                            <ul className="list-unstyled p-0 m-0">
                                <FilterMenuItem label="High to Low" />
                                <FilterMenuItem label="Bestmatch" />
                                <FilterMenuItem label="Most Sell" />
                                <FilterMenuItem label="With Offers" />
                            </ul>
                        </div>
                    </FilterMenu>

                    {/* <FilterMenu
                    icon="/assets/icon/hg-ascending-descending-icon.svg"
                    className="col px-2 justify-content-center"
                >
                    <div className="drop-down p-3">
                        <ul className="list-unstyled p-0 m-0">
                            <li className="pb-1 d-flex justify-content-between align-items-center">
                                <img
                                    src="/assets/icon/hg-ascending-icon.svg"
                                    alt=" "
                                    className="icon mr-2"
                                />
                                <div className="w-100"> Ascender </div>
                            </li>
                            <li className="pb-1 d-flex justify-content-between align-items-center">
                                <img
                                    src="/assets/icon/hg-descending-icon.svg"
                                    alt=" "
                                    className="icon mr-2"
                                />
                                <div className="w-100">Descender</div>
                            </li>
                        </ul>
                    </div>
                </FilterMenu> */}

                    {/* <FilterMenu
                    icon="/assets/icon/hg-sort-icon.svg"
                    className="col px-2 justify-content-center"
                >
                    <div className="drop-down p-3">
                        <ul className="list-unstyled p-0 m-0">
                            <li className="pb-1">Name</li>
                            <li className="pb-1">Date</li>
                            <li className="pb-1">Size</li>
                            <li className="pb-1">Type</li>
                        </ul>
                    </div>
                </FilterMenu> */}
                </div>
            </div>
        </div>
    );
}

export default FilterBar;
