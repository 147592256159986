import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { FILE_UPLOAD_CONFIG } from "../../config";

// Register the plugins
registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize
);

export function FileUpload(props) {
    const {
        files,
        setFiles,
        label,
        acceptedFiles,
        isRequired,
        maxFileSize,
    } = props;
    return (
        <FilePond
            files={files}
            allowMultiple={false}
            onupdatefiles={(fileItems) =>
                setFiles(fileItems.map((fileItem) => fileItem.file))
            }
            required={isRequired}
            labelIdle={label}
            credits={false}
            acceptedFileTypes={acceptedFiles}
            maxFileSize={maxFileSize || FILE_UPLOAD_CONFIG.maxSize}
        />
    );
}
