import React from "react";

import { Link, useLocation, useHistory } from "react-router-dom";
import Button from "../../components/Button";
import "./NavBar.styles.scss";

function AuthNavBar() {
    const { pathname } = useLocation();
    const history = useHistory();

    const isActive = (link) => {
        if (pathname === link) {
            return "auth-active";
        }
        return "";
    };
    return (
        <nav className="navbar navbar-expand-lg navbar-light position-absolute">
            <Link className="navbar-brand" to="/app/home">
                <div className="logo-container">
                    <div className="logo-wrapper">
                        <img
                            src="/assets/img/logo-dark.svg"
                            alt=""
                            className="logo"
                        />
                    </div>
                    <div className="logo-text">
                        <div className="title">Hyperglade</div>
                        <div className="subtitle">NFT Marketplace</div>
                    </div>
                </div>
            </Link>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navItems"
                aria-controls="navItems"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon" />
            </button>

            <div
                className="collapse navbar-collapse justify-content-end"
                id="navItems"
            >
                <div className="navbar-nav">
                    <Link
                        className={`nav-item nav-link mr-lg-4 ${isActive(
                            "/app/signin"
                        )}`}
                        to={`/app/signin`}
                    >
                        SIGN IN
                    </Link>
                    <Button
                        onClick={() => history.push("/app/register")}
                        title="JOIN"
                    />
                </div>
            </div>
        </nav>
    );
}

export default AuthNavBar;
