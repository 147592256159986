import React from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import NFTGrid from "../../components/Grids/NFTGrid";
import NavBar from "../../components/NavBar";
import ProfileHeader from "../../components/Profile/ProfileHeader";
import { useGetOwnNFTSQuery } from "../../services/nftService";
import { useGetMyProfileQuery } from "../../services/appService";
import { useGetUserQuery } from "../../services/userService";

import "./ProfilePage.styles.scss";

function AccountPage() {
    const { t } = useTranslation(["profile"]);
    const history = useHistory();
    const { userId } = useParams();
    
    const { data: ownProfile } = useGetMyProfileQuery();
    
    const isOwnProfile = ownProfile?.id === userId;
    
    const { data: profile } = useGetUserQuery({ userId });

    const { data: nftData } = useGetOwnNFTSQuery(userId, {
        skip: !profile,
        pollingInterval: 5000,
    });

    const onCardClick = (id) => {
        history.push(`/app/nft/view/${id}`);
    };

    return (
        <>
            <NavBar showLinks />
            <div className="container account-page my-5">
                {isOwnProfile && (
                    <h2 className="font-weight-bold text-center mb-5">
                        {t("MY_PROFILE")}
                    </h2>
                )}
                <div className="row justify-content-center mt-4">
                    <div className="col-xl-10 col-xxl-11">
                        <ProfileHeader
                            user={profile}
                            isOwnProfile={isOwnProfile}
                        />
                        <div className="nft-section">
                            <NFTGrid
                                gridTitle={`${
                                    isOwnProfile ? t("My_NFTS") : t("NFTS")
                                }`}
                                cardBtnTitle={t("VIEW")}
                                nftData={nftData}
                                onCardClick={onCardClick}
                                showPendingNFTs
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountPage;
