import { fetchBaseQuery } from "@rtk-incubator/rtk-query";
import { refreshAccessToken } from "../api";
import { ACCESS_TOKEN_NAME, TEMPORARY_TOKEN_NAME } from "../config";

export const temporaryAccessBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE,
    prepareHeaders: (headers) => {
        const token = sessionStorage.getItem(TEMPORARY_TOKEN_NAME);
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE,
    prepareHeaders: (headers) => {
        const token = sessionStorage.getItem(ACCESS_TOKEN_NAME);
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

export const baseQueryWithAuth = async (args, api) => {
    const result = await baseQuery(args, api);
    if (result.error && result.error.status === 401) {
        // access token expired
        // gain new access token using refresh token
        const refreshRes = await refreshAccessToken();
        if (refreshRes.error) {
            // refersh toekn expired.
            window.location = "/signout";
        } else {
            // new toke is set. now retry the request with new access token.
            const newTokenRes = await baseQuery(args, api);
            return newTokenRes;
        }
    } else if (result.error) {
        // some other error. request will fail silently
        return {
            error: {
                status: result.error?.status,
                data: result.error?.data,
            },
        };
    } else {
        return result;
    }
};

export const temporaryAccessBaseQueryWithAuth = async (args, api) => {
    try {
        const result = await temporaryAccessBaseQuery(args, api);
        if (result.error && result.error.status !== "401") {
            return { error: { status: result.error?.status, data: result.error?.data  } };            
        } 
        return result;
    } catch (error) {}
};

export const publicBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE,
});

export const baseQueryWithPublic = async (args, api) => {
    try {
        const result = await publicBaseQuery(args, api);
        if (result.error && result.error.status !== "401") {
            return { error: { status: result.error?.status, data: result.error?.data  } };            
        } 
        return result;
    } catch (error) {}
};

export const baseQueryWithAutoLogout = async (args, api) => {
    try {
        const result = await baseQuery(args, api);
        if (result.error && result.error.status !== "401") {
            return {
                error: {
                    status: result.error?.status,
                    data: result.error?.data,
                },
            };
        }
        return result;
    } catch (error) {}
};

