import React from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import { toast } from "react-toastify";
import AuthNavBar from "../NavBar/AuthNavBar";
import Button from "../Button";
import { useResendEmailVerifyMutation } from "../../services/appService";
import "./AuthVerify.styles.scss";
import { TOAST_CONFIG } from "../../config";
import parseError from "../../utils/errorUtils";

export default function SuccessVerify() {
    const location = useLocation();
    const { email } = queryString.parse(location.search);

    const [resendEmail] = useResendEmailVerifyMutation();

    const onRetry = async () => {
        const toastId = toast.loading("Resending confirmation email");
        try {
           const response = await resendEmail({ email });
           if (response.data) {
            // handle on success
            toast.update(toastId, {
                render: "Confirmation email sent",
                type: toast.TYPE.SUCCESS,
                isLoading: false,
                autoClose: TOAST_CONFIG.autoClose,
                closeButton: TOAST_CONFIG.closeButton,
            })
        }
        if (response.error) {
            // handle on error
            toast.update(toastId, {
                render:  parseError(response.error) || "Error while trying to resend the email",
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: TOAST_CONFIG.autoClose,
                closeButton: TOAST_CONFIG.closeButton,
            });
        }

        } catch (error) {}
    };

    return (
        <>
            <AuthNavBar />
            <div className="auth-layout">
                <div className="container">
                    <img
                        src="/assets/img/verify-mail.svg"
                        className="verify-img"
                        alt=""
                    />
                    <div className="sub-title text-center pt-3">
                        Just One More Step
                    </div>
                    <div className="content text-center pt-3">
                        <h3 className="mb-0">Let&apos;s Verify Your Email</h3>
                    </div>
                    <div className="sub-title text-center pt-4">
                        We sent a verification email to {" "}
                        <a className="" href={`mailto:${email}`}>
                            {email || ""}{" "}
                        </a>
                        <br />
                        Click the link in the email to verify your email address.
                    </div>
                    <div className="col text-center pt-4">
                        <a className="" href="#">
                            <Button
                                className="btn btn-primary"
                                title="Check Email"
                            />
                        </a>
                    </div>
                    <div className="row justify-content-md-center pt-3">
                        <div className="col-md-auto d-flex align-items-center mt-4">
                            <div
                                className="ml-4 d-flex align-items-center"
                                onClick={onRetry}
                            >
                                <img
                                    src="/assets/img/green-arrow.svg"
                                    className=""
                                    alt=""
                                />
                                <span className="green-link toggle-link">
                                    Retry
                                </span>
                            </div>
                            <div className="froget-mail pl-5">
                                Did not get the email? <br />
                                Don&apos;t worry. We can send it again.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
