import React, { useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useLoginStatus } from "../hooks/auth";

export const PrivateRoute = ({
    component: Component,
    restoreOnMount,
    ...rest
}) => {
    const isLoggedIn = useLoginStatus();

    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to home page
        <Route
            {...rest}
            render={(props) => (
                <>
                    <ScrollToTop restoreOnMount={restoreOnMount} />
                    {isLoggedIn ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to="/" />
                    )}
                </>
            )}
        />
    );
};

export const PublicRoute = ({
    component: Component,
    restoreOnMount,
    restricted,
    ...rest
}) => {
    const { isLoggedIn } = useLoginStatus();

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn && restricted ? (
                    <Redirect to="/" />
                ) : (
                    <>
                        <ScrollToTop restoreOnMount={restoreOnMount} />
                        <Component {...props} />
                    </>
                )
            }
        />
    );
};

export const ScrollToTop = ({ restoreOnMount = false }) => {
    const { pathname, search } = useLocation();

    useEffect(() => {
        if (!search && restoreOnMount) {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    return null;
};
