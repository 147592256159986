import React from "react";

export default function Backdrop({ show, clicked }) {
    return show ? (
        <div
            className={`w-100 h-100 position-fixed`}
            style={{ zIndex: 1100, left: 0, top: 0 }}
            onClick={clicked}
        ></div>
    ) : null;
}
