import React from "react";
import { useGetFileQuery } from "../../services/fileService";
import Button from "../Button";
import Skeleton from "../Skeletons/Skeleton";
import constants from "../../utils/constants";
import "./ArtCard.styles.scss";

const ArtCard = ({ badge, btnTitle, onBtnClick, nftDetails, isPending=false }) => {

    const [isThumbnailReady, setIsThumbnailReady] = React.useState(false);
    
    const {
        data: thumbnailFileData,
        isLoading: isCardImageLoading,
    } = useGetFileQuery(
        { fileId: nftDetails?.thumbnail },
        { skip: !nftDetails?.thumbnail }
    );

    const getCurrentNFTPrice = (prefix="", postfix="") => {
        let price = "N/A";
        if (nftDetails?.listings?.length) {
            const { listings: nftListingHistory } = nftDetails;
            const listing = nftListingHistory.find(
                ({ status }) => status === constants.NFT_LISTING_STATUS_ACTIVE
            );
            if (listing?.price) {
                const currentPrice = listing?.price;
                price = `${prefix} ${Number(currentPrice).toFixed(2)} ${postfix}`;
            }
        }
        return price;
    };

    const handleOnThumbnailLoad = () => {
        setIsThumbnailReady(true);
    }

    return (
        <div className={`ArtCardItem ${isPending ? 'pending' : ''}`}>
            <div className="card-img-wrapper">
                {!isCardImageLoading && <img
                    src={thumbnailFileData?.file}
                    alt={nftDetails?.name || ""}
                    className="card-img"
                    onLoad={handleOnThumbnailLoad}
                />}
                {!isThumbnailReady && (
                    <div className="card-img">
                        <Skeleton />
                    </div>
                )}
                {badge && (
                    <img src={badge} alt="Card Badge" className="card-badge" />
                )}
            </div>
            <div className="ArtCardContent">
                <div className="d-flex justify-content-between">
                <span className="ArtCardName d-block text-truncate">
                    {nftDetails.name || "NFT Name"}
                </span>
                {isPending && <small className="border border-secondary rounded p-1 text-secondary">
                    Pending
                </small>}
                </div>
                <div className="ArtCard-Footer mt-2">
                    <div className="footer-section">
                        <div className="ArtCardBid"> Current Price </div>
                        <div className="ArtCardMoneyRate">{getCurrentNFTPrice("", "USD")}</div>
                    </div>
                    <Button title={btnTitle} onClick={onBtnClick} />
                </div>
            </div>
        </div>
    );
};

export default ArtCard;
