import { createApi } from "@rtk-incubator/rtk-query";
import { baseQueryWithAuth } from "./baseQueries";

const creditService = createApi({
    reducerPath: "creditService",
    baseQuery: baseQueryWithAuth,
    entityTypes: ["Accounts", "CreditBalance", "CryptoAccounts"],
    endpoints: (builder) => ({
        getAccounts: builder.query({
            query: () => "/api/accounts/fiats/",
            provides: ["Accounts"],
            transformResponse: (response) => response.data,
        }),
        getCryptoAccounts: builder.query({
            query: () => "/api/accounts/cryptos/",
            provides: ["CryptoAccounts"],
            transformResponse: (response) => response.data,
        }),
        getAvailableCredits: builder.query({
            query: () => "/api/accounts/credits/",
            provides: ["CreditBalance"],
            transformResponse: (response) => response.data,
        }),
        addCryptoAccount: builder.mutation({
            query: ({ chain }) => ({
                url: "/api/accounts/cryptos/",
                method: "POST",
                body: { chain },
            }),
            invalidates: ["CryptoAccounts"],
        }),
        retryCryptoAccountAddressGeneration: builder.mutation({
            query: ({ accountId }) => ({
                url: `/api/accounts/cryptos/${accountId}/public_address/`,
                method: "PUT",
            }),
            invalidates: ["CryptoAccounts"],
        }),
        topupCredit: builder.mutation({
            query: ({ amount }) => ({
                url: "/api/accounts/credits/",
                method: "PUT",
                body: { amount },
            }),
            invalidates: ["CreditBalance"],
            transformResponse: (response) => response.data,
        }),
    }),
});

export default creditService;

export const {
    useGetAccountsQuery,
    useGetAvailableCreditsQuery,
    useTopupCreditMutation,
    useGetCryptoAccountsQuery,
    useAddCryptoAccountMutation,
    useRetryCryptoAccountAddressGenerationMutation,
} = creditService;
