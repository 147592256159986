import React from "react";

function Social({ title, icon }) {
    return (
        <div className="d-flex justify-content-around align-items-center flex-sm-row social-container mb-1">
            <div className="social-title-container d-flex justify-content-between align-items-center px-4 py-3 my-1">
                <img src={icon} alt="" className="social-icon" />
                <div className="social-title">{title}</div>
            </div>
            <input
                type="text"
                placeholder="Link"
                className="social-link-input form-control p-4 mx-3 my-1"
            />
            <button
                type="button"
                className="w-gradient btn btn-primary btn-sm add-btn py-1 px-4 my-1"
            >
                ADD
                <div className="button-glow" />
            </button>
        </div>
    );
}

export default Social;
