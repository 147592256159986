import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { FILE_UPLOAD_CONFIG } from "../../config";
import { getDisplayName } from "../../utils/dataUtils";
import ActionDropdwn from "../Dropdown/ActionDropdwn";
import Avatar from "./Avatar";
import Banner from "./Banner";
import EditableAvatar from "./EditableAvatar";
import EditableBanner from "./EditableBanner";

import "./Profile.styles.scss";

function ProfileHeader({
    user = {},
    editProfile,
    usersProfile,
    onSelectBanner,
    onSelectAvatar,
    isOwnProfile,
}) {
    const history = useHistory();
    const { t } = useTranslation(["profile"]);

    const content = (
        <div className="profile-popup">
            <p
                className="mb-0"
                onClick={() => history.push("/app/settings/profile")}
            >
                {t("EDIT_PROFILE")}
            </p>
            {/* <p className="mb-0">Wishlist</p>
            <p className="mb-0 text-danger">Delete Profile</p> */}
        </div>
    );

    return (
        <div className="profile-header">
            <div className="position-relative">
                {editProfile && (
                    <EditableBanner
                        user={user}
                        onSelectBanner={onSelectBanner}
                    />
                )}
                {!editProfile && <Banner user={user} />}
                <div className="avatar-container">
                    {editProfile && (
                        <EditableAvatar
                            width={120}
                            height={120}
                            showOutline
                            onSelectAvatar={onSelectAvatar}
                            user={user}
                        />
                    )}
                    {!editProfile && (
                        <Avatar
                            width={120}
                            height={120}
                            showOutline
                            user={user}
                        />
                    )}
                    {editProfile && (
                        <div className="avatar-hint-text">
                            RECOMMENDED SIZE : 500PX X 500PX MAXIMUM SIZE :
                            {` ${FILE_UPLOAD_CONFIG.maxSize || '0MB'}`}
                        </div>
                    )}
                </div>
            </div>

            {!editProfile && (
                <div className="profile-details-wrapper mt-4">
                    <div className="d-flex flex-column flex-sm-row align-items-start justify-content-between">
                        <div className="profile-name">
                            {getDisplayName(user)}
                        </div>
                        <div className="profile-tools mt-3 mt-sm-0">
                            {isOwnProfile && (
                                <ActionDropdwn
                                    content={content}
                                    menuStyle={{
                                        width: 130,
                                        background: "#f6f6f6",
                                    }}
                                >
                                    <div className="action-btn"></div>
                                </ActionDropdwn>
                            )}
                            {/* <div className="message-icon">
                                <img src="/assets/icon/message.svg" alt="" />
                            </div> */}
                            {usersProfile && (
                                <div className="add-favorite">
                                    <img
                                        src="/assets/icon/favorite.svg"
                                        alt=""
                                    />
                                    <span>{t("ADD_TO_FAVORITE")}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    {user.bio ? (
                        <div className="bio-text-muted mt-4">{user.bio}</div>
                    ) : (
                        <div className="bio-text-muted mt-4">
                           {t("DESCRIPTION")}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default ProfileHeader;
