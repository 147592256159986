import React, { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import "./AuthVerify.styles.scss";
import {
    Form,
    SubmitButton,
    TextField,
} from "../FormElements/FormElements";
import {
    useResetPasswordMutation,
} from "../../services/appService";
import { TOAST_CONFIG } from "../../config";
import parseError from "../../utils/errorUtils";

const resetPasswordFormSchema = Yup.object().shape({
    password: Yup.string().required("Required").min(6, "Too short"),
    confirm_password: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
    ),
});

function ResetEmail({ token, email }) {
    const history = useHistory();

    const [formData] = useState({
        password: "",
        confirm_password: "",
    });

    const [
        resetPassword,
        { isError: isResetPasswordError },
    ] = useResetPasswordMutation();

    const onReset = async (values) => {
        const { password, confirm_password } = values;
        const data = {
            new_password: password,
            confirm_new_password: confirm_password,
            token,
            email,
        };
        try {
            const toastId = toast.loading("Sending Password...");
            const response = await resetPassword(data);
            if (response.data) {
                // handle on success
                toast.update(toastId, {
                    render: "Password Reset Successfull",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
                history.push("/app/signin");
            }
            if (response.error) {
                // handle on error
                toast.update(toastId, {
                    render: parseError(response.error) || "Error while trying to reset the password",
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            }
        } catch (error) {}
    };

    const onRetry = () => {
        history.push("/app/forgot");
    };
    return (
        <div className="auth-layout">
            <div className="container">
                <div className="text-center">
                    <img src="/assets/img/token-img.svg" className="" alt="" />
                </div>

                <div className="content text-center mt-2">
                    <h4 className="mb-0 black-link">PASSWORD RESET</h4>
                </div>
                <div className="sub-desc text-center mt-3">
                    Enter your new password here.
                </div>

                <div className="row justify-content-center mt-3">
                    <div className="col-lg-6 col-xl-5">
                        <Form
                            enableReinitialize
                            initialValues={formData}
                            onSubmit={onReset}
                            validationSchema={resetPasswordFormSchema}
                        >
                            <div className="input-row mt-3">
                                <TextField
                                    type="password"
                                    label="New Password"
                                    name="password"
                                />
                            </div>
                            <div className="input-row mt-3">
                                <TextField
                                    type="password"
                                    label="Confirm New Password"
                                    name="confirm_password"
                                />
                            </div>
                            <div className="text-center mt-4">
                                <SubmitButton
                                    className=""
                                    title="RESET PASSWORD"
                                />
                            </div>
                        </Form>
                    </div>
                </div>
                {isResetPasswordError && (
                    <div className="row justify-content-md-center pt-3">
                        <div className="col-md-auto d-flex align-items-center mt-4">
                            <div
                                className="ml-4 d-flex align-items-center"
                                onClick={onRetry}
                            >
                                <img
                                    src="/assets/img/green-arrow.svg"
                                    className=""
                                    alt=""
                                />
                                <span className="green-link toggle-link">
                                    Retry
                                </span>
                            </div>
                            <div className="froget-mail pl-5">
                                Error? <br />
                                Don&apos;t worry. We can send it again.
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ResetEmail;
