import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { modalReducer } from "./modal";

// rtk-query demo
import appService from "../services/appService";
import tempAppService from "../services/tempAppService";
import { spinnerReducer } from "./spinner";
import nftService from "../services/nftService";
import fileService from "../services/fileService";
import userService from "../services/userService";
import creditService from "../services/creditService";

export default configureStore({
    reducer: combineReducers({
        modal: modalReducer,
        spinner: spinnerReducer,
        [appService.reducerPath]: appService.reducer,
        [tempAppService.reducerPath]: tempAppService.reducer,
        [fileService.reducerPath]: fileService.reducer,
        [nftService.reducerPath]: nftService.reducer,
        [userService.reducerPath]: userService.reducer,
        [creditService.reducerPath]: creditService.reducer,
    }),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false })
            .concat(appService.middleware)
            .concat(tempAppService.middleware)
            .concat(fileService.middleware)
            .concat(nftService.middleware)
            .concat(userService.middleware)
            .concat(creditService.middleware),
});
