import React from "react";
import CustomPaymentForm from "./CustomPaymentForm/CustomPaymentForm";
import "./PaymentModal.styles.scss";

export default function PaymentModal({
    paymentId,
    onSuccess,
    successMsg,
    amount,
}) {
    return (
        <div className="payment-modal-content">
            <CustomPaymentForm
                paymentId={paymentId}
                onSuccess={onSuccess}
                successMsg={successMsg}
                currencyAmount={amount}
                asModal
            />
        </div>
    );
}
