/* eslint-disable import/prefer-default-export */
import { useGetCryptoAccountsQuery } from "../services/creditService";

export const useCryptoWallets = () => {
    const {
        data: wallets,
        refetch: refetchWallets,
    } = useGetCryptoAccountsQuery();

    /**
     * Determines the availability of a crypto wallet for the specified blockchain.\
     * ⚠️ only checks if there's a matching entry for the chain. Use `isWalletReadyForTransactions` to check if the wallet is ready for transactions.
     * @param {string} chain Blockchain code to match
     * @returns `boolean` - Availability status
     */
    const isMatchingWalletAvailable = (chain = "") => {
        let availability = false;
        if (wallets?.length) {
            availability = wallets.some(
                ({ chain: chainCode }) => chain === chainCode
            );
        };
        return availability;
    };

    /**
     * Determines if the crypto wallet is ready for transactions by checking its public key.\
     * @param {string} chain Blockchain code to match
     * @returns `boolean` - Ready status
     */
    const isWalletReadyForTransactions = (chain = "") => {
        let ready = false;
        if (wallets?.length) {
            const wallet = wallets.find(
                ({ chain: chainCode }) => chain === chainCode
            );
            if (wallet) {
                ready = !!wallet?.public_address;
            }
        };
        return ready;
    }

    return {
        wallets,
        refetchWallets,
        isMatchingWalletAvailable,
        isWalletReadyForTransactions,
    };
};
