import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import store from "./state/store";
import Routes from "./routes/Routes";
import "./App.scss";
import "./custom.scss";
import "react-toastify/dist/ReactToastify.css";
import { TOAST_CONFIG } from "./config";

function App() {
    return (
        <Suspense fallback={null}>
            <Provider store={store}>
                <ToastContainer {...TOAST_CONFIG} />
                <Routes />
            </Provider>
        </Suspense>
    );
}

export default App;
