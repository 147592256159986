import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SUPPORTED_BLOCKCHAINS, TOAST_CONFIG } from "../../../config";
import { useGetCryptoAccountsQuery, useRetryCryptoAccountAddressGenerationMutation } from "../../../services/creditService";
import { modalActions } from "../../../state/modal";
import { toast } from "react-toastify";
import parseError from "../../../utils/errorUtils";
import Retry from "../../../components/AuthVerify/Retry";

const CryptoWallets = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(["profile", "common"]);
    const {
        data: cryptoAccounts,
        refetch: refetchCryptoAccounts,
    } = useGetCryptoAccountsQuery();

    const [generateWalletAddress] = useRetryCryptoAccountAddressGenerationMutation();

    React.useEffect(() => {
        // update wallets on mount
        refetchCryptoAccounts();
    }, []);

    const resolveBlockchainNameForCode = (code = "") => {
        const resolved = SUPPORTED_BLOCKCHAINS[code];
        if (resolved) {
            return resolved;
        }
        return code;
    };

    const handleAddWallet = () => {
        dispatch(
            modalActions.showModal({
                modalType: "AddCryptoAccountModal",
            })
        );
    };

    const getRetryCountdownSeconds = ({ publicAddress, createdAt }) => {
        let countdownSeconds = -1;
        const waitingTimeFrame = dayjs(createdAt).add(5, "minutes").subtract(1, "second"); // wait for 5 minutes untill pub address generation
        const fiveMinutesElapsed = dayjs().isAfter(waitingTimeFrame);
        if (!publicAddress) {
            countdownSeconds = waitingTimeFrame.diff(dayjs(), "seconds");
        }
        if (!publicAddress && fiveMinutesElapsed) {
            countdownSeconds = 0;
        }
        return countdownSeconds;
    }

    const handleOnRegenerateWalletAddress = async ({ id: accountId }) => {
        try {
            const toastId = toast.loading("Please wait...");
            const response = await generateWalletAddress({ accountId });
            if (!response.error) {
                // handle on success
                toast.update(toastId, {
                    render:
                        "Wallet address generation request successfull.",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            }
            if (response.error) {
                // handle on error
                toast.update(toastId, {
                    render: parseError(response.error) || "Error generation wallet address.",
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="container my-5">
            <h4 className="font-weight-bold text-center mb-3">
                {t("CRYPTO_WALLETS")}
            </h4>

            <div className="col-xl-4 mx-auto">
                <h6 className="text-center mb-4" style={{ lineHeight: 1.5 }}>
                    {t("FIND_YOUR_WALLETS")}
                </h6>
            </div>
            <div className="col-xl-8 mx-auto mt-5">
                <div className="p-4 border rounded text-center">
                    <div className="earned-amount-label">{t("CRYPTO_WALLETS")}</div>
                    <span className="pt-2">
                        <div className="w-100">
                            {cryptoAccounts?.length
                                ? cryptoAccounts.map(
                                      ({
                                          id,
                                          chain,
                                          public_address: publicAddress,
                                          created_at: createdAt,
                                      }) => (
                                          <div key={id} className="row my-2">
                                              <div className="col-xl-2">
                                                  {resolveBlockchainNameForCode(
                                                      chain
                                                  )}
                                              </div>
                                              <div className={`${publicAddress ? "col-xl" : "col-xl-7"} text-muted text-break`}>
                                                  {publicAddress || "Transaction pending"}
                                              </div>
                                              {!publicAddress && <div className="col-xl-3">
                                                <Retry hideArrow className="btn btn-link p-0" countdownSeconds={getRetryCountdownSeconds({ publicAddress, createdAt })} onRetry={() => {handleOnRegenerateWalletAddress({ id })}} />
                                              </div>}
                                          </div>
                                      )
                                  )
                                : t("NO_WALLETS_CREATE_ONE")}
                        </div>
                    </span>
                </div>
            </div>
            <div className="col-xl-6 mx-auto mt-3">
                <button
                    type="button"
                    className="w-gradient btn btn-primary submit-btn d-block mx-auto"
                    onClick={handleAddWallet}
                >
                    {t("CREATE_WALLET")}
                </button>
            </div>
        </div>
    );
};

export default CryptoWallets;
