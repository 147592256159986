import React from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import ResetEmail from "../../components/AuthVerify/ResetEmail";

function ResetPasswordPage() {
    const location = useLocation();
    const { token, email } = queryString.parse(location.search);

    return <ResetEmail token={token} email={email} />;
}

export default ResetPasswordPage;
