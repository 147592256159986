import { createApi } from "@rtk-incubator/rtk-query";
import { normaliseObjectArray } from "../utils/dataUtils";
import { baseQueryWithAuth } from "./baseQueries";

const userService = createApi({
    reducerPath: "userService",
    baseQuery: baseQueryWithAuth,
    entityTypes: ["Users", "User"],
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: () => "/api/users/",
            provides: ["Users"],
            transformResponse: (response) =>
                normaliseObjectArray(response),
        }),
        getUser: builder.query({
            query: ({userId}) => `/api/users/${userId}/`,
            transformResponse: response => response.data,
            provides: ["User"],
        }),
        addUser: builder.mutation({
            query: ({ username, password }) => ({
                url: "/api/users",
                method: "POST",
                body: {
                    username,
                    password,
                },
            }),
            invalidates: ["Users"],
        }),
        updateUser: builder.mutation({
            query: ({ user, userId }) => ({
                url: `/api/users/${userId}`,
                method: "PUT",
                body: {
                    user,
                },
            }),
            invalidates: ["Users", "User"],
        }),
    }),
});

export default userService;

export const { useGetUsersQuery, useGetUserQuery, useUpdateUserMutation } = userService;
