import React, { useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import ProfileNav from "./ProfileNav";
import Button from "../Button";
import "bootstrap/js/src/collapse";
import "./NavBar.styles.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useLoginStatus } from "../../hooks/auth";
import FilterSearch from "../FilterSearch/FilterSearch";
import FilterBar from "../FilterSection/FilterBar";

const navItems = [
    { path: "/app/home", Name: "HOME", disabled: false },
    // { path: "/app/gallery", Name: "NFT GALLERY", disabled: false },
    // { path: "/app/artist", Name: "ARTIST", disabled: false },
    // { path: "/app/learn-more", Name: "LEARN MORE", disabled: false },
];

const protectedNavItems = [
    { path: "/app/nft/create", Name: "CREATE", disabled: false },
];

const NavBar = ({
    showLinks = true,
    isSearchBarShown,
    isFilterBarShown,
    searchBarProps,
    filterBarProps,
    navContainerClassName = "",
}) => {
    const { pathname } = useLocation();
    const history = useHistory();

    const { t, i18n } = useTranslation(["navs"]);
    const isSignedIn = useLoginStatus();

    useEffect(() => {
        if (localStorage.getItem("i18nextLng")) {
            i18n.changeLanguage("en");
        }
    }, []);

    const isActive = (item) => {
        if (pathname === item?.path) {
            return "active";
        }
        return "";
    };

    const changeLanguage = (e) => {
        i18n.changeLanguage(e.target.value);
    };

    return (
        <>
            <nav
                className={`navbar navbar-expand-lg navbar-light bg-white ${navContainerClassName} ${
                    isSearchBarShown && searchBarProps ? "fix-search" : ""
                }`}
            >
                <Link className="navbar-brand" to="/app/home">
                    <div className="logo-container">
                        <div className="logo-wrapper">
                            <img
                                src="/assets/img/logo-dark.svg"
                                alt=""
                                className="logo"
                            />
                        </div>
                        <div className="logo-text">
                            <div className="title">Hyperglade</div>
                            <div className="subtitle">NFT Marketplace</div>
                        </div>
                    </div>
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navItems"
                    aria-controls="navItems"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                {showLinks && (
                    <div
                        className="collapse navbar-collapse justify-content-end w-100"
                        id="navItems"
                    >
                        <div className="filter-wrapper">
                            <div className="filter-container">
                                <FilterSearch {...searchBarProps} />
                            </div>
                        </div>
                        <div className="navbar-nav">
                            {navItems.map((item) => (
                                <Link
                                    key={item.path}
                                    className={`nav-item nav-link mr-lg-2 ${isActive(
                                        item
                                    )}`}
                                    to={`${item.path}`}
                                    disabled={item.disabled}
                                >
                                    {item.Name}
                                </Link>
                            ))}
                            {isSignedIn ? (
                                <>
                                    {protectedNavItems.map((item) => (
                                        <Link
                                            key={item.path}
                                            className={`nav-item nav-link mr-lg-2 ${isActive(
                                                item
                                            )}`}
                                            to={`${item.path}`}
                                            disabled={item.disabled}
                                        >
                                            {item.Name}
                                        </Link>
                                    ))}
                                    <ProfileNav />
                                </>
                            ) : (
                                <>
                                    <Link
                                        className={`nav-item nav-link mr-lg-2 ${isActive(
                                            { path: "/app/signin" }
                                        )}`}
                                        to="/app/signin"
                                    >
                                        SIGN IN
                                    </Link>
                                    <Button
                                        onClick={() =>
                                            history.push("/app/register")
                                        }
                                        title={t("JOIN")}
                                    />
                                </>
                            )}
                            {/* <div>
                            <select
                                className="nav-item nav-link bg-light border-0 mx-lg-2"
                                value={localStorage.getItem("i18nextLng")}
                                onChange={changeLanguage}
                            >
                                <option value="en">English</option>
                                <option value="es">Spanish</option>
                            </select>
                        </div> */}
                        </div>
                    </div>
                )}
                <div className="filter-bar-container">
                    {isFilterBarShown && filterBarProps && (
                        <div className="bg-white">
                            <FilterBar {...filterBarProps} />
                        </div>
                    )}
                </div>
            </nav>
        </>
    );
};

export default NavBar;
