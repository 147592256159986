import { createApi, fetchBaseQuery } from "@rtk-incubator/rtk-query";
import axios from "axios";
import { ACCESS_TOKEN_NAME } from "../config";
import { normaliseObjectArray } from "../utils/dataUtils";
import { baseQueryWithAuth, baseQueryWithAutoLogout } from "./baseQueries";

const fileService = createApi({
    reducerPath: "fileService",
    baseQuery: baseQueryWithAuth,
    endpoints: (builder) => ({
        createIPFSCopy: builder.mutation({
            query: ({ fileId, metaData }) => ({
                url: `/api/files/${fileId}/metadata/`,
                method: "POST",
                body: {
                    ...metaData,
                },
            }),
            transformResponse: (response) => response.data,
        }),
        getFile: builder.query({
            query: ({ fileId }) => ({
                url: `/api/files/${fileId}/`,
                method: "GET",
            }),
            transformResponse: (response) => response.data,
        }),
    }),
});

export const uploadFile = ({ file }) => {
    const token = sessionStorage.getItem(ACCESS_TOKEN_NAME);
    const formdata = new FormData();
    formdata.append("file", file);
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
    };
    return axios.post(`${process.env.REACT_APP_API_BASE}api/files/`, formdata, {
        headers,
    });
};

export default fileService;

export const { useCreateIPFSCopyMutation, useGetFileQuery } = fileService;
