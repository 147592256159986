/* eslint-disable react/jsx-props-no-spreading */
// these are wrapper components. need to forward the props via spreading

import React, { useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import {
    Formik,
    Form as FormikForm,
    Field,
    ErrorMessage,
    useFormikContext,
    useField,
} from "formik";
import ReactDatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "./FormElements.styles.scss";
import { FILE_UPLOAD_CONFIG } from "../../config";
import Social from "../Social/Social";

// Register the plugins
registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize
);

export function Form({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize,
    className,
    children,
    ...rest
}) {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={enableReinitialize}
            {...rest}
        >
            <FormikForm
                className={`needs-validation ${className || ""}`}
                noValidate=""
            >
                {children}
            </FormikForm>
        </Formik>
    );
}

export function SubmitButton({ className="", title }) {
    const { isSubmitting } = useFormikContext();
    return (
        <button
            type="submit"
            className={`${className} w-gradient  btn btn-primary  submit-btn`}
            disabled={isSubmitting}
        >
            {title}
            <div className="button-glow" />
        </button>
    );
}

export function DisplayField({ fieldName, children }) {
    const context = useFormikContext();
    return children(context[fieldName]);
}

export function TextField({
    name,
    label,
    type = "text",
    placeholder,
    inputClassName,
    onChange,
    ...rest
}) {
    const [field, meta] = useField({
        name,
        label,
        type,
        placeholder,
        onChange,
        ...rest,
    });

    const onFieldChange = (e) => {
        field.onChange(e);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <>
            <div className={`input-container `}>
                <input
                    className={`textfield-root ${
                        label && "label-padding"
                    } ${inputClassName}`}
                    placeholder={placeholder || ""}
                    type={type}
                    {...field}
                    {...rest}
                    onChange={onFieldChange}
                />
                <label className={meta.value && "filled"} htmlFor={name}>
                    {label}
                </label>
                {meta.touched && meta.error ? (
                    <div
                        className="invalid-feedback error-messages"
                        style={{ display: "block" }}
                    >
                        {meta.error}
                    </div>
                ) : null}
            </div>
        </>
    );
}

export function PercentageField({
    name,
    label,
    type = "number",
    placeholder,
    inputClassName,
    onChange,
    ...rest
}) {
    const [field, meta] = useField({
        name,
        label,
        type,
        placeholder,
        onChange,
        ...rest,
    });

    const onFieldChange = (e) => {
        field.onChange(e);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <>
            <div className={`input-container `}>
                <input
                    className={`textfield-root ${
                        label && "label-padding"
                    } ${inputClassName}`}
                    placeholder={placeholder || ""}
                    type={type}
                    min="0"
                    max="100"
                    {...field}
                    {...rest}
                    onChange={onFieldChange}
                />
                <label className={meta.value && "filled"} htmlFor={name}>
                    {label}
                </label>
                <div className="percentage-symbol">%</div>
                {meta.touched && meta.error ? (
                    <div
                        className="invalid-feedback error-messages"
                        style={{ display: "block" }}
                    >
                        {meta.error}
                    </div>
                ) : null}
            </div>
        </>
    );
}

export function TextAreaField({
    name,
    label,
    placeholder,
    rows = 3,
    inputClassName,
    onChange,
    ...rest
}) {
    const [field, meta] = useField({
        name,
        label,
        placeholder,
        onChange,
        ...rest,
    });
    const onFieldChange = (e) => {
        field.onChange(e);
        if (onChange) {
            onChange(e);
        }
    };
    return (
        <>
            <div className={`input-container `}>
                <textarea
                    className={`textfield-root ${
                        label && "label-padding"
                    } ${inputClassName}`}
                    rows={rows}
                    {...field}
                    {...rest}
                    onChange={onFieldChange}
                ></textarea>
                <label className={meta.value && "filled"} htmlFor={name}>
                    {label}
                </label>
                {meta.touched && meta.error ? (
                    <div
                        className="invalid-feedback error-messages"
                        style={{ display: "block" }}
                    >
                        {meta.error}
                    </div>
                ) : null}
            </div>
        </>
    );
}

export function PhoneNumberField({
    name,
    label,
    type = "text",
    placeholder,
    ...rest
}) {
    const [field, meta] = useField({ name, label, type, placeholder, ...rest });
    const { setFieldValue } = useFormikContext();

    const handleFieldChange = (phone) => {
        setFieldValue(field.name, phone);
    };

    return (
        <>
            <PhoneInput
                placeholder={placeholder}
                country="lk"
                value={field.value}
                onChange={handleFieldChange}
                inputClass="phone-input"
                dropdownClass="phone-input-dropdown"
            />
            {meta.touched && meta.error ? (
                <div
                    className="invalid-feedback error-messages"
                    style={{ display: "block" }}
                >
                    {meta.error}
                </div>
            ) : null}
        </>
    );
}

export function Select({
    name,
    label,
    options,
    defaultLabel,
    onChange,
    ...rest
}) {
    const [field, meta] = useField({ name, label, options, onChange, ...rest });
    const onFieldChange = (e) => {
        field.onChange(e);
        if (onChange) {
            onChange(e);
        }
    };
    return (
        <>
            <div className="input-container">
                <select
                    className="textfield-root"
                    id={name}
                    name={name}
                    {...field}
                    {...rest}
                    onChange={onFieldChange}
                >
                    <option value="">{defaultLabel || "Choose..."}</option>
                    {options.map((optn) => (
                        <option
                            value={optn.value}
                            label={optn.label || optn.value}
                            key={optn.value}
                        >
                            {optn.label || optn.value}
                        </option>
                    ))}
                </select>
                <label className={meta.value && "filled"} htmlFor={name}>
                    {label}
                </label>
                {meta.touched && meta.error ? (
                    <div
                        className="invalid-feedback error-messages"
                        style={{ display: "block" }}
                    >
                        {meta.error}
                    </div>
                ) : null}
            </div>
        </>
    );
}

export function SearchableSelect(props) {
    const { name, label, options, ...rest } = props;
    const [field, meta] = useField(props);
    return (
        <>
            <label htmlFor={name}>
                <strong>{label}</strong>
            </label>
            <input
                className="form-control"
                list={`${name}_list`}
                name={name}
                id={name}
                {...field}
                {...rest}
            />
            <datalist id={`${name}_list`}>
                <option value="">Choose...</option>
                {options.map((optn) => (
                    /* eslint-disable jsx-a11y/control-has-associated-label */
                    <option value={optn.value} />
                    /* eslint-disable jsx-a11y/control-has-associated-label */
                ))}
            </datalist>
            {meta.touched && meta.error ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {meta.error}
                </div>
            ) : null}
        </>
    );
}

export function Checkbox(props) {
    const { name, label } = props;
    const [field, meta, helpers] = useField(props);
    return (
        <div className="custom-control custom-checkbox mr-3 mb-2">
            <input
                type="checkbox"
                className="custom-control-input"
                id={name}
                checked={field.value}
                value={field.value}
                onChange={() => {
                    helpers.setTouched(true);
                    helpers.setValue(!field.value);
                }}
            />
            <label className="custom-control-label" htmlFor={name}>
                {label}
            </label>
        </div>
    );
}

export function DatePicker(props) {
    const { name, label, ...rest } = props;
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);

    return (
        <>
            {label && <label htmlFor={name}>{label}</label>}
            <ReactDatePicker
                {...rest}
                selected={field.value}
                onChange={(val) => setFieldValue(field.name, val)}
                className="form-control"
            />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {meta.error}
                </div>
            ) : null}
        </>
    );
}

export function FileField(props) {
    const { name, label, ...rest } = props;
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);
    return (
        <>
            {label && <label htmlFor={name}>{label}</label>}
            <input
                {...rest}
                type="file"
                onChange={(e) =>
                    setFieldValue(field.name, e.currentTarget.files[0])
                }
                className="form-control"
            />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {meta.error}
                </div>
            ) : null}
        </>
    );
}

export function NFTThumbnailFileField(props) {
    const { name, label, onChangeFiles, ...rest } = props;
    const [field, meta, helpers] = useField(props);

    const onFieldChange = (files) => {
        helpers.setValue(files);
        if (onChangeFiles) {
            onChangeFiles(files);
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="d-flex">
                    <div className="thumbnail-btn-container">
                        <div className="thumbnail-btn-wrapper">
                            <FilePond
                                {...rest}
                                id="file"
                                className="thumbnail-btn"
                                allowMultiple={false}
                                files={field.value}
                                credits={false}
                                maxFiles={1}
                                oninit={() => helpers.setValue(field.value)}
                                onupdatefiles={onFieldChange}
                                maxFileSize={FILE_UPLOAD_CONFIG.maxSize}
                                acceptedFileTypes={
                                    FILE_UPLOAD_CONFIG.acceptedThumbnailFileTypes
                                }
                                allowFileTypeValidation
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center px-2">
                        <div className="h5 font-weight-bold">
                            Add the Thumbnail
                        </div>
                        <small className="text-muted">
                            Preview for your NFT.
                        </small>
                        <small className="text-muted">
                            Max size: {FILE_UPLOAD_CONFIG.maxSize}
                        </small>
                    </div>
                </div>
            </div>
            {meta.touched && meta.error ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {meta.error}
                </div>
            ) : null}
        </>
    );
}

export function NFTFileUploadField(props) {
    const { name, label, onChangeFiles, ...rest } = props;
    const [field, meta, helpers] = useField(props);

    const onFieldChange = (files) => {
        helpers.setValue(files);
        if (onChangeFiles) {
            onChangeFiles(files);
        }
    };
    
    return (
        <>
            <div className={`nft-selector-container ${field?.value?.length ? 'hide-overlay' : ''}`}>
                <div className="title">Image, Video, Audio, or 3D Model</div>
                <FilePond
                    {...rest}
                    id="file"
                    className="nft-selector"
                    allowMultiple={false}
                    files={field.value}
                    credits={false}
                    labelIdle=""
                    maxFiles={1}
                    oninit={() => helpers.setValue(field.value)}
                    onupdatefiles={onFieldChange}
                    maxFileSize={FILE_UPLOAD_CONFIG.maxNFTSize}
                    acceptedFileTypes={FILE_UPLOAD_CONFIG.acceptedNftFileTypes}
                    allowFileTypeValidation
                />
                <div className="subtitle">
                    File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3,
                    WAV, OGG, GLB, GLTF. Max size:{" "}
                    {FILE_UPLOAD_CONFIG.maxNFTSize}
                </div>
            </div>
            {meta.touched && meta.error ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {meta.error}
                </div>
            ) : null}
        </>
    );
}

export function TableField(props) {
    const { name, label, headers } = props;
    const [field, meta, helpers] = useField(props);
    const [values, setvalues] = useState({});

    const handleInput = (e, fieldName) => {
        e.preventDefault();
        setvalues({
            ...values,
            [fieldName]: e.target.value,
        });
    };

    const reset = () => {
        const initValues = headers.reduce(
            (preValue, currValue) => ({
                ...preValue,
                [currValue.value]: "",
            }),
            {}
        );
        setvalues(initValues);
    };

    const addRecord = () => {
        if (field.value) {
            const update = field.value.slice();
            update.push(values);
            helpers.setValue(update);
        } else {
            const update = [];
            update.push(values);
            helpers.setValue(update);
        }
        reset();
    };

    const handleEnterPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            addRecord();
        }
    };

    const removeRecord = (index) => {
        const update = field.value.slice();
        update.splice(index, 1);
        helpers.setValue(update);
    };

    if (!Array.isArray(field.value)) {
        return null;
    }

    if (!Array.isArray(headers)) {
        return null;
    }

    return (
        <>
            <label htmlFor={`table${name}`}>{label}</label>
            <table id={`table${name}`} className="table table-borderless">
                <thead>
                    <tr>
                        {headers.map((header) => (
                            <th scope="col">{header.label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {field.value.map((fieldItem, idx) => (
                        <tr key={fieldItem[headers[0]?.value]}>
                            {headers.map((header) => (
                                <>
                                    <td>{fieldItem[header.value]}</td>
                                </>
                            ))}
                            <td>
                                <button
                                    type="button"
                                    style={{ minWidth: 100 }}
                                    className="btn btn-light btn-sm"
                                    onClick={() => removeRecord(idx)}
                                >
                                    Remove Entry
                                </button>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        {headers.map((header) => (
                            <>
                                <td key={header.value}>
                                    <input
                                        className="form-control"
                                        aria-describedby="inputGroup-sizing-sm"
                                        value={values[header.value]}
                                        onChange={(e) => {
                                            handleInput(e, header.value);
                                        }}
                                        onKeyPress={handleEnterPress}
                                    />
                                </td>
                            </>
                        ))}
                        <td>
                            <button
                                type="button"
                                style={{ minWidth: 100 }}
                                className="btn btn-light btn-sm"
                                onClick={addRecord}
                            >
                                Add Entry
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ErrorMessage
                name={name}
                render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
            />
        </>
    );
}

export function YesNoRadio(props) {
    const { name, label } = props;

    return (
        <Field name={name} id={name} label={label}>
            {({ field, form: { setFieldValue } }) => (
                <div>
                    {/* <label>{label}</label> */}
                    <div className="d-flex">
                        <div className="custom-control custom-radio mr-3">
                            <input
                                className="custom-control-input"
                                checked={field.value}
                                onChange={() => {
                                    setFieldValue(name, true);
                                }}
                                id={`${name}-yes`}
                                type="radio"
                            />
                            <label
                                className="custom-control-label"
                                htmlFor={`${name}-yes`}
                            >
                                Yes{" "}
                            </label>
                        </div>
                        <div className="custom-control custom-radio">
                            <input
                                className="custom-control-input"
                                checked={field.value === false}
                                onChange={() => {
                                    setFieldValue(name, false);
                                }}
                                id={`${name}-no`}
                                type="radio"
                            />
                            <label
                                className="custom-control-label"
                                htmlFor={`${name}-no`}
                            >
                                No{" "}
                            </label>
                        </div>
                    </div>
                    <ErrorMessage
                        name={name}
                        render={(msg) => (
                            <div style={{ color: "red" }}>{msg}</div>
                        )}
                    />
                </div>
            )}
        </Field>
    );
}

export function SocialsField() {
    return (
        <>
            <Social title="Twitter" icon="/assets/icon/hg-twitter.svg" />
            <Social title="Facebook" icon="/assets/icon/hg-facebook.svg" />
            <Social title="You Tube" icon="/assets/icon/hg-youtube.svg" />
            <div className="find-more-btn p-3">
                <img
                    src="/assets/icon/hg-plus-icon.svg"
                    alt=""
                    className="mr-2"
                />
                Find More Connection
            </div>
        </>
    );
}
