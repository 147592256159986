import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";

dayjs.extend(dayjsDuration);

export default function Retry({ onRetry, hideArrow=false, className="", countdownSeconds=30 }) {
    const [countdown, setCountDown] = useState(countdownSeconds);
    const [intervalId, setIntervalId] = useState(null);
    const isRetryDisabled = countdown !== 0;

    const initCountdown = () => {
        setCountDown(countdownSeconds);
        const countdownInterval = setInterval(() => {
            if (countdownSeconds > 0) {
                setCountDown((previousCountdown) => previousCountdown - 1);
            }
        }, 1000);
        setIntervalId(countdownInterval);
    };

    useEffect(() => {
        initCountdown();
    }, []);

    useEffect(() => {
        if (!isRetryDisabled && intervalId) {
            clearInterval(intervalId);
        }
    }, [countdown, intervalId]);

    const handleRetry = () => {
        if (!isRetryDisabled) {
            onRetry();
            initCountdown();
        }
    };

    return (
        <div
            className={className || "ml-4 d-flex align-items-center flex-wrap"}
            style={
                isRetryDisabled
                    ? { filter: "grayscale(0.9)", opacity:0.5, width: hideArrow ? "unset" : "9rem" }
                    : { width: hideArrow ? "unset" : "5rem" }
            }
        >
            {!hideArrow && <img src="/assets/img/purple-arrow.svg" className="" alt="" />}
            <span className="purple-link toggle-link" onClick={handleRetry}>
                {!isRetryDisabled ? "Retry" : `${countdown < 0 ? "Retry" : `Retry in ${dayjs.duration(countdown, "seconds").format("mm [m] ss [s]").replace("00 m", "")}`}`}
            </span>
        </div>
    );
}
