import React from "react";
import { useTranslation } from "react-i18next";
import { useGetAccountsQuery } from "../../../services/creditService";
import "./Earnings.styles.scss";

const Earnings = () => {
    const { t } = useTranslation(["profile", "common"]);

    const {
        data: earningsBalance,
        refetch: refetchEarningsBalance,
    } = useGetAccountsQuery();

    React.useEffect(() => {
        // Update earnings on mount
        refetchEarningsBalance();
    }, []);

    return (
        <div className="container my-5">
            <h4 className="font-weight-bold text-center mb-3">
                {t("EARNINGS")}
            </h4>

            <div className="col-xl-4 mx-auto">
                <h6 className="text-center mb-4" style={{ lineHeight: 1.5 }}>
                    {t("FIND_YOUR_EARNINGS")}
                </h6>
            </div>
            <div className="col-xl-6 mx-auto mt-5">
                <div className="p-4 border rounded text-center">
                    <div className="earned-amount-label">{t("EARNED_AMOUNT")}</div>
                    <span className="pt-2">
                        {earningsBalance?.balance || "0.00"}
                    </span>
                </div>
            </div>
            <div className="col-xl-6 mx-auto mt-3">
                <button
                    type="button"
                    className="d-block mx-auto w-gradient btn btn-primary submit-btn"
                >
                   {t("REQUEST_PAYOUT")}
                </button>
            </div>
        </div>
    );
};

export default Earnings;
