import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLoginStatus } from "../../hooks/auth";
import { useGetMyProfileQuery } from "../../services/appService";
import Avatar from "../Profile/Avatar";

function ProfileNav() {
    const [visibility, setVisibility] = useState(false);

    const isLoggedIn = useLoginStatus();

    const { data: profile } = useGetMyProfileQuery({}, { skip: !isLoggedIn });

    return (
        <div className="position-relative profile-nav-wrapper ml-0 ml-md-3">
            <div className="d-none d-md-block">
                <div
                    onMouseOver={() => setVisibility(true)}
                    onMouseLeave={() => setVisibility(false)}
                    onFocus={() => setVisibility(true)}
                    onBlur={() => setVisibility(false)}
                >
                    <Avatar user={profile} width={40} height={40} />
                </div>

                <div
                    className={`list-root ${
                        visibility ? "visible" : "invisible"
                    }`}
                    onMouseOver={() => setVisibility(true)}
                    onMouseLeave={() => setVisibility(false)}
                >
                    <div className="nav-list">
                        <ul className="pl-0">
                            <li>
                                <Link to={`/app/user/${profile?.id}`}>
                                    Profile
                                </Link>
                            </li>
                            <li>
                                <Link to={"/app/settings"}>Settings</Link>
                            </li>
                            <li>
                                <Link to={"/app/signout"}>Logout</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="d-block d-md-none m-0">
                <Link
                    className="nav-item nav-link mr-lg-2"
                    to={`/app/user/${profile?.id}`}
                >
                    PROFILE
                </Link>
                <Link
                    className="nav-item nav-link mr-lg-2"
                    to={"/app/settings"}
                >
                    SETTINGS
                </Link>
                <Link className="nav-item nav-link mr-lg-2" to={"/app/signout"}>
                    LOGOUT
                </Link>
            </div>
        </div>
    );
}

export default ProfileNav;
