import React, { useState } from "react";
import { useParams } from "react-router";
import {
    Form,
    Select,
    SubmitButton,
    TextField,
} from "../../components/FormElements";
import NavBar from "../../components/NavBar";
import ReactDatePicker from "react-datepicker";

import "./NFTPages.styles.scss";

const blockchains = [{ value: "ETH", label: "ETH" }];

function SellNFTPage() {
    const { nftId } = useParams();

    const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  
    const [formData, setFormData] = useState({
        chain: "ETH",
        price: "",
    });

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
      };
    return (
        <>
            <NavBar showLinks />
            <div className="container sell-nft-page mt-5">
                <div className="row justify-content-around">
                    <div className="col-md-5">
                        <img
                            src="/assets/img/card-img-1.png"
                            className="w-100"
                            alt=""
                        />
                    </div>
                    <div className="col-md-5">
                        <Form
                            initialValues={formData}
                            // validationSchema={registerSchema}
                            // onSubmit={onSubmitRegisterForm}
                            enableReinitialize
                            // className="register-form d-md-flex"
                        >
                            <div>
                                <label>Price</label>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <Select
                                            name="chain"
                                            placeholder="Chain"
                                            options={blockchains?.map(
                                                (chain) => ({
                                                    value: chain.value,
                                                    label: chain.label,
                                                })
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            name="price"
                                            placeholder="Price"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className=" mt-4">
                                <label>Duration</label>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <ReactDatePicker
                                           selected={endDate}
                                           onChange={onChange}
                                           startDate={new Date()}
                                           endDate={endDate}
                                           selectsRange
                                           minDate={new Date()}
                                           dateFormat="MM-dd-yyyy"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="input-row mt-4">
                                <SubmitButton title="Complete listing" />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SellNFTPage;
