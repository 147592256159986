import React from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import ValidReceivedVerifyCode from "../../components/AuthVerify/ValidReceivedVerifyCode";
import {
    useGetMyProfileWithTempTokenQuery,
    useVerifyMobileTokenMutation,
} from "../../services/tempAppService";
import { TOAST_CONFIG } from "../../config";

function VerifyMobilePage() {
    const history = useHistory();

    const [
        verifyMobile,
        {
            error: mobileVerifyError,
        },
    ] = useVerifyMobileTokenMutation();

    const { refetch: refetchProfile } = useGetMyProfileWithTempTokenQuery();

    const onVerify = async (otp) => {
        try {
            const toastId = toast.loading("Verifying OTP...");
            const response = await verifyMobile({ token: otp, method: "SMS" });
            if (response?.error) {
                // handle on error
                toast.update(toastId, {
                    render:
                        mobileVerifyError?.data?.errors?.[0] || "Invalid OTP",
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            } else {
                // handle on success
                toast.update(toastId, {
                    render: "Phone number verified! Signin to continue",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
                refetchProfile(); // TODO: temp hack. handle properly later
                history.push("/app/signout");
            }
        } catch (error) {
            // continue
        }
    };

    const handleRetry = () => {
        history.push("/app/add/mobile");
    };
    return (
        <ValidReceivedVerifyCode
            handleRetry={handleRetry}
            onVerify={onVerify}
        />
    );
}

export default VerifyMobilePage;
