import React, { useState } from "react";
import Backdrop from "../BackDrop";

import "./Dropdown.styles.scss";

function ActionDropdwn(props) {
    const { children, content, menuStyle } = props;
    const [showDropdown, setShowDropdown] = useState(false);
    return (
        <>
            <Backdrop
                show={showDropdown}
                clicked={() => setShowDropdown(false)}
            />
            <div className="action-dropdown">
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowDropdown(!showDropdown)}
                >
                    {children}
                </div>

                <div
                    className={`dropdown-content ${
                        showDropdown ? "show-content" : ""
                    }`}
                    style={menuStyle}
                >
                    {content}
                </div>
            </div>
        </>
    );
}

export default ActionDropdwn;
