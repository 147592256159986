import React from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../config";
import { useTopupCreditMutation } from "../../../services/creditService";
import { modalActions } from "../../../state/modal";
import { Form, SubmitButton, TextField } from "../../FormElements";
import parseError from "../../../utils/errorUtils";

const creditSchema = yup.object().shape({
    amount: yup
        .number()
        .min(1, "Minimum allowed amount is: 1")
        .required("Please enter amount to topup"),
});

const CreditTopUpModal = ({ onCreditTopUp }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(["profile", "common"]);
    const { hideModal } = modalActions;

    const [creditTopUpFormData, setCreditTopUpFormData] = React.useState({
        amount: "",
    });

    const [topupCredits] = useTopupCreditMutation();

    const handleOnTopup = async ({ amount }) => {
        try {
            const toastId = toast.loading("Requesting credit topup...");
            const response = await topupCredits({ amount });
            if (response.data) {
                // handle on success
                toast.update(toastId, {
                    render:
                        "Credit topup request success. Redirecting to payment.",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
                const { data: paymentId } = response;
                dispatch(
                    modalActions.showModal({
                        modalType: "PaymentModal",
                        modalProps: {
                            amount,
                            paymentId,
                            successMsg:
                                "Payment succeeded. Your credit balance will be updated shortly.",
                            onSuccess: () => {
                                if (onCreditTopUp) {
                                    onCreditTopUp();
                                }
                                dispatch(hideModal());
                            },
                        },
                    })
                );
            }
            if (response.error) {
                // handle on error
                toast.update(toastId, {
                    render: parseError(response.error) || "Error requesting topup.",
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                    closeButton: TOAST_CONFIG.closeButton,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleOnClickCancel = () => {
        dispatch(hideModal());
    };
    return (
        <Form
            initialValues={creditTopUpFormData}
            validationSchema={creditSchema}
            onSubmit={handleOnTopup}
            enableReinitialize
        >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h5>Enter credit amount</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 p-3 text-center">
                        <TextField
                            type="number"
                            name="amount"
                            label={t("CREDIT_AMOUNT")}
                            inputClassName="input-class"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <SubmitButton title={t("TOPUP")} />
                    </div>
                    <div className="col-md-5">
                        <button
                            type="button"
                            className="btn btn-outline-primary w-100 h-100"
                            onClick={handleOnClickCancel}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default CreditTopUpModal;
