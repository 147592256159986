import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import AuthNavBar from "../NavBar/AuthNavBar";
import Button from "../Button";
import "./AuthVerify.styles.scss";

export default function VerifyComplete({ isLoading }) {
    const history = useHistory();
    return (
        <>
            <AuthNavBar />
            <div className="auth-layout">
                <div className="container">
                    <img
                        src="/assets/img/auth-pass.svg"
                        className="verify-img"
                        alt=""
                    />
                    {!isLoading && (
                        <>
                            <div className="sub-title text-center pt-4">
                                C O N G R A T U L A T I O N S !
                            </div>
                            <div className="content text-center pt-3">
                                <h3 className="mb-0 black-link">
                                    You Have Successfuly Verified Your Email
                                </h3>
                            </div>
                        </>
                    )}

                    <div className="col text-center mt-4">
                        <Button
                            className="btn btn-primary"
                            title="SIGN IN"
                            onClick={() => history.push("/app/signin")}
                        />
                    </div>
                    <div className="row justify-content-center mt-4">
                        <img
                            src="/assets/img/blue-arrow.svg"
                            className=""
                            alt=""
                        />
                        <NavLink
                            to="/app/home"
                            className="blue-link toggle-link"
                        >
                            Go Back to Home Page
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    );
}
