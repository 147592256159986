import React from "react";
import { useDispatch } from "react-redux";
import Avatar from "./Avatar";
import { ImageButton } from "../Button";
import { modalActions } from "../../state/modal";

const EditableAvatar = ({
    width = 45,
    height = 45,
    showOutline,
    user = {},
    onSelectAvatar,
}) => {
    const dispatch = useDispatch();

    const handleAvatarSelect = (file) => {
        if (onSelectAvatar && file) {
            onSelectAvatar(file);
        }
    };

    const handleOnEdit = () => {
        dispatch(
            modalActions.showModal({
                modalType: "ImagePickerModal",
                modalProps: {
                    onSelect: handleAvatarSelect,
                },
            })
        );
    };

    return (
        <>
            <Avatar
                height={height}
                width={width}
                showOutline={showOutline}
                user={user}
            />

            <button type="button" onClick={handleOnEdit} className="edit-btn">
                <img
                    src="/assets/icon/edit.svg"
                    alt="Edit Avatar"
                    className="edit-icon"
                />
            </button>
        </>
    );
};

export default EditableAvatar;
