import { createApi } from "@rtk-incubator/rtk-query";
import { normaliseObjectArray } from "../utils/dataUtils";
import { baseQueryWithAuth } from "./baseQueries";

const nftService = createApi({
    reducerPath: "nftService",
    baseQuery: baseQueryWithAuth,
    entityTypes: ["NFT", "ListedNFT", "NFTTags", "NFTListings"],
    endpoints: (builder) => ({
        getNFTS: builder.query({
            query: ({ tag, isApproved }) =>
                tag
                    ? `/api/nfts/?tag=${tag}`
                    : `/api/nfts/?approved=${isApproved}`,
            provides: ["NFT"],
            transformResponse: (response) =>
                normaliseObjectArray(response.data),
        }),
        getListedNFTS: builder.query({
            query: ({ tag, isApproved, listedOnly=true }) =>
                tag
                    ? `/api/nfts/?tag=${tag}${listedOnly ? "&listed=true" : ""}${isApproved ? "&approved=true" : ""}`
                    : `/api/nfts/?${isApproved ? "approved=true" : ""}${listedOnly ? "&listed=true" : ""}`,
            provides: ["ListedNFT"],
            transformResponse: (response) =>
                normaliseObjectArray(response.data),
        }),
        getOwnNFTS: builder.query({
            query: (userId) => `/api/nfts/?owned_by=${userId}`,
            provides: ["NFT"],
            transformResponse: (response) =>
                normaliseObjectArray(response.data),
        }),
        getNFT: builder.query({
            query: (nftId) => `/api/nfts/${nftId}/`,
            provides: (_, nftId) => [{ type: "NFT", id: nftId }],
            transformResponse: (response) => response.data,
        }),
        createNFT: builder.mutation({
            query: (data) => ({
                url: "/api/nfts/",
                method: "POST",
                body: data,
            }),
            transformResponse: (response) => response.data,
            invalidates: ["NFT", "ListedNFT"],
        }),
        getNFTTags: builder.query({
            query: () => `/api/tags/`,
            provides: ["NFTTags"],
            transformResponse: (response) =>
                normaliseObjectArray(response.data),
        }),
        getNFTListings: builder.query({
            query: (nftId) => `/api/nfts/${nftId}/listings/`,
            provides: (_, nftId) => [{ type: "NFTListings", id: nftId }],
            transformResponse: (response) => response.data,
        }),
        listNFT: builder.mutation({
            query: (data) => ({
                url: `/api/nfts/${data?.id}/`,
                method: "PUT",
                body: data,
            }),
            invalidates: (_, data) => [{ type: "NFT", id: data?.id },{ type: "ListedNFT", id: data?.id }, { type: "ListedNFT", id: data?.id }, "NFTListings"],
            transformResponse: (response) => response.data,
        }),
        purchaseNFT: builder.mutation({
            query: (data) => ({
                url: `/api/nfts/${data?.id}/`,
                method: "PUT",
                body: data,
            }),
            invalidates: (_, data) => [
                { type: "NFT", id: data?.id },
                { type: "ListedNFT", id: data?.id },
                { type: "NFTListings", id: data?.id },
            ],
            transformResponse: (response) => response.data,
        }),
    }),
});

export default nftService;

export const {
    useGetNFTSQuery,
    useGetListedNFTSQuery,
    useGetOwnNFTSQuery,
    useGetNFTQuery,
    useGetNFTListingsQuery,
    useCreateNFTMutation,
    useGetNFTTagsQuery,
    useListNFTMutation,
    usePurchaseNFTMutation,
} = nftService;
