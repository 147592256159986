import React from "react";
import { useHistory } from "react-router";
import ActionDropdwn from "../Dropdown/ActionDropdwn";
import NFTBidDetails from "./NFTBidDetails";
import NFTBidForm from "./NFTBidForm";
import { useGetUserQuery } from "../../services/userService";
import { SUPPORTED_BLOCKCHAINS } from "../../config";

import "./NFTInformation.styles.scss";

const NFTInformation = ({
    nft,
    onAddAuction,
    isViewMode = true,
    onClickBuy,
    onClickAdvanceNFTDetails,
    onClickListNFT,
    onClickUnlistNFT,
}) => {
    const history = useHistory();

    const { data: nftCreator } = useGetUserQuery(
        { userId: nft?.created_by },
        { skip: !nft?.created_by }
    );
    const content = (
        <div className="d-flex align-items-center px-3">
            <img
                src="/assets/icon/hg-delete-icon.svg"
                alt=" "
                className="delete-icon"
            />
            <div className="action-menu-content-item delete">
                Report this NFT
            </div>
        </div>
    );

    const handleAddAuction = (values) => {
        if (onAddAuction) {
            onAddAuction(values);
        }
    };

    const handleOnClickBuy = async () => {
        await onClickBuy();
    };

    const handleOnClickAdvanceNFTDetails = () => {
        if (onClickAdvanceNFTDetails) {
            onClickAdvanceNFTDetails();
        }
    };

    const handleOnClickListNFT = (price) => {
        if (onClickListNFT) {
            onClickListNFT(price);
        }
    };

    const handleOnClickUnlistNFT = () => {
        if (onClickUnlistNFT) {
            onClickUnlistNFT();
        }
    };

    const handleCreatorsNameClick = () => {
        if (nft?.created_by) {
            history.push(`/app/user/${nft.created_by}`);
        }
    };

    return (
        <>
            <div className="nft-information-container">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between">
                        <div className="creator-info">
                            <img
                                src={
                                    nft?.creator?.logo ||
                                    "/assets/icon/card-badge-3.svg"
                                }
                                alt=" "
                                className="creator-badge"
                            />
                            <span>
                                {SUPPORTED_BLOCKCHAINS[nft?.chain] ||
                                    "Name Unavailable"}
                            </span>
                        </div>
                        <div className="nft-actions">
                            <button type="button" className="btn">
                                <img
                                    src="/assets/icon/hg-favourite-grey.svg"
                                    alt=""
                                    hidden={!isViewMode}
                                />
                            </button>
                            <ActionDropdwn content={content}>
                                <img src="/assets/icon/hg-menu.svg" alt="" />
                            </ActionDropdwn>
                        </div>
                    </div>
                    <div className="my-3">
                        <div 
                            aria-hidden
                            className="h4 font-weight-bold" 
                            role="button" 
                            onClick={handleCreatorsNameClick}
                        >
                            {nftCreator?.first_name || ""}
                        </div>
                    </div>
                    <button type="button" className="add-to-wishlist-btn mb-2">
                        Add to Wishlist
                        <img
                            src="/assets/icon/hg-wishlist.svg"
                            alt=" "
                            className="icon"
                        />
                    </button>
                    <div className="container-fluid p-0 mt-4 mb-3">
                        <small>
                            {nft?.description || "Description Unavailable"}
                        </small>
                    </div>
                    {!isViewMode && (
                        <NFTBidForm
                            nft={nft}
                            onSubmit={handleAddAuction}
                            onClickList={handleOnClickListNFT}
                            onClickUnlist={handleOnClickUnlistNFT}
                        />
                    )}
                    {isViewMode && (
                        <NFTBidDetails
                            nft={nft}
                            onClickBuy={handleOnClickBuy}
                        />
                    )}
                    <div className="row mt-5 mb-1">
                        <div className="col-12">
                            <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={handleOnClickAdvanceNFTDetails}
                            >
                                <img
                                    src="/assets/icon/hg-info-hex.svg"
                                    alt=" "
                                    className="mr-2"
                                />
                                Advance Details
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NFTInformation;
