import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import queryString from "query-string";
import appService from "../../services/appService";
import VerifyComplete from "../../components/AuthVerify/VerifyComplete";
import UnsuccessVerify from "../../components/AuthVerify/UnsuccessVerify";
import { TOAST_CONFIG } from "../../config";

function EmailVerificationPage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { token } = queryString.parse(location.search);
    const [isVerifyError, setIsVerifyError] = useState(false);
    const [isFetchVerifyLoading, setIsFetchVerifyLoading] = useState(false);


    useEffect(() => {
        if (token) {
            verifyEmail();
        }
    }, [token]);

    const verifyEmail = async () => {
        const toastId = toast.loading("Loading...");
        setIsFetchVerifyLoading(true);
        // RTK usage without hook to preserve refetch ability while accessing response
        const { isSuccess, isError } = await dispatch(
            appService.endpoints.verifyEmail.initiate({ token })
        );
        setIsFetchVerifyLoading(false);
        setIsVerifyError(isError);
        if (isSuccess) {
            // handle on success
            toast.update(toastId, {
                render: "Verified Successfully",
                type: toast.TYPE.SUCCESS,
                isLoading: false,
                autoClose: TOAST_CONFIG.autoClose,
                closeButton: TOAST_CONFIG.closeButton,
            });
        } 
        if (isError) {
            // handle on error
            toast.update(toastId, {
                render: "Error",
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: TOAST_CONFIG.autoClose,
                closeButton: TOAST_CONFIG.closeButton,
            });
        }
    };

    if (isVerifyError) return <UnsuccessVerify />;

    return <VerifyComplete isLoading={isFetchVerifyLoading} />;
}

export default EmailVerificationPage;
