import { createSlice } from "@reduxjs/toolkit";

const initState = {
    isVisible:false,
};

const spinnerSlice = createSlice({
    name: "spinner",
    initialState: initState,
    reducers: {
        setSpinner(state,{action,payload}){
            state.isVisible=payload;
        }
    }
});

export const spinnerActions = spinnerSlice.actions;
export const spinnerReducer = spinnerSlice.reducer;