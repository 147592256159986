const constants = {
    NFT_LISTING_STATUS_PAYMENT_PROCESSING: "PAYMENT_PROCESSING",
    NFT_LISTING_STATUS_PENDING_LISTINGS: "PENDING_LISTINGS",
    NFT_LISTING_STATUS_PENDING_APPROVAL: "PENDING_APPROVAL",
    NFT_LISTING_STATUS_ACTIVE: "ACTIVE",
    NFT_LISTING_STATUS_INACTIVE: "INACTIVE",
    NFT_LISTING_STATUS_SOLD: "SOLD",
    NFT_LISTING_STATUS_PAID: "PAID",
    TRANSACTION_STATUS_PENDING: "PENDING",    
    TRANSACTION_STATUS_SUCCESS: "SUCCESSED",
    TRANSACTION_STATUS_FAILED: "FAILED",    
};

export default constants;
