import React from "react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import relativeTimePlugin from "dayjs/plugin/relativeTime";
import NavBar from "../../components/NavBar";
import FilterSearch from "../../components/FilterSearch/FilterSearch";
import NFTThumbnail from "../../components/NFTThumbnail/NFTThumbnail";
import NFTInformation from "../../components/NFTInformation/NFTInformation";
import Footer from "../../components/Footer/Footer";
import CollapsibleRecord from "../../components/CollapsibleRecord/CollapsibleRecord";
import PaginatedTable from "../../components/PaginatedTable";
import NFTPriceHistoryChart from "../../components/NFTPriceHistoryChart/NFTPriceHistoryChart";
import { modalActions } from "../../state/modal";
import { useLoginStatus } from "../../hooks/auth";
import { useCryptoWallets } from "../../hooks/cryptoWallet";
import { useTransactionCredits } from "../../hooks/transactionCredits";
import { normaliseObjectArray } from "../../utils/dataUtils";
import { TOAST_CONFIG } from "../../config";
import { useGetMyProfileQuery } from "../../services/appService";
import {
    useGetNFTQuery,
    useListNFTMutation,
    usePurchaseNFTMutation,
} from "../../services/nftService";
import parseError from "../../utils/errorUtils";
import constants from "../../utils/constants";
import "./NFTPages.styles.scss";

dayjs.extend(relativeTimePlugin);

const ViewNFTPage = () => {
    const history = useHistory();
    const { nftId } = useParams();
    const dispatch = useDispatch();
    const { showModal } = modalActions;
    const [searchQuery, setSearchQuery] = React.useState("");

    const isLoggedIn = useLoginStatus();

    // TODO: use this details to verify whether NFT information is view only or not
    const { data: profile } = useGetMyProfileQuery({}, { skip: !isLoggedIn });

    const { data: selectedNFT, refetch: refetchNFT } = useGetNFTQuery(nftId, {
        skip: !nftId,
    });

    
    const { 
        isMatchingWalletAvailable,
        isWalletReadyForTransactions
    } = useCryptoWallets();

    const { isCreditBalanceSufficient } = useTransactionCredits();

    const [listNFT] = useListNFTMutation();
    const [purchaseNFT] = usePurchaseNFTMutation();

    const activityLogs = React.useMemo(() => {
        const allActivityLogs = [
            {
                activity: "Create",
                price: "-",
                from: "-",
                to: profile?.first_name,
                date: selectedNFT?.created_at,
            },
        ];
        let nftListingActivityLogs = [];
        if (selectedNFT?.listings?.length) {
            const { listings: nftListingHistory } = selectedNFT;
            nftListingActivityLogs = nftListingHistory
                .map(({ price, created_at: createdAt }) => ({
                    activity: "Price Change",
                    price: Number(price).toFixed(1),
                    from: "-",
                    to: "-",
                    date: createdAt,
                }))
                .reverse();
        }
        return allActivityLogs
            .concat(nftListingActivityLogs)
            .map((item, idx) => ({ ...item, id: idx + 1 }));
    }, [profile, selectedNFT]);

    const normalizedActivityLogs = normaliseObjectArray(activityLogs);

    const renderTimeAgo = ({ date }) => {
        const dayjsDate = dayjs(date);
        if (dayjsDate.isValid()) {
            return dayjsDate.fromNow();
        }
        return "Invalid Date";
    };

    const renderFrom = ({ from }) => <Link to="#">{from}</Link>;

    const renderTo = ({ to }) => <Link to="#">{to}</Link>;

    const activityLogColumns = [
        "activity",
        "price",
        renderFrom,
        renderTo,
        renderTimeAgo,
    ];

    const activityLogColumnNames = ["Activity", "Price", "From", "To", "Date"];

    const handleOnClickAdvanceNFTDetails = () => {
        dispatch(
            showModal({
                modalType: "AdvancedNFTDetailsModal",
                modalProps: {
                    nft: selectedNFT
                },
            })
        );
    };

    const onListNFT = async (price) => {
        const castedPrice = Number(price);
        if (castedPrice >= 1) {
            const payload = {
                id: selectedNFT?.id,
                price: castedPrice,
                listing: true,
            };
            const toastId = toast.loading("Listing NFT...");
            const response = await listNFT(payload);
            if (response.data) {
                // handle on success
                toast.update(toastId, {
                    render: "NFT Listed",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                });
            }
            if (response.error) {
                const errorStr = parseError(response.error);
                // handle on error
                toast.update(toastId, {
                    render: errorStr,
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: TOAST_CONFIG.autoClose,
                });
            }
        }
    }

    const handleOnClickListNFT = (price) => {
        if (!selectedNFT?.approved) {
            // NFT is not approved - proceed to confirmation
            dispatch(modalActions.showModal({
                modalType: 'ConfirmationModal',
                modalProps: {
                    title: "Confirm NFT listing",
                    description: "This NFT is not approved yet. So it will not be visible on the Homepage. Please confirm listing.",
                    onDone: () => {onListNFT(price)}
                },
            }));
        } else {
            // NFT is approved - proceed to listing
            onListNFT(price);
        }
    };

    const handleOnClickUnlistNFT = async () => {
        // TODO: remove price field once backend validation is removed for unlisting
        const payload = {
            id: selectedNFT?.id,
            price: 0,
            listing: false,
        };
        const toastId = toast.loading("Unlisting NFT...");
        const response = await listNFT(payload);
        if (response.data) {
            // handle on success
            toast.update(toastId, {
                render: "NFT Unlisted",
                type: toast.TYPE.SUCCESS,
                isLoading: false,
                autoClose: TOAST_CONFIG.autoClose,
            });
        }
        if (response.error) {
            const errorStr = parseError(response.error);
            // handle on error
            toast.update(toastId, {
                render: errorStr,
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: TOAST_CONFIG.autoClose,
            });
        }
    };

    const getNFTPriceOfActiveListing = () => {
        const { listings } = selectedNFT;
        const activeListing = listings.find(({status}) => status === constants.NFT_LISTING_STATUS_ACTIVE);
        if (activeListing) {
            return Number(activeListing.price || 0);
        }
        return 0;
    }

    const handleWalletNotAvailable = () => {
        dispatch(
            modalActions.showModal({
                modalType: "ConfirmationModal",
                modalProps: {
                    title: "Wallet not created",
                    description:
                        "You don't have a wallet for this blockchain. Please create an account to continue.",
                    variant: "warning",
                    onDone: () => {
                        history.push("/app/settings/crypto-wallets");
                    },
                    doneBtnText: "Create Wallet",
                    blocking: true,
                },
            })
        );
};

    const handleWalletPending = () => {
        // If a valid wallet found but wallet address is not found
        dispatch(
            modalActions.showModal({
                modalType: "ConfirmationModal",
                modalProps: {
                    title: "Wallet unavailable",
                    description:
                        "Your wallet for this blockchain is still being created. If it's taking too much time you can retry creating the wallet from the wallet settings",
                    variant: "warning",
                    onDone: () => {
                        history.push("/app/settings/crypto-wallets");
                    },
                    doneBtnText: "Go to Settings",
                    blocking: true,
                },
            })
        );
    }

    const handleCreditNotSufficient = () => {
        dispatch(modalActions.showModal({
            modalType: "ConfirmationModal",
            modalProps: {
                title: "Insufficient Transaction Credits",
                description: "Your credit balance is running low. Top-up your transaction credits to continue.",
                variant: "warning",
                onDone: () => { history.push("/app/settings/credits") },
                doneBtnText: "Topup Credits",
                blocking: true,
            },
        }));
    };

    const handleBuyNFT = async () => {
        // dispatch(
        //     modalActions.showModal({
        //         modalType: "PaymentModal",
        //         modalProps: {
        //             clientSecret:
        //                 "pi_3L6WTJD6ZVWBc1NI0U5tg3BF_secret_2nafYYug9ENCdEPIIm5VMDlfa",
        //             onSuccess: () => {
        //                 refetchNFT();
        //             },
        //         },
        //     })
        // );
        // return;

        if (!isLoggedIn) {
            toast.error("Please sign in to continue");
            return;
        }

        const { chain } = selectedNFT;

        const creditBalanceSufficient = isCreditBalanceSufficient(); 
        const walletAvailable = isMatchingWalletAvailable(chain);
        
        if (walletAvailable) {
            const walletReady = isWalletReadyForTransactions(chain);
            if (!walletReady) {
                handleWalletPending();
                return;
            }
        } else {
            handleWalletNotAvailable();
            return;
        }

        if (!creditBalanceSufficient) {
            handleCreditNotSufficient();
            return;
        }

        const payload = {
            id: selectedNFT?.id,
            buyer: profile?.id,
        };
        const toastId = toast.loading("loading...");
        const response = await purchaseNFT(payload);

        if (response.error) {
            const errorStr = parseError(response.error);

            // handle on error
            toast.update(toastId, {
                render: errorStr,
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: TOAST_CONFIG.autoClose,
            });
        } else {
            toast.dismiss(toastId);
            dispatch(
                modalActions.showModal({
                    modalType: "PaymentModal",
                    modalProps: {
                        amount: getNFTPriceOfActiveListing(),
                        paymentId: response?.data?.payment_id,
                        onSuccess: () => {
                            refetchNFT();
                            dispatch(modalActions.hideModal());
                            history.push(`/app/user/${profile?.id}`);
                        },
                    },
                })
            );
        }
    };

    // TODO: change created_by to owner.
    const isOwnNFT = isLoggedIn && profile?.id === selectedNFT?.owned_by;

    return (
        <>
            <NavBar />
            <div className="container">
                <div className="row">
                    <div className="col-10 offset-1">
                        <div className="view-nft-content">
                            <FilterSearch
                                searchQuery={searchQuery}
                                setSearchQuery={setSearchQuery}
                                placeholder="Search items, collection, Artist or user"
                            />
                            <div className="my-2">
                                <small className="spaced-letters">
                                    EXPLORE COLLECTIONS
                                </small>
                            </div>
                            <div className="h3 mt-4 mb-5 font-weight-bold">
                                Discover the extraordinary NFT World
                            </div>
                            <div className="row w-100 mt-5">
                                <div className="col-md-5">
                                    <NFTThumbnail nft={selectedNFT} />
                                </div>
                                <div className="col-md-7">
                                    <NFTInformation
                                        nft={selectedNFT}
                                        isViewMode={!isOwnNFT}
                                        onClickAdvanceNFTDetails={
                                            handleOnClickAdvanceNFTDetails
                                        }
                                        onClickListNFT={handleOnClickListNFT}
                                        onClickUnlistNFT={
                                            handleOnClickUnlistNFT
                                        }
                                        onClickBuy={handleBuyNFT}
                                    />
                                </div>
                            </div>
                            <div className="row w-100 mt-5">
                                <div className="col-12 my-3">
                                    <CollapsibleRecord
                                        icon="/assets/icon/hg-price-history.svg"
                                        title="Price History"
                                    >
                                        <NFTPriceHistoryChart
                                            nft={selectedNFT}
                                        />
                                    </CollapsibleRecord>
                                </div>
                                <div className="col-12 my-3">
                                    <CollapsibleRecord
                                        icon="/assets/icon/hg-activity-log.svg"
                                        title="Activity Log"
                                    >
                                        <PaginatedTable
                                            columns={activityLogColumns}
                                            columnNames={activityLogColumnNames}
                                            rows={normalizedActivityLogs.allIds}
                                            data={normalizedActivityLogs.byId}
                                        />
                                    </CollapsibleRecord>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ViewNFTPage;
