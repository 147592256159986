import React from "react";
import { useGetFileQuery } from "../../services/fileService";
import { getDisplayName, getDisplayNameInitials, getGradientForUserDisplayName } from "../../utils/dataUtils";

import "./Profile.styles.scss";

function Avatar({ width = 45, height = 45, showOutline, user = {} }) {
    const outLineStyle = {
        width: width + 8,
        height: height + 8,
        borderRadius: 3,
        background: "rgba(215,215,215,1)",
        border: "1px solid rgba(83, 83, 83, 0.3)",
    };

    const { data: avatarFileData } = useGetFileQuery(
        { fileId: user.avatar },
        { skip: !user.avatar }
    );

    const getInitials = (userInfo) => {
        const name = getDisplayNameInitials(userInfo);
        if (name.length > 1) {
            return `${name[0].toUpperCase()}${name[1] || ""}`;
        }
        return "";
    };

    const getAvatarFileLink = () => {
        let avatarLink = avatarFileData?.file;
        if (user?.avatar && user?.avatar?.includes("blob:")) {
            avatarLink = user?.avatar;
        }
        return avatarLink;
    };

    const { c1, c2 } = getGradientForUserDisplayName(user);

    return (
        <div
            style={showOutline && outLineStyle}
            className="d-flex align-items-center justify-content-center"
        >
            {!user?.avatar && (
                <div
                    className="profile-avatar d-flex align-items-center justify-content-center font-weight-bold"
                    style={{
                        width,
                        height,
                        background: `linear-gradient(180deg, ${c1} 0%, ${c2} 100%)`,
                        borderRadius: 1,
                        fontSize: (width + 5) / 3,
                        color: "#fff",
                    }}
                >
                    {getInitials(user)}
                </div>
            )}
            {user?.avatar && (
                <div
                    style={{
                        width,
                        height,
                        borderRadius: 1,
                        backgroundImage: `url(${getAvatarFileLink()})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                    className="image-container"
                />
            )}
        </div>
    );
}

export default Avatar;
