import { createApi } from "@rtk-incubator/rtk-query";
import { temporaryAccessBaseQueryWithAuth } from "./baseQueries";

const tempAppService = createApi({
    reducerPath: "tempAppService",
    baseQuery: temporaryAccessBaseQueryWithAuth,
    refetchOnMountOrArgChange: true,
    entityTypes: ["TempMyProfile"],
    endpoints: (builder) => ({
        verifyOTP: builder.mutation({
            query: (data) => ({
                url: "/api/auth/verify/otp/",
                method: "POST",
                body: data,
            }),
            transformResponse: (response) => response.data,
            invalidates: ["TempMyProfile"],
        }),
        getMyProfileWithTempToken: builder.query({
            query: () => "/api/users/me/",
            provides: ["TempMyProfile"],
            transformResponse: (response) => response.data,
        }),
        reSendVerifyMobileToken: builder.mutation({
            query: (data) => ({
                url: "/api/auth/verify/resend-mobile/",
                method: "POST",
                body: data,
            }),
            transformResponse: (response) => response,
        }),
        verifyMobileToken: builder.mutation({
            query: (data) => ({
                url: "/api/auth/verify/mobile/",
                method: "POST",
                body: data,
            }),
            invalidates: ["TempMyProfile"],
        }),
    }),
});

export default tempAppService;

export const {
    useVerifyOTPMutation,
    useGetMyProfileWithTempTokenQuery,
    useReSendVerifyMobileTokenMutation,
    useVerifyMobileTokenMutation,
} = tempAppService;
