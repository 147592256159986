import React from "react";
import OTPInput from "./OTPInput";
import Retry from "./Retry";

export default function ValidReceivedVerifyCode({ handleRetry, onVerify }) {
    return (
        <>
            <div className="auth-layout">
                <div className="container">
                    <img
                        src="/assets/img/received-img.svg"
                        className="verify-img mt-n5"
                        alt=""
                    />
                    <div className="sub-title text-center mt-1">
                        VERIFY THAT IT'S YOU
                    </div>
                    <div className="content text-center mt-2">
                        <h4 className="mb-0 black-link">
                            ENTER THE CODE YOU HAVE RECEIVED
                        </h4>
                    </div>

                    <div className="mt-5">
                        <OTPInput onVerify={onVerify} />
                    </div>

                    <div className="row justify-content-md-center mt-4">
                        <div className="col-md-auto d-flex align-items-center">
                            <Retry onRetry={handleRetry} />
                            <div className="froget-mail ml-3">
                                Did not recive the code? <br />
                                Don’t worry. We can send it again.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
