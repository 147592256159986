
/* eslint-disable import/prefer-default-export */
import { useGetAvailableCreditsQuery } from "../services/creditService";
import { MINIMUM_ALLOWED_CREDIT_AMOUNT } from "../config";

export const useTransactionCredits = () => {
    const { data: creditBalanceDetails } = useGetAvailableCreditsQuery();

    /**
     * Determines if the credit balance is above the recommended amount.\
     * @returns `boolean` - Sufficient status
     */
    const isCreditBalanceSufficient = () => {
        let sufficient = false;
        if (creditBalanceDetails?.credit) {
            const { credit } = creditBalanceDetails;
            if (Number(credit).toFixed(2) >= MINIMUM_ALLOWED_CREDIT_AMOUNT) {
                sufficient = true;
            }
        }
        return sufficient;
    };

    return {
        creditBalanceDetails,
        isCreditBalanceSufficient,
    };
};