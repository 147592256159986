import React from "react";
import { useDispatch } from "react-redux";
import Banner from "./Banner";
import { ImageButton } from '../Button';
import { modalActions } from "../../state/modal";

const EditableBanner = ({ user, onSelectBanner }) => {
    const dispatch = useDispatch();

    const handleBannerSelect = (file) => {
        if (onSelectBanner && file) {
            onSelectBanner(file);
        }
    }

    const handleOnEdit = () => {
        dispatch(
            modalActions.showModal({
                modalType: "ImagePickerModal",
                modalProps: {
                    onSelect: handleBannerSelect,
                },
            })
        );
    }

    return (
    <>
        <Banner user={user} />
        <button type="button" onClick={handleOnEdit} className="edit-btn">
            <img src="/assets/icon/edit.svg" alt="Edit Banner" className="edit-icon banner" />
        </button>
    </>
)};

export default EditableBanner;
