export default function parseError(response, fallbackErrStr="Something went wrong") {
    let errorLevelOne = response?.data?.errors?.[0];
    let errorMessage = fallbackErrStr;
    if (typeof errorLevelOne === "string") {
        errorMessage = errorLevelOne;
    } 
    if (typeof errorLevelOne === "object") {
        errorLevelOne = errorLevelOne[Object.keys(errorLevelOne)[0]];
        if (errorLevelOne?.length) {
            [errorMessage] = errorLevelOne;
        }
        if (typeof errorLevelOne === "string") {
            errorMessage = errorLevelOne;
        }
    }
    if(errorMessage==="Tatum Service temporarily unavailable, try again later."){
        errorMessage = "Transaction failed. Try again in few minutes "
    }
    return errorMessage;
}
