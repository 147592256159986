import React from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../state/modal";
import "./ConfirmationModal.styles.scss";

const ConfirmationModal = ({
    title = "",
    description = "Please confirm before continuing.",
    onDone,
    onCancel,
    variant = "info",
    doneBtnText="Okay"
}) => {
    const dispatch = useDispatch();
    const handleOnClickOkay = () => {
        if (onDone) {
            onDone();
            dispatch(modalActions.hideModal());
        }
    };

    const handleOnClickCancel = () => {
        if (onCancel) {
            onCancel();
        }
        dispatch(modalActions.hideModal());
    };

    return (
        <div className="confirmation-modal">
            {title && (
                <div className="row">
                    <div className="col-12">
                        <h4>{title}</h4>
                    </div>
                </div>
            )}
            <div className="row mt-3 mb-4">
                <div className="col-12">
                    <div className={`alert alert-${variant}`}>
                        {description}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 offset-md-4 px-3">
                    <button
                        type="button"
                        className="btn btn-primary w-100"
                        onClick={handleOnClickOkay}
                    >
                        {doneBtnText}
                    </button>
                </div>
                <div className="col-md-4 px-3">
                    <button
                        type="button"
                        className="btn btn-outline-primary w-100"
                        onClick={handleOnClickCancel}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
