import React from "react";
import * as yup from "yup";
import { Form, TextField } from "../FormElements";
import constants from "../../utils/constants";

const validationSchema = yup.object().shape({
    auctionPrice: yup
        .number()
        .required("Required")
        .typeError("Invalid format")
        .min(1, "Invalid price"),
});

const NFTBidForm = ({ nft, onSubmit, onClickList, onClickUnlist }) => {
    const [isNFTListingAvailable, setIsNFTListingAvailable] = React.useState(
        false
    );
    const [nftBidFormData, setNftBidFormData] = React.useState({
        auctionPrice: "",
    });

    React.useEffect(() => {
        let activeListingFound = false;
        if (nft?.listings?.length) {
            const { listings: nftListingHistory } = nft;
            const activeListing = nftListingHistory.find(
                ({ status }) => status === constants.NFT_LISTING_STATUS_ACTIVE
            );
            if (activeListing) {
                activeListingFound = true;

                // setting active listing price to the textfield
                setNftBidFormData({
                    auctionPrice: Number(activeListing?.price).toFixed(2),
                });
            } else {
                setNftBidFormData({
                    auctionPrice: "",
                });
            }
        }
        setIsNFTListingAvailable(activeListingFound);
    }, [nft]);

    const handleOnClickList = () => {
        if (onClickList) {
            const { auctionPrice } = nftBidFormData;
            onClickList(auctionPrice);
        }
    };

    const handleOnClickUnlist = () => {
        if (onClickUnlist) {
            onClickUnlist();
        }
    };

    const onChangeListPrice = (e) => {
        const { value } = e.target;
        setNftBidFormData((prevState) => ({
            ...prevState,
            auctionPrice: value,
        }));
    };

    return (
        <Form
            initialValues={nftBidFormData}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
            className="w-100 px-3"
        >
            <div className="row">
                <div className="col-12 col-md-8 auction-placement-content p-3">
                    {!isNFTListingAvailable && (
                        <div className="h3 font-weight-bold">List this NFT</div>
                    )}
                    {isNFTListingAvailable && (
                        <div className="h3 font-weight-bold">NFT Listed</div>
                    )}
                    {/* <div className="d-flex justify-content-between align-items-center">
                        <span className="d-block">Current Auction</span>
                        <div className="d-block">
                            <div className="h3 font-weight-bold">
                                {nft?.price || 0} ETH
                            </div>
                            <div>($ {nft?.priceInUsd || 0})</div>
                        </div>
                    </div> */}
                    <div className="d-block">
                        <span className="d-block my-2">Listing price</span>
                        <div className="d-flex justify-content-between align-items-center">
                            <TextField
                                name="auctionPrice"
                                placeholder="Enter price here"
                                inputClassName="price-input"
                                disabled={isNFTListingAvailable}
                                onChange={onChangeListPrice}
                            />
                            <div className="h4 font-weight-bold px-2">USD</div>
                        </div>
                        <div className="d-block">
                            {!isNFTListingAvailable && (
                                <button
                                    type="submit"
                                    className="btn btn-primary px-4 mt-3"
                                    onClick={handleOnClickList}
                                >
                                    List
                                </button>
                            )}
                            {isNFTListingAvailable && (
                                <button
                                    type="submit"
                                    className="btn btn-dark px-4 mt-3"
                                    onClick={handleOnClickUnlist}
                                >
                                    Unlist
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 px-3">
                    {/* <button
                        type="button"
                        className="btn btn-outline-secondary d-block w-100 my-2 rounded-0 py-2 secondary-btn"
                    >
                        LIST
                    </button> */}
                    <button type="button" className="btn btn-primary d-block w-100 mt-3 mt-md-0">
                        TRANSFER
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default NFTBidForm;
