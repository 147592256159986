import { createApi } from "@rtk-incubator/rtk-query";
import {
    baseQueryWithAuth,
    baseQueryWithAutoLogout,
    baseQueryWithPublic,
    temporaryAccessBaseQueryWithAuth,
} from "./baseQueries";

const appService = createApi({
    reducerPath: "appService",
    baseQuery: baseQueryWithAutoLogout,
    entityTypes: ["Profile"],
    endpoints: (builder) => ({
        signIn: builder.mutation({
            query: ({ username, password }) => ({
                url: "/api/auth/token/",
                method: "POST",
                body: {
                    username,
                    password,
                },
            }),
            invalidates: ["Profile"],
            transformResponse: (response) => response.data,
        }),
        register: builder.mutation({
            query: (data) => ({
                url: "/api/auth/register/",
                method: "POST",
                body: data,
            }),
        }),
        getMyProfile: builder.query({
            query: () => "/api/users/me/",
            provides: ['Profile'],
            transformResponse: (response) => response.data,
        }),
        updateMyProfile: builder.mutation({
            query: (data) => ({
                url: "/api/users/me/",
                method: "PUT",
                body: data,
            }),
            invalidates: ["Profile"],
        }),
        verifyMobileToken: builder.mutation({
            baseQuery: baseQueryWithAuth,
            query: (data) => ({
                url: "/api/auth/verify/mobile/",
                method: "POST",
                body: data,
            }),
        }),
        reSendVerifyMobileToken: builder.mutation({
            baseQuery: temporaryAccessBaseQueryWithAuth,
            query: (data) => ({
                url: "/api/auth/verify/resend-mobile/",
                method: "POST",
                body: data,
            }),
            transformResponse: (response) => response,
        }),
        verifyOTP: builder.mutation({
            baseQuery: baseQueryWithAuth,
            query: (data) => ({
                url: "/api/auth/verify/otp/",
                method: "POST",
                body: data,
            }),
            transformResponse: (response) => response.data,
        }),
        verifyEmail: builder.query({
            query: ({ token }) =>
                `/api/auth/verify/email/?token=${token}&method=EMAIL`,
        }),
        resendEmailVerify: builder.mutation({
            query: (data) => ({
                url: "/api/auth/verify/resend-email/",
                method: "POST",
                body: data,
            }),
        }),
        requestPasswordReset: builder.mutation({
            baseQuery: baseQueryWithPublic,
            query: (data) => ({
                url: "/api/auth/request-password-reset/",
                method: "POST",
                body: data,
            }),
        }),
        resetPassword: builder.mutation({
            baseQuery: baseQueryWithPublic,
            query: (data) => ({
                url: "/api/auth/reset-password/",
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export default appService;

export const {
    useSignInMutation,
    useRegisterMutation,
    useGetMyProfileQuery,
    useUpdateMyProfileMutation,
    useVerifyMobileTokenMutation,
    useReSendVerifyMobileTokenMutation,
    useVerifyOTPMutation,
    useResendEmailVerifyMutation,
    useVerifyEmailQuery,
    useRequestPasswordResetMutation,
    useResetPasswordMutation,
} = appService;
