import React from "react";
import { useDispatch } from "react-redux";
import { CHAIN_TX_VERIFICATION_PLATFORMS } from "../../../config";
import { modalActions } from "../../../state/modal";
import constants from "../../../utils/constants";
import "./AdvancedNFTDetailsModal.styles.scss";

const AdvancedNFTDetailsModal = ({ nft }) => {
    const dispatch = useDispatch();

    const verificationConfig = CHAIN_TX_VERIFICATION_PLATFORMS[nft?.chain];

    const renderTransactionStatus = () => {
        const status = nft?.mint_tx?.status;
        switch (status) {
            case constants.TRANSACTION_STATUS_SUCCESS:
                return <div className="alert alert-success">Success</div>;
            case constants.TRANSACTION_STATUS_FAILED:
                return <div className="alert alert-danger">Failed</div>;
            case constants.TRANSACTION_STATUS_PENDING:
                return <div className="alert alert-warning">Pending</div>;
            default:
                return <div className="alert alert-info">Unknown</div>;
        }
    };

    const handleOnVerifyBtnClick = () => {
        const { tx_id: transactionId } = nft.mint_tx;
        if (transactionId && verificationConfig) {
            window.open(`${verificationConfig.baseURL}${transactionId}`, "_blank");
            dispatch(modalActions.hideModal());
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h6 className="text-muted">{nft?.name}</h6>
                    <h4>Advanced NFT Details</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h6>MINTING TRANSACTION DETAILS</h6>
                </div>
            </div>
            <div className="row">
                <div className="col-12 title">SIGNATURE</div>
                <div className="col-12 content">
                    {nft?.mint_tx?.signature_id}
                </div>
            </div>
            <div className="row">
                <div className="col-12 title">STATUS</div>
                <div className="col-12">{renderTransactionStatus()}</div>
            </div>
            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-link w-100 d-flex justify-content-center align-items-center"
                        disabled={!(nft?.mint_tx?.tx_id && verificationConfig)}
                        onClick={handleOnVerifyBtnClick}
                    >
                        {verificationConfig ? `View transaction on ${verificationConfig.platform}` : ""}
                        <div className="external-link-icon mx-1" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AdvancedNFTDetailsModal;
